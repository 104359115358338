import React from "react";
import { IconButton } from "@material-ui/core";
import { leftArrow, rightArrow, } from "../../blocks/customisableuserprofiles/src/assets";
import { preWhiteArrow } from "../../blocks/postcreation/src/assets";
export interface CustomArrowProps {
    onClick: () => void;
    type: string;
    lenghIsGreater: boolean;
  }
  
export const customArrow = ({ type, onClick, lenghIsGreater }: CustomArrowProps) => {
    const iconSrc = type === "PREV" ? leftArrow : rightArrow;
    return (
      <IconButton style={{ display: lenghIsGreater ? "block" : "none" }} className={`customArrow ${type === "PREV" ? "prevArrow" : "nextArrow"}`} data-test-id="iconButton" onClick={onClick}>
        <img data-test-id="icon" height={20} width={20} src={type === "PREV" ? preWhiteArrow : iconSrc} alt={type} />
      </IconButton>
    );
  };