
export const cancle = require("../assets/cancle.svg");
export const moreIcon = require("../assets/moreIcon.svg");
export const addCollab = require("../assets/addCollab.svg");
export const remove = require("../assets/remove.svg");
export const thumbnail = require("../assets/thumbnail.svg");
export const close = require("../assets/close.svg");
export const blackClose = require("../assets/black_close.png");
export const addImage = require("../assets/addImage.svg");
export const eyeOpenIcon = require("../assets/eyeOpenIcon.svg");
export const eyeCloseIcon = require("../assets/eyeCloseIcon.svg");
export const mailIcon = require("../assets/mailIcon.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const preWhiteArrow = require("../assets/preArrowWhite.svg");
export const restricted = require("../assets/restricted.svg");
export const saveImage = require("../assets/saveImage.svg");
export const addIcon =require("../assets/plus.svg");
export const companyLogo = require("../assets/companyLogo.png");
export const visibilityOn = require("../assets/visibility_on.png");
export const visibilityOff = require("../assets/visibility_off.png");
export const edit = require("../assets/edit.png");
export const shareImage = require("../assets/share.png");
export const closeIconImage =require("../assets/closeIconWhite.svg");








