import React from "react";

// Customizable Area Start
import {
  Box,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import ClientController, {Props} from "./ClientController";
import SubscriptionCards from "../../../components/src/CommonSubscription.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Silika-Medium"
    }
});
// Customizable Area End

export default class Membership extends ClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={{
          overflow: 'scroll'
        }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>

            <Box width="100%" style={{  marginLeft: this.state.windowWidth >= 960 ? '24px' : '0px',}} >
                <SubscriptionCards 
                  onClickBackToTop={this.handleScrollUp}
                  onClickFeaturesOpen={this.handleFeaturesOpen}
                  subscriptionFeatureCategories={this.state.subscriptionFeatureCategories}
                  subscriptionFeatures={this.state.subscriptionFeatures}
                  subscriptionPlans={this.state.subscriptionPlans}
                  windowWidth={this.state.windowWidth}
                  viewAllFeaturesOpen={this.state.viewAllFeaturesOpen}
                  isHeaderNotNeeded={false}
                />
            </Box>
          </LoginSignUp></div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


