import React from "react";
import DialogWithBtns from "../../../../components/src/DialogWithBtns.web";
import { styled, Typography } from "@material-ui/core";

type Props = {
    open: boolean,
    title :string,
    onCancelClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onSuccessClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    description : string;
    cancelText?: string;
    successText?: string;
    onClose?: () => void;
}
function DeletePopUp(props:Props) {
  const {open, title, cancelText,successText, onCancelClick, onClose, onSuccessClick, description} = props
  return (
    <DialogWithBtns
          open={open}
          title={title}
          cancelText={cancelText}
          successText={successText}
          onCancelClick={onCancelClick}
          onClose={onClose}
          onSuccessClick={onSuccessClick}
        >
          <StyledTypography>
            {description}
          </StyledTypography>
    </DialogWithBtns>
  )
}

export default DeletePopUp

const StyledTypography = styled(Typography)({
    fontFamily  :"Silika-Medium", 
    fontWeight  :300, 
    textAlign : "center", 
    fontSize : "16px", 
    marginBottom  :"24px",
})
