import React from "react";

// Customizable Area Start
import TermsController, { Props } from "./TermsController";
import Modal from "./components/Modal";
// Customizable Area End

export default class PrivacyPolicy extends TermsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Modal
          open={true}
          title="Privacy Policy"
          description={this.state.privacyPolicyDescription}
          handleClosePopUp={this.props.onClose}
        />
      // Customizable Area End
    );
  }
}


