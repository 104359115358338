import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import dayjs from "dayjs";
import { UpdatedValues } from "../../../../packages/blocks/customisableuserprofiles/src/ProfileInfoController";
import { OptionType, Profile } from "./EventViewController";
import { measureInternetSpeed } from "../../../components/src/measureInternet.web";

export interface IAttendee {
  id: string;
 attributes:{
  photo:string,
  email:string,
  first_name:string,
  last_name:string,
  account_id: number
 }
}
export type Event = {
  id: string;
  type: string;
  attributes: {
    event_name: string;
    description: string;
    event_website: string;
    account: {
      data: {
        id: string,
        type: string,
        attributes: {
      id: number;
      account_id: number;
      first_name: string;
      last_name: string;
      full_phone_number: string;
      country_code: string | null;
      phone_number: string | null;
      email: string;
      activated: boolean;
      device_id: string | null;
      unique_auth_id: string;
      password_digest: string;
      created_at: string;
      updated_at: string;
      user_name: string | null;
      platform: string | null;
      user_type: string | null;
      app_language_id: number | null;
      last_visit_at: string | null;
      is_blacklisted: boolean;
      suspend_until: string | null;
      status: string;
      role_id: number | null;
      full_name: string | null;
      stripe_id: string | null;
      stripe_subscription_id: string | null;
      stripe_subscription_date: string | null;
      gender: string | null;
      date_of_birth: string | null;
      age: number | null;
      social_links: any[] | null; // adjust the type accordingly
      reset_email_token: string | null;
      temp_email: string | null;
      photo:string;
      }
    }
    };
    attendees:{
      data:[IAttendee]
    }
    start_date: string;
    end_date: string;
    time: string | null;
    is_in_person: "online" | "in person"|"both"; // assuming there are only two options
    banner_image: string;
    country:string|null;
    county:string|null;
    is_favourite:boolean;
    start_time:string;
    end_time:string;
    town:string|null;
    created_at:string;
    link:string;
    address:string;
    postcode:string;
  };
};
export interface FormattedDateObject {
  unavailable_from: string | null,
  unavailable_to: string | null
}
export interface DateObject {
  from: Date | null,
  to: Date | null,
  fromError: string | null,
  toError: string | null
}
import {
  City,
  Country,
  ICity,
  ICountry,
  IState,
  State,
} from "country-state-city";

export interface IInPerson {
  name: string;
  value: string;
}

interface TimeZone {
  zoneName: string;
  gmtOffset: number;
  gmtOffsetName: string;
  abbreviation: string;
  tzName: string;
}

 export interface CityInfo {
  name: string;
  isoCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
}

export interface TownInfo{
  name: string;
  isoCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
  stateCode:string;
}

export const DefaultCountry = {
  isoCode: "",
  name: "",
  phonecode: "",
  flag: "",
  currency: "",
  latitude: "",
  longitude: "",
  timezones: [],
}

export const DefaultAdSearchCountry = {
  isoCode: "",
  name: "Country",
  phonecode: "",
  flag: "",
  currency: "",
  latitude: "",
  longitude: "",
  timezones: [],
}

export const DefaultCounty = {
  name: "",
  isoCode: "",
  countryCode: "",
  latitude: "",
  longitude: "",
};

export const DefaultAdSearchCounty = {
  name: "Region",
  isoCode: "",
  countryCode: "",
  latitude: "",
  longitude: "",
};

export const DefaultTown = {
  name: "",
  isoCode: "",
  countryCode: "",
  latitude: "",
  longitude: "",
  stateCode:"",
};

export const DefaultAdSearchTown = {
  name: "Town",
  isoCode: "",
  countryCode: "",
  latitude: "",
  longitude: "",
  stateCode:"",
};

export interface ICreateEvent {
  eventName: string;
  description: string;
  inPerson: string;
  bannerImage: File | null;
  eventWeb: string;
  linkToMeet: string;
  country: ICountry;
  town: TownInfo;
  address: string;
  postcode: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  county: CityInfo;
  timeZone:string;
}
export interface CountryInfo {
  isoCode: string;
  name: string;
  phonecode: string;
  flag: string;
  currency: string;
  latitude: string;
  longitude: string;
  timezones: TimeZone[];
}
interface IListEvent {
   data:Event[];
   errors?:object;
}

export interface FavEvent {
  id: string;
  type: string;
  attributes: {
    favouriteable_id: number;
    favouriteable_type: string;
    favouriteable: { data: Event };
  };
}

interface IFavoriteEvent{
  data:FavEvent[];
  errors?:object;
}

export interface ICustomInputProps {
  value?: string;
  onClick?: () => void;
  placeHolder?: string;
  isStartIcon?:boolean;
  isEndIcon?:boolean;
  name?:string;
  onBlur?:(event:any)=> void;
  onChange?:(event:any)=>void;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  listAllEvent: Event[];
  fromDate: Date | null;
  openDatePickers: boolean;
  closeDatePickers: boolean;
  toDate: Date | null;
  location:string;
  isOpenedCreateEventPopUp: boolean;
  inPersonOnlineBoth: string;
  uploadedFile: File | null;
  uploadedFileUrl: string;
  validFile: boolean;
  startDate: Date;
  endDate: Date;
  token: string | null;
  createEventInitialValues: {
    eventName: string;
    description: string;
    inPerson: string;
    bannerImage: File | null;
    eventWeb: string;
    linkToMeet: string;
    country: CountryInfo;
    town: TownInfo;
    address: string;
    postcode: string;
    startDate: string;
    startTime: string|null;
    endDate: string;
    endTime: string|null;
    county: CityInfo;
    timeZone:string;
  };
  activeTab:boolean;
  favEventList:FavEvent[];
  paginatedListEvent:Event[];
  activePage:number;
  isLoadingMore:boolean;
  isRemainingEventLoading:boolean;
  adSearchCountry:CountryInfo;
  adSearchCounty:CityInfo;
  adSearchTown:TownInfo;
  adSearchText:string;
  isCopied:boolean|null;
  msgSend: boolean;
  openModel:boolean;
  isSendLinkToChatModalOpen:boolean;
  linkShare:string;
  shareData:OptionType[],
  sendChatMessage:string,
  getAllData:Profile[],
  allValue:UpdatedValues,
  cover:string,
  wantToEventShare:string 
  // Customizable Area End
}



interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}




export default class AllEventControllerW extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllEventId: string | null = "";
  token: string = "";
  listEventsAPI: string = ""
  createEventApiCallId: string | null = "";
  attendEventApiId: string | null = "";
  favouriteEventApiId:string | null = "";
  eventFavouriteApiCallId:string | null = "";
  getAllDataProfileApiCallId: string | null="";
  deleteFavouritesApiCallId: string | null="";
  eventMessageSendApiCallId:string | null="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      listAllEvent: [],
      fromDate: null,
      openDatePickers: false,
      closeDatePickers: false,
      toDate: null,
      location: "",
      isOpenedCreateEventPopUp: false,
      inPersonOnlineBoth: "",
      uploadedFile: null,
      uploadedFileUrl: "",
      validFile: true,
      startDate: new Date(),
      endDate: new Date(),
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      createEventInitialValues: {
        eventName: "",
        description: "",
        inPerson: "",
        bannerImage: null,
        eventWeb: "",
        linkToMeet: "",
        country: DefaultCountry,
        town:DefaultTown,
        address: "",
        postcode: "",
        startDate: "",
        startTime: null,
        endDate: "",
        endTime: null,
        county: DefaultCounty,
        timeZone:" "
      },
      activeTab:true,
      favEventList:[],
      paginatedListEvent:[],
      activePage:0,
      isLoadingMore:true,
      openModel: false,
      isRemainingEventLoading:true,
      adSearchCountry:DefaultAdSearchCountry,
      adSearchCounty:DefaultAdSearchCounty,
      adSearchTown:DefaultAdSearchTown,
      adSearchText:"",
      isCopied:null,
      allValue:{
        Country: {} as CountryInfo, 
        Region: {} as CityInfo, 
        FirstName: "",
        LastName: "",
        Town: {} as CityInfo,
        PreferredWork: [], 
        image: "",
        imageError:"",
        Profession: [],
        Seniority: "",
        Headline: "",
      },
      msgSend:false,
      shareData: [],
      sendChatMessage:'',
      isSendLinkToChatModalOpen:false,
      linkShare:"",
      getAllData: [],    
      cover:"",
      wantToEventShare:""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.token = window.localStorage.getItem("token") || "";
    if (!this.token) {
      const messageEvent = new Message(getName(MessageEnum.NavigationMessage));
      messageEvent.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      messageEvent.addData(getName(MessageEnum.NavigationTargetMessage), "AccountLogin");
      runEngine.sendMessage(messageEvent.id, messageEvent);
    } else {
      this.ListEvents();
      this.setState({ validFile: true });
      this.getToken();
      this.handleFavouriteEvent();
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseType = getName(MessageEnum.RestAPIResponceMessage);

    if (responseType === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.listEventsAPI:
          this.handleListEventsResponse(responseJson);
          break;

        case this.createEventApiCallId:
          this.handleCreateEventResponse(responseJson, errorResponse);
          break;

        case this.favouriteEventApiId:
          this.handleFavouriteEventResponse(responseJson);
          break;

        case this.eventFavouriteApiCallId:
            this.handleAddFavouriteEventResponse(responseJson);
          break;

        case this.getAllDataProfileApiCallId:
           this.handleAllProfileResponse(responseJson.data);
          break;

        case this.deleteFavouritesApiCallId:
          this.handleDeleteFavEventResponse(responseJson.message);
          break;

        case this.eventMessageSendApiCallId:
            this.handleSendMsgEventResponse(responseJson.message);
            break;

        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleListEventsResponse =  async (responseJson:IListEvent) => {
    if (responseJson && !responseJson.errors) {
        this.setState({ listAllEvent: responseJson.data.filter((data)=>data.attributes.account.data !==null),paginatedListEvent:[] });
        const speed=await measureInternetSpeed();
        const speedInSeconds =  speed / 1000;
        const fileSizeInBytes = 1024 * 1024;
        const estimatedSpeedMbps = fileSizeInBytes / speedInSeconds / 12500000;
        if (estimatedSpeedMbps && estimatedSpeedMbps <= 5) 
           this.handleLoadMore();
        else {
           this.setState({paginatedListEvent:responseJson.data.filter((data)=>data.attributes.account.data !==null),isLoadingMore:false,isRemainingEventLoading:false})
        }};
  }

  handleCreateEventResponse=(responseJson:{data:Event,errors:object} , errorResponse?:object) =>{
    if (responseJson && !responseJson.errors) {
      this.setState({ isOpenedCreateEventPopUp: false });
      this.handleAttendEvent(parseInt(responseJson.data.id))
      this.navigateToPage(responseJson.data.id)
    } else {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    this.hideCreateEventModal();
    this.ListEvents();
  }

  handleFavouriteEventResponse=(responseJson:IFavoriteEvent) =>{
    if (responseJson.data) {
      this.setState({favEventList:responseJson.data.filter((event)=> event.attributes.favouriteable.data!==null)})
    }
    this.hideCreateEventModal();
  }

  handleAddFavouriteEventResponse(responseJson:IFavoriteEvent) {
    if (responseJson.data) {
      this.handleFavouriteEvent();
    }
  }

  handleAllProfileResponse(responseJson:Profile[]) {
    if (responseJson) {
      this.setState({getAllData:responseJson});
    }
  }

  handleDeleteFavEventResponse(responseJson:string){
    if (responseJson) {
     this.handleFavouriteEvent();
    }
  }

  handleSendMsgEventResponse (responseJson:string){
    if (responseJson) {
      this.setState({isSendLinkToChatModalOpen: false,sendChatMessage:'',shareData:[], msgSend: true}); 
     }
  }

  ListEvents = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listEventsAPI = requestMessaged.messageId;

    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listEventsEndPoint}?event_name=${this.state.adSearchText}&start_date=${this.state.fromDate?dayjs(this.state.fromDate).format("YYYY-MM-DD"):""}&end_date=${this.state.toDate?dayjs(this.state.toDate).format("YYYY-MM-DD"):""}&location=${this.state.location?this.state.location:""}&country=${this.state.adSearchCountry.name!=="Country"?this.state.adSearchCountry.name:""}&county=${this.state.adSearchCounty.name !=="Region"?this.state.adSearchCounty.name:""}&town=${this.state.adSearchTown.name !=="Town"?this.state.adSearchTown.name:""}`
    );

    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessaged.id, requestMessaged);

    return true;
  }

  handleDateChange = (selectedDate: Date) => {
    this.setState({ fromDate: selectedDate,toDate:null,activePage:0 ,paginatedListEvent:[] },()=>{this.ListEvents()})
    this.handleDatePickerOpen()
  }

  handleDateChangeTo = (selectedDate: Date | null) => {
    this.setState({ toDate: selectedDate,activePage:0 ,paginatedListEvent:[] },()=>{this.ListEvents()})
    this.handleDatePickerOpenTo()
  }

  handleDatePickerOpen = () => {
    this.setState({ openDatePickers: !this.state.openDatePickers })
  }

  handleDatePickerOpenTo = () => {
    this.setState({ closeDatePickers: !this.state.closeDatePickers })
  }

  handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({location:event.target.value as string,activePage:0 ,paginatedListEvent:[],adSearchCountry:DefaultAdSearchCountry,
      adSearchCounty:DefaultAdSearchCounty,adSearchTown:DefaultAdSearchTown},()=>{this.ListEvents();})
  }

  removeBreadCrumbs = (states: string) => {
    switch(states) {
      case "fromDate":
        this.setState({ fromDate: null,toDate: null,activePage:0 ,paginatedListEvent:[] },()=>{this.ListEvents()});
        break;
      case "toDate":
        this.setState({ toDate: null ,activePage:0 ,paginatedListEvent:[]},()=>{this.ListEvents()});
        break;
      case "location":
        this.setState({ location: "",activePage:0 ,paginatedListEvent:[],adSearchCountry:DefaultAdSearchCountry,
        adSearchCounty:DefaultAdSearchCounty,adSearchTown:DefaultAdSearchTown },()=>{this.ListEvents()});
        break;
      case "all":
        this.setState({
          fromDate: null,
          toDate: null,
          location: "",
          adSearchCountry: DefaultAdSearchCountry,
          adSearchCounty: DefaultAdSearchCounty,
          adSearchTown: DefaultAdSearchTown,
          activePage:0 ,
          paginatedListEvent:[]
        },()=>{this.ListEvents()});
        break;
      case "country":
        this.setState({ adSearchCountry: DefaultAdSearchCountry,adSearchCounty: DefaultAdSearchCounty,adSearchTown: DefaultAdSearchTown,activePage:0 ,paginatedListEvent:[]},()=>{this.ListEvents()});
        break;
      case "county":
        this.setState({ adSearchCounty: DefaultAdSearchCounty,activePage:0 ,paginatedListEvent:[] },()=>{this.ListEvents()});
        break;
      case "town":
        this.setState({ adSearchTown: DefaultAdSearchTown,activePage:0 ,paginatedListEvent:[] },()=>{this.ListEvents()});
        break;
      default:
        break;
      }
  }
  
 handleCreateEventPopUp = () => {
    this.setState({ isOpenedCreateEventPopUp: true });
  };

  hideCreateEventModal = () => {
    this.setState({ isOpenedCreateEventPopUp: false });
    this.setState({
      uploadedFileUrl: "",
      inPersonOnlineBoth: "",
      uploadedFile: null,  
    });
  };

  handleRemoveFile = () => {
    this.setState({uploadedFileUrl: "", uploadedFile: null ,validFile:true });
  };

  handleInpersonOnline = (value: string) => {
    this.setState({ inPersonOnlineBoth: value });
  };

  handleImageChange = (events: React.ChangeEvent<HTMLInputElement>) => {
    const filesdr = events.target.files;
    if (filesdr) {
      const fileSizer = filesdr[0].size / (1024 * 1024);
      if (fileSizer <= 5 && filesdr[0].type.split("/").includes("image")) {
        this.setState({ uploadedFile: filesdr[0],uploadedFileUrl: URL.createObjectURL(filesdr[0]),validFile:true});
      }
      else{
          this.setState({validFile:false,uploadedFileUrl: URL.createObjectURL(filesdr[0])});
      }
    }
  };

  setEndDate = (dateEnd: Date) => {
    this.setState({ endDate: dateEnd });
  };

  setStartDate = (dateStart: Date) => {
    this.setState({ startDate: dateStart });
  };

  eventCreation=(values: ICreateEvent): boolean=> {
    let formdata = new FormData();

    formdata.append("event_name", values.eventName);
    formdata.append("postcode", values.postcode);
    if (values.town?.name) {
      formdata.append("town", values.town?.name);
    }
    formdata.append("start_time", values.startTime);
    formdata.append("end_time", values.endTime);
    formdata.append("description", values.description);
    formdata.append("event_website", values.eventWeb);
    formdata.append("start_date", values.startDate);
    formdata.append("end_date", values.endDate);
    formdata.append("address", values.address);
    formdata.append("link", values.linkToMeet);
    formdata.append("time_zone", values.timeZone);
    if(values.bannerImage)
    {
      formdata.append("banner_image", values.bannerImage as File);
    }
   
    formdata.append("is_in_person", values.inPerson);

    if(values.county?.name)
    {
      formdata.append("county", values.county?.name);
    }

    if(values.country?.name && values.country?.name!=="Country")
      {
        formdata.append("country", values.country?.name);
      }

    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEventApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEventUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCountry = () => {
    let CounntryArray: ICountry[] = Country.getAllCountries();
    let newArray = CounntryArray.map((countryVal: ICountry) => ({
      value: countryVal,
      label: countryVal.name,
    }));
    return newArray;
  };

  getCityArray = (iosCodeCountry: string, iosCodeCounty: string) => {
    let newArray = City.getCitiesOfState(iosCodeCountry, iosCodeCounty).map(
      (countryVal: ICity) => ({ value: countryVal, label: countryVal.name })
    );
    return newArray;
  };

  getStateArray = (iosCodeOfState: string) => {
    let newArray = State.getStatesOfCountry(iosCodeOfState).map(
      (countryVal: IState) => ({ value: countryVal, label: countryVal.name })
    );
    return newArray;
  };

  handleTabChanges = (activeTabs: boolean) => {
    this.removeBreadCrumbs("all");
    this.setState({ activeTab: activeTabs,adSearchText:"",activePage:0 ,paginatedListEvent:[]},()=>{this.ListEvents()});
    if(!activeTabs){
      this.handleFavouriteEvent()
    }
  };

  handleAttendEvent = (eventId: number) => {
    const headers = {
      token: localStorage.getItem("token"),
    };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.attendEventApiId = requestMessaged.messageId;

    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.attendEventEndPoint}`+`${eventId}`
    );

    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.attendEventMethodType
    );

    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return true;
  };

  handleFavouriteEvent = () => {
    const headers = {
      token: localStorage.getItem("token"),
    };
    const requestMessageFavs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouriteEventApiId = requestMessageFavs.messageId;

    requestMessageFavs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouriteEventAPI}`
    );

    requestMessageFavs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessageFavs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageFavs.id, requestMessageFavs);
    return true;
  };

  handleLoadMore = () => {
    this.setState({ isLoadingMore: true });
  
    setTimeout(() => {
      this.setState(
        () => ({
          activePage: this.state.activePage + 1,
          paginatedListEvent: this.state.listAllEvent.filter((event)=> event.attributes.account!==null).slice(
            0,
            (this.state.activePage + 1) * 9
          ),
          isLoadingMore: false,
        }),
        () => {
          this.setState({
            isRemainingEventLoading:
              this.state.listAllEvent.length -
                this.state.paginatedListEvent.length ===
              0
                ? false
                : true,
          });
        }
      );
    }, 200); 
  }

  handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  handleAdSearchCountry = (countrys: CountryInfo) => {
    this.setState({ adSearchCountry: countrys, adSearchCounty: DefaultAdSearchCounty, adSearchTown: DefaultAdSearchTown, activePage: 0, paginatedListEvent: [] }, () => { this.ListEvents() });
  }

  handleAdSearchCounty = (countys: CityInfo) => {
    this.setState({ adSearchCounty: countys, adSearchTown: DefaultAdSearchTown, activePage: 0, paginatedListEvent: [] }, () => { this.ListEvents() });
  }
  
  handleAdSearchTown = (towns: TownInfo) => {
    this.setState({ adSearchTown: towns, activePage: 0, paginatedListEvent: [] }, () => { this.ListEvents() });
  }

  handleAdSearchText = (searchText: string) => {
    this.setState({ adSearchText: searchText, listAllEvent: [], activePage: 0 }, () => { if (searchText === "" || searchText.length >= 2) { this.ListEvents() } });
  }

  navigateToPage = (idEvent: string) => {
    const userNavMsgs: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsgs.addData(getName(MessageEnum.NavigationTargetMessage), "EventView");
    userNavMsgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), idEvent);
    userNavMsgs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(userNavMsgs);
  };

  handleAddFavouriteEvent = (eventId:string) => {
    const favoriteEvent={
      data:{
        favouriteable_id: parseInt(eventId),
        favouriteable_type: "BxBlockEvents::Event"
      }
    }   

    const header = {
      token: localStorage.getItem("token"),
      "Content-Type":configJSON.validationApiContentType
    };

    const requestMessageFavEvs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.eventFavouriteApiCallId = requestMessageFavEvs.messageId;

    requestMessageFavEvs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteEventEndPont
    );
    requestMessageFavEvs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageFavEvs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createApiMethodType
    );
    requestMessageFavEvs.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(favoriteEvent)
    );
    runEngine.sendMessage(requestMessageFavEvs.id, requestMessageFavEvs);
    return true;
  }

  handleClickCopy = () => {
    this.setState({msgSend:false})
    navigator.clipboard.writeText(this.state.linkShare)
      .then(() => {
        this.setState({ isCopied: true });
      })
      .catch((error) => { this.setState({ isCopied: false });});
  }

  handleChat=()=>{
    this.setState({isSendLinkToChatModalOpen:true, msgSend:false, isCopied:null})
    this.handleGetAllUserProfile();
  }

  openPopUp =(eventId:string)=>{
    this.setState({
     openModel: true,
     linkShare:window.location.href.split("/",3).join("/")+"/EventView?id="+`${eventId}`,
     wantToEventShare:eventId
    })
   }

  handleGetAllUserProfile = () => {
    const headers = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDataProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleClose =()=>{
    this.setState({
      openModel: false,
      isCopied:null,
      msgSend:false,
     })
  }

  handleNavigateBacks=()=>{
    this.setState({
      isSendLinkToChatModalOpen:false,
      openModel: true
     })
  }

  handleCloseChatModels = () => {
    this.setState({isSendLinkToChatModalOpen: false,sendChatMessage:'',shareData:[]});
  }

  handleSend=()=>{
    const ids = this.state.shareData.map(user => user.account_id);
    const headers = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("token")
    };
    let body =  {
        user_id: ids,
        event_id: +this.state.wantToEventShare,
        message: this.state.sendChatMessage,
       }
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_events/share_email"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.eventMessageSendApiCallId = requestMessages.messageId;
    runEngine.sendMessage(requestMessages.id, requestMessages);
  }
  handleshareDataChatChanges = (_event:  React.ChangeEvent<{}>,value: OptionType[]) => {
    this.setState({ shareData: value });
  };

  handlesendChatMessages=(event:{target:{value:string}})=>{
    this.setState({ sendChatMessage: event.target.value });
  }

  handleStateCovs =(passedVal:string)=>{
    this.setState({cover:passedVal})
  }
  handleStatesCovs =(passedValues:string)=>{
    this.setState({cover:passedValues})
  }

  handleDeleteFavouriteApiCall = (eventId: string) => {
    const header = {   
      token: localStorage.getItem("token"),
    };
    const requestMessageses = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFavouritesApiCallId = requestMessageses.messageId;

    requestMessageses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouriteEventEndPont}/${eventId}`
    );

    requestMessageses.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageses.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessageses.id, requestMessageses);
    return true;
  };

  handleValidFile = () =>{
    return this.state.uploadedFile || this.state.uploadedFileUrl!==""
  }

  handleOnlineBoth = () =>{
    return this.state.inPersonOnlineBoth == "online" || this.state.inPersonOnlineBoth == "both"
  }

  handleInpersonBoth = () =>{
    return (this.state.inPersonOnlineBoth == "in person" || this.state.inPersonOnlineBoth == "both")
  }
  // Customizable Area End
}
