import { Message } from "../../framework/src/Message";

import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
    isJsonStringify?: boolean;
    }

export const apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
        data;
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
        "Content-Type": contentType,
        token: token,
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );

    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            isJsonStringify ? JSON.stringify(body) : body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};