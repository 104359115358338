import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
  time: number;
  approved : boolean;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  chatList: IChat[];
  selectedTab: number;
  textMessage: string;
  anchorEl: any,
  selectedChat : string;
  searchQuery : string;
  isMobileView : boolean;
  isChatSelected:boolean;
  isNewChat : boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {

      chatList: [],
      selectedTab: 0,
      textMessage: "",
      anchorEl: null,
      selectedChat : "1",
      searchQuery : "",
      isMobileView: window.innerWidth < 700,
      isChatSelected:false,
      isNewChat : false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.handleResize);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
      this.setState({ isMobileView: window.innerWidth < 700 });
  };

  handleChatSelected = (item:IChat)=>{
    this.setState({selectedChat : item.id, isChatSelected : true, isNewChat : !item.approved })
  }

  chatInputData = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ textMessage: e.target.value })
  }

  handleSearch = (e :  React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({searchQuery : e.target.value})
  }

  getFilteredChatList() {
    const { searchQuery, selectedTab } = this.state;
    const isApproved = selectedTab === 0;
    return configJSON.chatList.filter((item: any) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) && item.approved === isApproved
    );
  }
  handleCloseChat = () =>{
    this.setState({isChatSelected : false})
  }
  // Customizable Area End
}
