import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
  Modal,
  Backdrop,
  Fade,
  Paper
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import HeaderNavbar from "../../../components/src/Navbar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as IMG_CONST from "./assets";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDeleteModal = () => {
    const { isDeleteModalOpen } = this.state;
    return (
      <ModalBox >
        <Modal
          open={isDeleteModalOpen}
          onClose={this.handleOpenClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            style: { backgroundColor: "rgba(255, 255, 255, 0.7)" },
          }}
        >
          <Fade in={isDeleteModalOpen}>
            <ModalBox style={{ height: "100%" }}>
              <DeleteModalContainer>
                <HeaderBox>
                  <ModalTitle>Delete</ModalTitle>
                </HeaderBox>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <DeleteMessage>
                    {this.state.selectAll ? configJSON.allTxtDeleteMessage : configJSON.singleTxtDeleteMessage}
                  </DeleteMessage>
                </Box>
                <ButtonContainer>
                  <CancelButton onClick={this.handleOpenClose}>Cancel</CancelButton>
                  <DeleteButton onClick={this.handleOpenClose}>Yes, delete</DeleteButton>
                </ButtonContainer>
              </DeleteModalContainer>
            </ModalBox>
          </Fade>
        </Modal>
      </ModalBox>
    );
  };

  renderPopOptions = () => {
    return (
      <PopUpBox>
        <OptionContainer data-test-id ="popIndexTestId" onClick={this.handlePopIndex}>
          <img src={IMG_CONST.deleteIcon} alt="Bell" className="imageIcon" />
          <OptionText>Mark as read</OptionText>
        </OptionContainer>
        <OptionContainer onClick={this.handlePopIndex}>
          <DeleteOutlineOutlinedIcon className="imageIcon deleteIcon" />
          <OptionText>Delete</OptionText>
        </OptionContainer>
      </PopUpBox>
    );
  };

  renderReadUnreadPopUp = () => {
    return (
      <ReadPopUpBox>
        <ReadOptionContainer onClick={this.handleSelectAllChange}>
          <ReadOptionText>All</ReadOptionText>
        </ReadOptionContainer>
        <ReadOptionContainer onClick={this.handleMessageSeen}>
          <ReadOptionText >Unread</ReadOptionText>
        </ReadOptionContainer>
      </ReadPopUpBox>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <HeaderNavbar
          navigation={this.props.navigation}
          data-test-id="headerNavbar"
        />
        <InnerBox>
          <Typography className="headingText">Notifications (4)</Typography>

          <Box className="allCheckBox">
            <Box className="insideCheckBox">
              <Checkbox
                className="selectAllBox"
                checked={this.state.selectAll}
                data-test-id="selectAllTestId"
                onChange={this.handleSelectAllChange}
              />
              <KeyboardArrowDownIcon style={{ position: "relative", cursor:"pointer" }} data-test-id="messageSeenTestId" onClick={this.handleMessageSeen} />
              {this.state.isMessage && this.renderReadUnreadPopUp()}
            </Box>
            <Box>
              {this.state.isAnySelected &&
                <Box className="iconBox">
                  <DeleteOutlineOutlinedIcon className="moreIcon deleteIcon" data-test-id="deleteBtnTestId" onClick={this.handleOpenClose} />
                  <MarkEmailReadOutlinedIcon className="moreIcon" />
                  <img src={IMG_CONST.settingIcon} className="moreIcon" />

                </Box>
              }
            </Box>
          </Box>
          
          {this.state.notificationData.map((item, indexValue) => (
            <Box key={indexValue} className="innerListBox">
              <Checkbox
                data-test-id={`checkBoxTestId_${indexValue}`}
                checked={item.checked}
                onChange={() => this.handleCheckboxChange(indexValue)}
              />
              <img src={item.imageData} className="imageClass" alt="Notification Icon" />
              <Box style={{ width: "100%" }}>
                <Typography className="notificationHeadingTxt">{item.heading}</Typography>
                <Typography className="notificationSubTxt">{item.subText}</Typography>
              </Box>

              <Box>
                <Typography className="hourTxt"> {item.hour}</Typography>
                <MoreVertIcon className="moreIcon"
                  data-test-id={`iconClickTestId_${indexValue}`}
                  onClick={() => this.handleIconClick(indexValue)}
                />
                {this.state.openPopUpIndex === indexValue && this.renderPopOptions()}
              </Box>
            </Box>
          ))}

        </InnerBox>
        {this.renderDeleteModal()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const InnerBox = styled(Box)({
  margin: "80px",
  display: "flex",
  flexDirection: "column",
  "& .curserPointer":{cursor:"pointer"},
  "@media (max-width: 1024px)": {
    margin: "50px"
  },
  "@media (max-width: 768px)": {
    margin: "30px"
  },
  "@media (max-width: 480px)": {
    margin: "30px"
  },
  "@media (max-width: 320px)": {
    margin: "10px"
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: "black"
  },
  "& .headingText": {
    color: "#010101",
    fontWeight: 500,
    fontSize: "30px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "26px"
    },
    "@media (max-width: 480px)": {
      fontSize: "22px"
    },
    "@media (max-width: 320px)": {
      fontSize: "20px"
    }
  },
  "& .innerListBox": {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    gap: "15px",
    "&:not(:first-of-type)": {
      borderTop: "1px solid #DFDFDF",
      borderLeft: "1px solid #DFDFDF",
      borderRight: "1px solid #DFDFDF"
    },
    "@media (max-width: 480px)": {
      padding: "15px",
      gap: "10px"
    },
    "@media (max-width: 320px)": {
      padding: "10px",
      gap: "5px"
    }
  },
  "& .imageClass": {
    height: "50px",
    width: "50px",
    objectFit: "cover",
    "@media (max-width: 480px)": {
      height: "40px",
      width: "40px"
    },
    "@media (max-width: 320px)": {
      height: "30px",
      width: "30px"
    }
  },
  "& .notificationHeadingTxt": {
    color: "#010101",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "20px"
    },
    "@media (max-width: 480px)": {
      fontSize: "18px"
    },
    "@media (max-width: 320px)": {
      fontSize: "16px"
    }
  },
  "& .hourTxt": {
    color: "#9B9B9B",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    marginBottom: "5px"
  },
  "& .notificationSubTxt": {
    color: "#010101",
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "12px"
    },
    "@media (max-width: 480px)": {
      fontSize: "10px"
    },
    "@media (max-width: 320px)": {
      fontSize: "8px"
    }
  },
  "& .selectAllBox": {
    margin: "20px  0px 20px 20px",
    "@media (max-width: 1024px)": {
      margin: "50px 0px 50px 21px"
    },
    "@media (max-width: 768px)": {
      margin: "30px 0px 30px 17px"
    },
    "@media (max-width: 480px)": {
      margin: "15px 0px 15px 15px"
    },
    "@media (max-width: 320px)": {
      margin: "10px 0px 10px 10px"
    }
  },
  "& .moreIcon": {
    cursor: "pointer",
    position: "relative"
  },
  "& .iconBox": {
    display: "flex",
    gap: "10px"
  },
  "& .deleteIcon": {
    color: "red"
  },
  "& .allCheckBox": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "space-between",
    borderBottom: "2px solid #DFDFDF",
    height: "50px",
    margin: "50px 0px"
  },
  "& .insideCheckBox": {
    display: "flex",
    alignItems: "center",
    height: "50px"
  }
});

const ModalBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",

});

const DeleteModalContainer = styled(Paper)({
  width: "500px",
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  backgroundColor: "#FFFFFF",
  '@media (max-width: 768px)': {
    width: "80%"
  },
  '@media (max-width: 480px)': {
    width: "90%"
  }
});

const HeaderBox = styled(Box)({
  backgroundColor: "#0D0C22",
  height: "40px",
  display: "flex",
  alignItems: "center",
  padding: "0px 20px"
});

const ModalTitle = styled(Typography)({
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Silika-Medium"
});

const DeleteMessage = styled(Typography)({
  padding: "0px 20px",
  color: "#010101",
  fontWeight: 300,
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  textAlign: "center",
  '@media (max-width: 480px)': {
    fontSize: "12px"
  }
});

const ButtonContainer = styled(Box)({
  display: "flex",
  marginTop: "16px",
  gap: "10px",
  justifyContent: "flex-end",
  padding: "0px 20px 20px 0px",
  flexDirection: "row",
  '@media (max-width: 480px)': {
    flexDirection: "column",
    alignItems: "stretch",
    padding: "20px"
  }
});

const CancelButton = styled(Button)({
  textTransform: "unset",
  color: "#0D0C22",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  width: "105px",
  backgroundColor: "#FFFFFF",
  padding: "0px 20px",
  border: "1px solid #010101",
  cursor: "pointer",
  borderRadius: "1px",
  '@media (max-width: 480px)': {
    width: "100%",
    marginBottom: "10px"
  }
});

const DeleteButton = styled(Button)({
  textTransform: "unset",
  width: "105px",
  backgroundColor: "#0D0C22",
  color: "#FFFFFF",
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  cursor: "pointer",
  borderRadius: "1px",
  '&:hover': {
    backgroundColor: "#0D0C22",
    color: "#FFFFFF"
  },
  '@media (max-width: 480px)': {
    width: "100%"
  }
});

const PopUpBox = styled(Box)({
  width: "150px",
  position: "absolute",
  right: "110px",
  border: "1px solid #DFDFDF",
  display: "flex",
  flexDirection: "column",
  borderRadius: "5px",
  zIndex: 1000,
  backgroundColor: "#FFFFFF",
  "@media (max-width: 1200px)": {
    right: "80px"
  },
  "@media (max-width: 900px)": {
    right: "50px"
  },
  "@media (max-width: 600px)": {
    width: "100px",
    right: "20px"
  },
  "@media (max-width: 400px)": {
    right: "10px"
  },
  "& .imageIcon": {
    width: "20px",
    height: "20px"
  }
});


const OptionContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  padding: "5px 10px",
  borderRadius: "5px",
  transition: "background-color 0.3s ease",
  pointer:"cursor",
  "&:hover": {
    backgroundColor: "#0D0C22",

    "& img": {
      filter: "brightness(0) invert(1)"
    },

    "& p": {
      color: "#FFFFFF"
    }
  }
});

const OptionText = styled(Typography)({
  color: "#0D0C22",
  fontWeight: 500,
  fontSize: "12px",
  fontFamily: "Silika-Medium",
  transition: "color 0.3s ease"
});

const ReadOptionContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  padding: "10px 10px",
  borderRadius: "5px",
  transition: "background-color 0.3s ease",
  pointer:"cursor",
  "&:hover": {
    backgroundColor: "#0D0C22",

    "& img": {
      filter: "brightness(0) invert(1)"
    },

    "& p": {
      color: "#FFFFFF"
    }
  }
});

const ReadOptionText = styled(Typography)({
  color: "#0D0C22",
  fontWeight: 500,
  fontSize: "12px",
  fontFamily: "Silika-Medium",
  transition: "color 0.3s ease"
});

const ReadPopUpBox = styled(Box)({
  width: "150px",
  position: "absolute",
  left: "80px",
  border: "1px solid #DFDFDF",
  display: "flex",
  flexDirection: "column",
  borderRadius: "5px",
  zIndex: 1000,
  backgroundColor: "#FFFFFF",
  top: "283px", 
  "@media (max-width: 1200px)": {
    top: "283px", 
    right: "80px"
  },
  "@media (max-width: 1024px)": {
    top: "253px", 
    right: "50px"
  },
  "@media (max-width: 900px)": {
    top: "253px", 
    right: "50px"
  },
  "@media (max-width: 768px)": {
    top: "227px", 
    width: "100px",
    right: "20px"
  },
  "@media (max-width: 480px)": {
    top: "222px", 
    left: "50px"
  },
  "& .imageIcon": {
    width: "20px",
    height: "20px"
  }
});

// Customizable Area End
