import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  TextField,
  Grid,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { ErrorMessage, Formik } from "formik";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TermsAndConditions from "../../email-account-login/src/TermsAndConditions.web";
import PrivacyPolicy from "../../email-account-login/src/PrivacyPolicy.web";
import LoginSignUp, { webStyle , StyledButton, StyledTypography} from "../../email-account-login/src/LoginSignUp.web";
import "../../customisableuserprofiles/src/ProfileInfo.css"
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import * as Yup from "yup";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .required("Email address required")
    .test(
      "is-valid-email",
      "Invalid email address",
      (value) =>
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
    ),
  password: Yup.string()
    .required("Password is required")
    .test(
      "is-strong-password",
      "Password must include at least one uppercase and one lowercase letter",
      (value) => /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const StyledTextField = styled(TextField)({
  "& .formHelperClass":{
      color: "#DC2626",
      marginLeft: "0px",
      fontFamily: "Silika-Medium"
  },
  "& input, input::placeholder":{
      fontFamily: "Silika-Medium !important"
  }
});


// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },

});



export default class EmployeeSignUp extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    
    return (
      // Customizable Area Start
      // Required for all blocks
      
      <ThemeProvider theme={theme}>
        {this.state.termsPopup && <TermsAndConditions open={this.state.termsPopup} onClose={this.handleTermsNavigate} />}
        {this.state.privacyPopup && <PrivacyPolicy open={this.state.privacyPopup} onClose={this.handlePrivacyPolicyClick} />}
        <div style={{ overflow: 'hidden' }} data-test-id="AccountRegiContainer">
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box width={{xs : "300px", sm : "327px",  md: "360px"}}>
              <Formik
                initialValues={{
                  title : this.state.EmployeSignUpFormData.title,
                  email : this.state.EmployeSignUpFormData.email,
                  firstName : this.state.EmployeSignUpFormData.firstName,
                  lastName : this.state.EmployeSignUpFormData.lastName,
                  password : this.state.EmployeSignUpFormData.password,
                  confirmPassword : this.state.EmployeSignUpFormData.confirmPassword,
                }}
                validationSchema={validationSchema}
                data-testid="formikForm"
                onSubmit={() => { }}
              >
                {({handleSubmit,values,  handleBlur, handleChange }) => (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <div style={webStyle.formTitleContainer}>
                      <StyledTypography style={{ ...webStyle.formTitleText, fontFamily: 'Silika-Medium', textAlign : "left" }}>
                        Join Tech Nova
                      </StyledTypography>
                    </div>
                    <Typography style={webStyle.invitationText}>
                    You've been invited to join Tech Nova.  Please complete your details:
                    </Typography>
                    <Box style={webStyle.textInputs}>
                      <StyledTextField
                        placeholder={"Title"}
                        variant="outlined"
                        value={values.title}
                        style={{ ...webStyle.inputfieldHeight, marginBottom: 5 }}
                        data-test-id="title"
                        name={"title"}
                        helperText={<ErrorMessage name="title" />}
                        onChange={handleChange} 
                        FormHelperTextProps={{
                          className: "formHelperClass"
                      }}
                      />
                    </Box>
                      <Grid style={webStyle.textInputs} container spacing={2}>
                        <Grid item xs={12} lg={6} md={6}>
                            <StyledTextField
                                fullWidth
                                name={"firstName"}
                                value={values.firstName}
                                inputProps={{ maxLength: 30, style: { height: 10 } , className:"customTextField"}}
                                placeholder="First Name"
                                variant="outlined"
                                data-test-id='firstName'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={<ErrorMessage name="firstName" />}
                                FormHelperTextProps={{
                                  className: "formHelperClass"
                              }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} md={6}>
                            <StyledTextField
                                fullWidth
                                name={"lastName"}
                                data-test-id='lastName'
                                value={values.lastName}
                                placeholder="Last Name"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={<ErrorMessage name="lastName" />}
                                inputProps={{ maxLength: 30, style: { height: 10 }, className:"customTextField" }}
                                FormHelperTextProps={{
                                  className: "formHelperClass"
                              }}
                            />
                        </Grid>
                      </Grid>
                    <Box style={webStyle.textInputs}>
                      <StyledTextField
                        placeholder={"Email"}
                        variant="outlined"
                        value={values.email}
                        style={{ ...webStyle.inputfieldHeight, marginBottom: 5 }}
                        data-test-id={"email"}
                        name={"email"}
                        onChange={handleChange} 
                        helperText={<ErrorMessage name="email" />}
                        FormHelperTextProps={{
                          className: "formHelperClass"
                      }}
                      />
                    </Box>
                    <Box style={webStyle.textInputs}>
                      <div style={webStyle.isAccountDiv}>
                        <Typography style={{ ...webStyle.isAccountStatement, fontSize: "12px" }}>
                          The password should have at least one lower case and one upper case characters
                        </Typography>
                      </div>
                      <StyledTextField
                        fullWidth
                        placeholder="Password"
                        data-test-id={"txtPassword"}
                        name="password"
                        style={{ ...webStyle.inputfieldHeight, marginBottom: 10 }}
                        variant="outlined"
                        type={this.state.showPassword ? "text" : "password"}
                        value={values.password}
                        autoComplete='new-password'
                        onChange={handleChange} 
                        helperText={<ErrorMessage name="password" />}
                        FormHelperTextProps={{
                          className: "formHelperClass"
                      }}
                        InputProps={{
                          endAdornment:
                            !this.state.showPassword
                              ? (<VisibilityOffIcon
                                style={webStyle.passwordVisibilityOffIcon}
                                onClick={this.handlePassShow}
                              />)
                              : (<VisibilityIcon
                                style={webStyle.passwordVisibilityOnIcon}
                                onClick={this.handlePassShow}
                              />)
                        }}
                      />
                    </Box>
                    <Box style={webStyle.textInputs}>
                      <StyledTextField
                        fullWidth
                        placeholder="Re-enter Password"
                        data-test-id={"confirmPassword"}
                        style={{ ...webStyle.inputfieldHeight, marginBottom: 2 }}
                        variant="outlined"
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onChange={handleChange}
                        helperText={<ErrorMessage name="confirmPassword" />}
                        FormHelperTextProps={{
                          className: "formHelperClass"
                      }}
                        InputProps={{
                          endAdornment:
                            !this.state.showConfirmPassword
                              ? (<VisibilityOffIcon
                                style={webStyle.passwordVisibilityOffIcon}
                                onClick={this.handleConfirmPassShow}
                                data-test-id="showConfirmPassword"
                              />)
                              : (<VisibilityIcon
                                style={webStyle.passwordVisibilityOnIcon}
                                onClick={this.handleConfirmPassShow}
                                data-test-id="hideConfirmPassword"
                              />)
                        }}
                      />
                    </Box>
                    <Box>
                      <div style={{ ...webStyle.isAccountDiv, paddingTop: 29 }}>
                        <Typography style={{ ...webStyle.isAccountStatement, fontSize: "13px"}}>
                          By joining Forge you agree to the<span
                            data-test-id={"termsConditions"}
                            style={{
                              ...webStyle.signUplink,
                              fontSize: "12px",
                              fontFamily: 'Silika-Medium',
                              textDecoration: 'underline'
                            }}
                            onClick={this.handleTermsNavigate}>
                            Terms & Conditions </span>and
                          <span
                            onClick={this.handlePrivacyPolicyClick}
                            data-test-id={"privacyPolicy"}
                            style={{ ...webStyle.signUplink, fontFamily: 'Silika-Medium', fontSize: "12px",  textDecoration: 'underline' }}>Privacy Policy</span>
                        </Typography>
                      </div>
                    </Box>
                    <div >
                      <StyledButton
                        variant="outlined"
                        style={{ ...webStyle.logInButton, textTransform: "capitalize" }}
                        type="submit"
                        data-test-id='submitBtn'
                      >
                        Join Organization
                      </StyledButton>
                    </div>
                  </form>
                )}
              </Formik>
            </Box>
          </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

