import React from "react";

// Customizable Area Start
import TermsController, { Props } from "./TermsController";
import Modal from "./components/Modal";

// Customizable Area End

export default class TermsAndConditions extends TermsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
        <Modal
          open={true}
          title="Terms And Conditions"
          description={this.state.description}
          handleClosePopUp={this.props.onClose}
        />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End


