import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { StyledBackButtonFloat, StyledButtonFloat, webStyle } from "../../email-account-login/src/LoginSignUp.web";
import ClientController, {Props} from "./ClientController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Silika-Medium"
    }
});
// Customizable Area End

export default class MembershipConfirmation extends ClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{
          overflow: 'hidden'
        }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box width={{xs : "300px", sm : "327px",  md: "727px"}}>
                <Typography style={webStyle.membershipPlanTitle}>Your membership Plan</Typography>
                <div style={webStyle.selectedMemberShipBlock}>
                    <div>
                        <Typography style={webStyle.selectedPlan}>Enterprise Plan</Typography>
                        <Typography style={webStyle.selectedPlanFeature}>This plan includes 6 seats.
                        </Typography>
                        <Typography style={{...webStyle.subTitle, textDecoration : "underline"}}>Plan Details
                        </Typography>
                    </div>
                    <Typography style={webStyle.selectedPlan}>£ 80</Typography>
                </div>
                <div style={webStyle.billingBlock}>
                    <Typography style={webStyle.subTitle}>
                        Monthly Billing schedule
                    </Typography>
                    <div style={webStyle.billingRight}>
                    <Typography style={{...webStyle.subTitle, color : "#9B9B9B"}}>
                        Next payment date
                    </Typography>
                    <Typography style={webStyle.subTitle}>
                        23rd August, 2024
                    </Typography>
                    </div>
                </div>
                <div style={webStyle.backAndNextbtnDiv} >
                <StyledBackButtonFloat
                  variant="outlined"
                  style={{
                    ...webStyle.logInButton,
                    backgroundColor: "none",
                    border: "1px solid #0D0C22",
                    color: "#0D0C22",
                  }}
                  type="submit"  
                  data-test-id="confirmationBackButton"
                >
                  Back
                </StyledBackButtonFloat>
                <StyledButtonFloat
                  variant="outlined"
                  style={{ ...webStyle.logInButton }}
                  type="submit"
                  data-test-id="ConfirmationNextBtn"
                >
                  Next
                </StyledButtonFloat>
              </div>
            </Box>
          </LoginSignUp></div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


