import React, { Component } from 'react';
const coverImage = require("./coverImage.png");

const aspectRatio = (489 / 1440) * 100;
const coverImageHeight = window.innerWidth * aspectRatio;

export default class CoverImageComponent extends Component {
    render() {
        return (
            <div style={webStyles.coverImageWrapper}>
                <div style={webStyles.coverImage} />
            </div>
        );
    }
}

const webStyles = {
    coverImageWrapper: {
        position: 'relative' as 'relative',
        width: '100%', // Full width of the parent
        paddingTop: `${aspectRatio}%`, // Aspect ratio of 489px / 1440px
        backgroundColor: '#ccc', // Optional: for better visibility
      },
      coverImage: {
        position: 'absolute' as 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${coverImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
}