import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  SubscriptionPlan,
  SubscriptionPlanFeature,
  FeatureCategory,
} from "../../../components/src/types";
// Customizable Area Start
import { IFirstPageData } from "./LandingPageController";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
    // Customizable Area Start
    landingPageData:IFirstPageData[]
    subscriptionPlans: SubscriptionPlan[];
    subscriptionFeatureCategories: FeatureCategory[];
    subscriptionFeatures: SubscriptionPlanFeature[];
    viewAllFeaturesOpen: boolean;
    windowWidth: number;
    // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class LandingPage3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    getLandingPagedataApiId:string="";
    fetchSubscriptionPlansApiCallId : string | null = "";
    fetchSubscriptionFeatureCategoriesCallId: string | null = "";
    fetchSubscriptionFeaturesCallId: string | null = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            // Customizable Area Start
            landingPageData:[],
            subscriptionPlans: [],
            subscriptionFeatureCategories: [],
            subscriptionFeatures: [], 
            viewAllFeaturesOpen: false,
            windowWidth: window.innerWidth
            // Customizable Area End
        };
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.handleResize = this.handleResize.bind(this);
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const responseType = getName(MessageEnum.RestAPIResponceMessage);
        if(responseType === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if(!responseJson.errors){
                switch(apiRequestCallId){
                    case this.fetchSubscriptionPlansApiCallId: {
                        this.setState({subscriptionPlans: responseJson.data});
                        break;
                    }

                    case this.fetchSubscriptionFeatureCategoriesCallId: {
                        this.setState({ subscriptionFeatureCategories: responseJson.data});
                        break;
                    }
        
                    case this.fetchSubscriptionFeaturesCallId: {
                        this.setState({subscriptionFeatures: responseJson.data});
                        break;
                    }

                    case this.getLandingPagedataApiId:{
                        this.handleLandingPage3ApiResponse(responseJson);
                        break;
                    }

          default: {
            break;
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

    async componentDidMount(){
        this.fetchSubscriptionPlans();
        this.fetchSubscriptionFeatures();
        this.fetchSubscriptionFeatureCategories();
        window.addEventListener("resize", this.handleResize);
        this.handleGetLandingPage3Data()
        this.StorageClear()
      window.scroll({
        top: 0,
        left: 0
      })
    }
  StorageClear(){
      window.localStorage.clear();
      sessionStorage.clear();
    }
  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    const innerWidth = window.innerWidth;
    this.setState({ windowWidth: innerWidth });
  }

    handleScrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    private handleLandingPage3ApiResponse(responseJson: any) {
        this.setState({ landingPageData:responseJson.data });
    }

    handleGetLandingPage3Data = () => {
        const requestMessager = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.getLandingPagedataApiId = requestMessager.messageId;
      
        requestMessager.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getLandingPageDataApiEndPoint}`
        );
      
        requestMessager.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(requestMessager.id, requestMessager);
      
        return true;
      };

  handleFeaturesOpen = () => {
    this.setState((prevState) => ({
      ...prevState,
      viewAllFeaturesOpen: !prevState.viewAllFeaturesOpen,
    }));
  };

  fetchSubscriptionPlans = () => {
    const headers = {
      token: window.localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubscriptionPlansApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionsPlan
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchSubscriptionFeatures = () => {
    const headers = {
      token: window.localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubscriptionFeaturesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionFeatures
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchSubscriptionFeatureCategories = () => {
    const headers = {
      token: window.localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubscriptionFeatureCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionFeatureCategories
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
