import React from 'react'
import {
  Grid, Typography, styled, List, ListItem, ListItemIcon, ListItemText, IconButton
} from "@material-ui/core";
import { SettingHeadingProps1 } from '../../SettingsProfileController';
import { accountLight,visibilityLight,termsLight,projectLight,notificationLight,accountDark,visibilityDark,termsDark,projectDark,notificationDark } from "../../assets"
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const listData = [{
  heading: "Account & Billing",
  descripton: "Settings related to your personal information and account credentials.",
  icon: accountLight,
  icon1: accountDark

}, {
  heading: "Visiblity & Privacy",
  descripton: "Control how your information is seen by others.",
  icon: visibilityLight,
  icon1: visibilityDark
}, {
  heading: "Terms & Conditions",
  descripton: "Privacy Policy and Terms of Use.              ",
  icon: termsLight,
  icon1: termsDark
}, {
  heading: "Project Working",
  descripton: "Define your hourly rate and other project settings.",
  icon: projectLight,
  icon1: projectDark
}, {
  heading: "Notifications",
  descripton: "All settings related to notifications.",
  icon: notificationLight,
  icon1: notificationDark
}
];
class SettingHeading extends React.Component<SettingHeadingProps1>{
  constructor(props: SettingHeadingProps1){
    super(props);
  }

  render(){
    const isMobile = this.props.windowWidth <= 599;
    if(isMobile){
      return(
        <StyledMobileContainer>
            <Typography variant="h4" gutterBottom>Settings</Typography>
            <StyledListContainer>
              {listData.map((item, index) => (
                <StyledListItem button onClick={() => this.props.onTabChange(index)}>
                  <ListItemIcon>
                      <img src={this.props.selectedSetting == index ? item.icon : item.icon1}></img>
                  </ListItemIcon>
                  <ListItemText primary={item.heading}/>
                  <IconButton><KeyboardArrowRightIcon /></IconButton>
                </StyledListItem>
              ))}
            </StyledListContainer>
        </StyledMobileContainer>
      )
    }
  
    return (
      <StyledWebContainer>
        <Typography className="headingText">Settings</Typography>
        <StyledHeadingGrid item container>
          {listData.map((item, index) => {
            return <Grid key={index} container className={this.props.selectedSetting == index ? "selectedStyle" : "subGrid"} onClick={() => this.props.onTabChange(index)}>
              <Grid item  className="iconGrid" lg={2}>
                <img src={this.props.selectedSetting == index ? item.icon : item.icon1}></img>
                
              </Grid>
              <Grid item lg={10}>
                <Typography className={this.props.selectedSetting == index ? "selectedMainText" : "notSelectedMainText"}>{item.heading}</Typography>
                <Typography className={this.props.selectedSetting == index ? "selectedSubText" : "notSelectedSubText"}>{item.descripton}</Typography>
              </Grid>
            </Grid>
          })}
        </StyledHeadingGrid>
      </StyledWebContainer>
    )
  }

}  

const StyledMobileContainer = styled("div")({
  padding: "24px 0px",
  "& h4":{
    fontFamily: "Silika-Medium",
    fontSize: "24px",
    margin: "0 25px 12px 25px"
  }
});

const StyledListContainer = styled(List)({
  padding: "0px"
});

const StyledListItem = styled(ListItem)({
  padding: "0px 25px !important",
  height: "56px",
  borderBottom: "1px solid #E2E8F0",
  "& *":{
    fontFamily: "Silika-Medium",
    fontSize: "16px"
  },
  "& .MuiListItemIcon-root":{
    minWidth: "24px",
    marginRight: "8px"
  },
  "& button":{
    "& svg":{
      fill: "#0D0C22",
      height: "24px",
      width: "24px"
    }
  }
});

const StyledWebContainer = styled("div")({
  padding: "4%",
  backgroundColor: "#FFF",
  boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  display: "flex",  
  flexDirection: "column",
  minHeight: "100vh",
  height: "100%",
  "& *":{
    fontFamily: "Silika-Medium"
  },
  "& .headingText":{
    color: "black",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    paddingLeft:"10px"
  }
});

const StyledHeadingGrid = styled(Grid)({
  paddingTop: "10%",
  cursor:'pointer',
  "& .selectedStyle":{
    padding: "5% 10% 5% 6%",
    backgroundColor: "black",
    borderRadius: "2px",
    width: "100%",
    display: "inline-flex",
    flexWrap: "nowrap",
    '&:hover': {
      cursor: "pointer"
    },
  },
  "& .subGrid":{
    padding: "5% 10% 5% 6%",
    width: "100%",
    display: "inline-flex",
    flexWrap: "nowrap",
    '&:hover': {
      cursor: "pointer"
    },
  },
  "& .iconGrid":{
    "& img":{
      height: "24px",
      width: "24px"
    }
  },
  "& .selectedMainText":{
    color: "white",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .notSelectedMainText":{
    color: "black",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  "& .selectedSubText":{
    color: "white",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    marginTop:"4%",
  },
  "& .notSelectedSubText":{
    color: "#9B9B9D",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    marginTop:"4%",
  }
});

export default SettingHeading
