import React from 'react';
import {
    Box,
    Button,
    Typography,
    Grid,
    Tab,
    Tabs,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    TabProps
} from "@material-ui/core";
import { withStyles, Theme, createStyles, ThemeProvider, createTheme, styled } from '@material-ui/core/styles';
import NewChatController, { IChat, Props, configJSON } from "./NewChatController";
import { kebab, clip, sendIcon, search,} from './assets';
import HeaderNavbar from "../../../components/src/Navbar"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff"
      }
    }
  });

const StyledBox = styled(Box)(({ theme }) =>({
    padding : "0 80px 20px 80px",
    oberflow : "hidden",
    "@media (max-width: 900px)": {
        padding: "0 20px 10px 20px",
    },
    "@media (max-width: 700px)": {
        padding: "0",
    },
    "& h6":{
      textAlign: 'center',
      padding: "0 22.5px 36px 22.5px",
      fontFamily: "Silika-Medium",
      color: "#010101",
      fontSize: "20px",
      lineHeight: "24.13px"
    },

        "& .avatarSize": {
            height: 20,
            width: 20
        },
    
        "& .headingStyles": {
            fontSize: "32px",
            fontWeight: 500,
            fontFamily  :"Silika-Medium",
            lineHeight  :"40px",
            letterSpacing : "-0.15px",
            "@media (max-width: 700px)": {
                fontSize : "24px"
            },
        },
    
        "& .countStyle": {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: "black",
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: "center"
        },
    
        "& .countNumberStyle": {
            color: 'white',
            fontSize: '12px',
            fontWeight: 500,
            zIndex: 1
        },
    
    
        "& .popOver": {
            "&:hover": {
                backgroundColor: "#0D0C22"
            }
        },
    
    
        "& .sendInputBox": {
            width: "100%",
            height: "56px",
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem",
            border: "1px solid #DFDFDF",
            boxSizing : "border-box",
        },
    
    
        "& .searchUserInput": {
            border: "none",
            padding: "1rem 0",
    
        },
    
        "& .sendInput": {
            border: "none",
            padding: "1rem",
        },
    
        "& .sendInputsection": {
            display: "flex",
            width : "90%"
        },

        "& .MessageIcon" : {
            width : "10%"
        },
    
        "& .sendInputUsersection": {
            display: "flex",
            gap: "8px",
            paddingLeft: "8px",
            alignItems: "center",
            borderBottom: "1px solid #DFDFDF",
            "@media (max-width: 700px)": {
                margin: "18px 18px 0 18px",
                border: "1px solid #DFDFDF",
            },
        },
    
        "& .bubbleContainer": {
            width: "100%",
            display: "flex"
        },
    
        "& .bubble": {
            border: "0.5px solid #9B9B9D",
            borderRadius: "10px",
            margin: "5px",
            padding: "10px",
            display: "inline-block"
        },
    
        "& .mutedChat": {
            backgroundColor: "lightgray"
        },
    
        "& .NewMessageButton": {
            backgroundColor: "black",
            "@media (max-width: 700px)": {
                backgroundColor: "transparent",
                boxShadow : "none",
            },
        },
    
        "& .listItemContainer": {
            borderTop: "2px solid #ccc",
            borderBottom: "2px solid #ccc",
            padding: 15,
            display: "flex"
        },
    
        "& .messageButton": {
            backgroundColor: "#000",
            color: "#FFF"
        },
    
        "& .sendMessageBox": {
            border: "1px solid gray",
            borderRadius: "1rem"
        },
    
    
        "& .wrapper": {
            maxWidth: "full full",
            margin: "0 auto",
            display: "grid",
            gridTemplateColumns: "1fr 3fr",
            gridAutoFlow: "column",
            gridTemplateAreas: `
                        "header header"
                        "chatList chatWindow"
                        `,
        },
    
        "& .header": {
            gridColumn: "1 / -1",
            display: "flex",
            alignItems: "center",
            height: "50px",
            justifyContent: "space-between",
            margin: "1rem 0",
            gridArea: "header",
            "@media (max-width: 700px)": {
                padding: "0 18px",
                margin  :0,
                borderTop: "0.5px solid #DFDFDF",
            },
        },
        "& .header.hidden": {
            "@media (max-width: 700px)": {
                display: "none",
            },
        },
    
        "& .box": {
            backgroundColor: "#FFF",
            color: "#000",
            borderRadius: "5px",
            padding: "20px",
            fontSize: "1rem",
            margin: 0,
            width: "auto",
        },
    
        "& .chatContainer": {
            display: "grid",
            height: "calc(100vh - 200px)",
            border: "1px solid #9B9B9D",
            gridTemplateColumns: "1fr 3fr",
            gridTemplateAreas: `"chatList chatWindow"`,
            overflow : "hidden",
            borderRadius  :"4px",
            "@media (max-width: 700px)": {
                gridTemplateColumns: "1fr",
                gridTemplateAreas: "chatList",
                height: "calc(100vh - 100px)",
                border : "none",
            },
        },
    
        "& .chatList": {
            height : "calc(100vh - 205px)",
            gridArea: "chatList",
            overflowY : "hidden",
            minWidth: "280px",
            "@media (max-width: 700px)": {
                height : "calc(100vh - 150px)",
            },
        },
    
        "& .chatWindow": {
            display: "grid",
            height : "100%",
            gridTemplateColumns: "1fr",
            placeContent: "space-between",
            gridArea: "chatWindow",
            border: "1px solid #DFDFDF",
            boxSizing : "border-box",
            "@media (max-width: 700px)": {
                display : "none"
            },
        },
    
        "& .chatlistContent": {
            display: "grid",
            gridTempleateColumns: "1fr 3fr 1fr 1fr",
            gridTemplateAreas: `
                        "Avatar Content Time Options"
                        `,
            gridGap: "10px",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            borderBottom: "1px solid #dbdbdb",
        },
    
        "& .RequestSelectedOption": {
            borderBottom: "1px solid #dbdbdb",
        },
    
        "& .Avatar": {
            gridArea: "Avatar",
            placeContent: "center"
        },
    
        "& .Content": {
            gridArea: "Content",
            placeContent: "left"
        },
    
        "& .Time": {
            gridArea: "Time",
            placeContent: "center"
    
        },
    
        "& .Options": {
            gridArea: "Options",
            placeContent: "center"
        },

        "& .chatListItems" : {
            height : "calc(100vh - 305px)",
            overflowY : "scroll",
            "@media (max-width: 700px)": {
                padding: "0 18px",
                height : "calc(100vh - 272px)",
            },
        },
        "& .chatWindow.visible": {
            "@media (max-width: 700px)": {
                display: "grid",
                width : "100vw",
                height : "100%"
            },
        },
        
        "& .chatList.hidden": {
            "@media (max-width: 700px)": {
                display: "none",
            },
        },
        "& .tabsContainer" : {
            height: 'auto', 
            borderBottom : "1px solid #9B9B9D",
            "@media (max-width: 700px)": {
                margin: "0 18px",
            },
        },
        "& .addBtn" : {
            "@media (max-width: 700px)": {
                color : "black",
                padding: "0 0 0 1rem" 
            },
        },
        "& .noChtSelected" :{
           display : "flex",
           alignItems : "center",
           justifyContent  :"center"
        },
        "& .noChtSelected_txt" : {
            fontFamily : "Silika-Medium",
            fontWeight : 500,
            fontSize : "32px",
            lineHeight  :"38.16px",
            "@media (max-width: 1000px)": {
                fontSize : "24px",
            },
        },
        "& .acceptMessageGrid" : {
            padding  :"12px",
            borderTop : "1px solid #9B9B9D",
            display : "flex",
            justifyContent : "space-between",
            alignItems : "center",
            "@media (max-width: 1000px)": {
                flexDirection : "column",
                alignItems : "center",
                gap : "24px",
                padding :"12px 0 24px 0",  
            },
        },
        "& .acceptButtons" : {
            display : "flex",
            gap : "15px",
            "@media (max-width: 1000px)": {
                justifyContent : "space-between",
                width : "300px",    
            },
        },
        '& .commonBtn': {
            height: '36px',
            width: '104px',
            borderRadius: '2px',
            border: '1px solid black',
            boxShadow: 'none',
            textTransform : "none",
            '&:hover': {
                backgroundColor: 'black',
                color: 'white',
                boxShadow: 'none',
            },
            "@media (max-width: 1000px)": {
                width : "50%"      
            },
        },
        '& .acceptBtn': {
            backgroundColor: 'black',
            color: 'white',
          },
          '& .rejectBtn': {
            backgroundColor: 'transparent',
            color: 'black',
          },
          '& .rejectBtn:hover + .acceptBtn': {
            backgroundColor: 'transparent',
            color: 'black',
            border: '1px solid black',
          },
          '& .acceptBtn:hover + .rejectBtn': {
            backgroundColor: 'transparent',
            color: 'black',
            border: '1px solid black',
          },
        "& .acceptMessageTxt" : {
            fontFamily : "Silika-Light",
            fontWeight : 300,
            fontSize : "14px",
            lineHeight  : "19.69px",
            "@media (max-width: 400px)": {
                fontSize : "12px",     
            },
        },
        "& .acceptMessageprofile" : {
            fontFamily : "Silika-Medium",
            fontWeight : 700,
        },
        "& .headerBlock" : {
            backgroundColor: "#0D0C22",
            display : "flex",
            "@media (max-width: 700px)": {
                backgroundColor: "transparent",    
            },
        },
        "& .headerProfileTxt" : {
            color : "white",
            "@media (max-width: 700px)": {
                color: "black",    
            },
        },
        '& .popOverBtnStyle': {
            border : "1px solid blue !important" 
        },
  }));


const CustomTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            width : "50%",
            color: "#9B9B9D",
            "&.Mui-selected": {
                color: "black"
            },
        },

    })
)((props: TabProps) => <Tab {...props} />);


const getImageUrl = (id: string): string => {
    // Replace with your logic to generate image URLs
    return `https://material-ui.com/static/images/avatar/${id}.jpg`;
};


export default class NewChat extends NewChatController {

    constructor(props: Props) {
        super(props);
    }

    tabs = () => {
        const handleChange = (event: any, newValue: any) => {
            if (newValue !== this.state.selectedTab) {
                this.setState(
                    { selectedTab: newValue }
                );
            }
        };
        return (
                <Tabs
                    data-test-id={"selectedTab"}
                    value={this.state.selectedTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    className='tabsContainer'
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "black"
                        }
                    }}
                >
                    <CustomTab label="Chats" />
                    <CustomTab label={`Requests (${0})`} />
                </Tabs>
        )
    }

    header = (profile: any) => {
        const {isMobileView} = this.state
        return (
            <Box width="100%" >
                {profile ?
                    <ListItem className='headerBlock'>
                        {isMobileView && <ChevronLeftIcon sx={{fontSize : "32px", marginRight : "24px"}} onClick={this.handleCloseChat} />}
                        <ListItemIcon>
                            <Avatar alt="Remy Sharp" src={getImageUrl(profile.id)} />
                        </ListItemIcon>
                        <Box>
                            <ListItemText className='headerProfileTxt' primary={profile.name}></ListItemText>
                        </Box>
                        <Box style={{ marginLeft: "auto" }}>
                            <MoreVertIcon sx={{color : isMobileView ? "black"  :"white" }} />
                        </Box>
                    </ListItem>
                    :

                    <div>
                        <Typography></Typography>
                    </div>}
            </Box>
        )
    }

    searchBar = () => {
        return (
            <>
                <Grid item xs={12}>
                    <div className='sendInputUsersection'>
                        <div>
                            <img src={search} />
                        </div>
                        <div>
                            <input onFocus={this.removeFocusBorder} onChange={this.handleSearch} type="text" placeholder="Search" name="search" className='searchUserInput'/>
                        </div>
                    </div>
                </Grid>
                {/* <Divider /> */}
            </>
        )
    }

    chatlistUielement = (item: IChat, index: number, openx: any, id: any) => {
        return (
            <List data-test-id="chatListItem" onClick={() => this.handleChatSelected(item)} style={{ height: '76px', borderBottom: '1px solid #DFDFDF' }}>
                <ListItem button key={item.id}>
                    <ListItemIcon>
                        <Avatar alt="Remy Sharp" src={getImageUrl(item.id)} />
                    </ListItemIcon>
                    <Box>
                        <ListItemText primary={item.name}></ListItemText>
                        <ListItemText secondary={item.lastMessage}></ListItemText>
                    </Box>
                    <Box style={{ marginLeft: "auto" }}>
                        <ListItemText secondary={item.time + 'h'}></ListItemText>
                        {item.unreadCount > 0 &&
                            <Box className='countStyle'>
                                <Typography className='countNumberStyle'>{item.unreadCount}</Typography>
                            </Box>
                        }

                    </Box>
                    <Box>
                        <img src={kebab} alt="More"  style={{ cursor: "pointer" }} />
                    </Box>
                </ListItem>
            </List>
        )
    }


    renderRoomList = () => {

        const openx = Boolean(this.state.anchorEl);
        const id = openx ? "simple-popover" : undefined;

        const filteredList = this.getFilteredChatList();

        return (
            filteredList.map((item: IChat, index: number) => (
                this.chatlistUielement(item, index, openx, id)
            )
            )
        )
    }

    requestsScreen = () => {
        const openx = Boolean(this.state.anchorEl);
        const id = openx ? "simple-popover" : undefined;
        const filteredList = this.getFilteredChatList();

        return (
            filteredList.map((item: IChat, index: number) => (
                this.chatlistUielement(item, index, openx, id)
            )
            )
        )
    }

    chatbubbles = () => {
        return (
            <div style={{ padding: "24px" }}>
                <Box style={{ width: "80%", minHeight: "60px", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: '100%', minHeight: "42px", backgroundColor: "#DFDFDF", borderRadius: "16px 16px 16px 4px", display: "flex", alignItems: "center", paddingLeft: "16px" }}>
                            <Typography style={{ fontWeight: 300, fontSize: "14px" }}>
                                Here is the file you were searching for
                            </Typography>
                    </div>
                </Box>
            </div>
        )
    }

    removeFocusBorder = (event:any) => {
        event.target.style.outline = 'none';
    }

    sendMessageGrid = (profile: any) => {
        return (
            <Grid container style={{width : '95%', margin: 'auto', marginBottom : '8px' }}>
                 <div className='sendInputBox'>
                        <div className='sendInputsection'>
                            <img src={clip} style={{ paddingTop: "0.5rem" }} />
                            <input onFocus={this.removeFocusBorder} type="text" placeholder={"message "+profile.name.split(' ')[0]} name="search" className='sendInput' data-test-id="chat-input" onChange={this.chatInputData} />
                        </div>
                        <div className='MessageIcon'>
                            <img src={sendIcon} />
                        </div>
                    </div>
            </Grid>
        )
    }

    acceptMessageGrid = (profile: any) => {
        return (
            <div className='acceptMessageGrid'>
                <Typography className='acceptMessageTxt'>
                    <span className='acceptMessageprofile'>{profile.name}</span> wants to send you a message
                </Typography>
                <div className='acceptButtons'>
                    <Button variant="contained" className="rejectBtn commonBtn">Reject</Button>
                    <Button variant="contained" className="acceptBtn commonBtn">Accept</Button>
                </div>
            </div>
        )
    }

    render() {
        const selectedUserProfile = configJSON.chatList.filter((chat: any) => chat.id === this.state.selectedChat)[0] ||null
        const { isChatSelected, isNewChat} = this.state;
        const isMobile = window.innerWidth <= 700;
        return (
            <ThemeProvider theme={theme}>
            <div style={{overflow : "hidden"}}>
             <HeaderNavbar navigation={this.props.navigation} />
            <StyledBox >
            <div className={`header ${isMobile && isChatSelected ? 'hidden' : ''}`}>
                <div>
                    <Typography className='headingStyles' >Chat</Typography> </div>
                <div>
                    <Button className='NewMessageButton' variant="contained" color="primary">
                    {!isMobile && "New Message"}
                    <AddCircleOutlineIcon className='addBtn' />
                </Button></div>
            </div>
            <div className='chatContainer'>
                    <div className={`chatList ${isMobile && isChatSelected ? 'hidden' : ''}`}>
                        {this.tabs()}
                        {this.searchBar()}
                        <div className='chatListItems'>
                            {this.state.selectedTab === 0 ? this.requestsScreen(): this.renderRoomList() }
                        </div>
                    </div>
                    {isChatSelected ? 
                    <div  className={`chatWindow ${isMobile && isChatSelected ? 'visible' : ''}`}>   
                        <div>
                            {this.header(selectedUserProfile)}
                        </div>
                        <div>
                            {this.chatbubbles()}
                            {!isNewChat ? this.sendMessageGrid(selectedUserProfile) : this.acceptMessageGrid(selectedUserProfile)}
                        </div>
                    </div>
                     :<div className={`chatWindow noChtSelected`}>
                        <Typography className='noChtSelected_txt'>
                            No chat selected
                        </Typography>
                     </div>}
            </div>
            </StyledBox>
            </div>
            </ThemeProvider>
        )
    }
}

