import React from "react";
// Customizable Area Start
import {
  Button, Grid, Dialog, DialogActions, DialogContent,  DialogTitle, DialogContentText, Typography,TextField, Chip, Box, MenuItem,Select
} from "@material-ui/core";
import { makeStyles, Theme, createStyles,withStyles,styled } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { closeImage } from "../assets"
import "react-datepicker/dist/react-datepicker.css";
import { S } from "../ProfileInfoController";
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as Yup from 'yup';
import { ICountry,Country } from "country-state-city";
import { checkCondition } from "../../../../components/src/HelperUtils";


const validationSchemaEducation = Yup.object().shape({
  institution: Yup.string().required('Institution is required'),
  qualification: Yup.string().required('Qualification is required'),
  fieldOfStudy: Yup.string().required('Field of study is required'),
  startMonth: Yup.string().required('Start month is required'),
  startYear: Yup.string().required('Start year is required'),
  endMonth: Yup.string().when(['startYear', 'endYear'], {
    is: (endYear) =>  !!endYear, 
    then: Yup.string().required('End Month is required')
      .test('is-greater-or-equal', 'End Month must be greater than or equal to Start Month when End Year is the same as Start Year', function (value) {
        const { startMonth, startYear, endYear } = this.parent;
        const monthMap: Record<string, number> = {January: 1, February: 2, March: 3, April: 4, May: 5, June: 6,July: 7, August: 8, September: 9, October: 10, November: 11, December: 12};
        const startMonthNum = monthMap[startMonth];
        const endMonthNum = monthMap[value];
        if (endYear === startYear) {
          return endMonthNum > startMonthNum;
        }
        return true;
      }),
    otherwise: Yup.string().nullable()
  }),
  Country: Yup.string().required('Country is required'),
  endYear: Yup.string().required('End year is required').test('is-greater', 'End year must be greater than or equal to start year', function(value) {
    const { startYear } = this.parent;
    return value >= startYear;
  }),
 
  
});

type Props = {
  state: S
  handleEducationClose: () => void
  addEducatioin:(value:any)=>void
  editSingleEducation:(value:any) => void
  getApiCalled:(value:any) => void
  openDeleteEducation: (eduId: string) => void
}

interface Option {
  name: string;
  isoCode: string;
  flag: string;
  phonecode: string;
  currency: string;
  latitude: string;
  longitude: string;
  timezones: TimeZone[];
}
interface TimeZone {
  zoneName: string;
  gmtOffset: number;
  gmtOffsetName: string;
  abbreviation: string;
  tzName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    InnerExperience: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "1195px",
      background: "#000",
      borderRadius: "4px, 4px, 0px, 0px",
      fontSize: "14px !important",
      fontWeight: 500,
      fontFamily: "Silika-Light",
      color: "white",
      lineHeight: "19.3px",
    },
    outlneButton: {
      borderRadius: "2px",
      borderColor: "black",
    },
    HeadingDialoug: {
      fontSize: "16px",
      fontFamily: "Silika-Medium",
      fontWeight: 500,
      lineHeight: "19px",

    },
    
    filledButton: {
      borderRadius: "2px",
      backgroundColor: "black",
      color: "white",
    },
  })
);

const CustomSelect = styled(Select)({
  marginTop: "4px",
  width: "100%",
  "& .MuiSelect-root": {
    display: "flex",
    alignItems: "center"
  },
  "& .MuiInputBase-input": {
    width: "100% !important",
    height: "48px !important",
    fontFamily: "Silika-Light",
    fontSize: "16px",
  },
})

const TextFieldCustomText = styled(TextField)({
  width: "100%",
  marginTop: '4px',
  "& .MuiInputBase-input": {
    width: "100% !important",
    maxHeight: "100px !important",
    fontFamily: "Silika-Light",
    fontSize: "16px",
    color: "#010101",
    overflow: "auto !important",
    "&::placeholder": {
      fontFamily: "Silika-Medium !important",
      fontSize: "16px",
      color: "#9B9B9D !important",
    },
  },
})

const StyledAutocomplete = withStyles({
  inputRoot: {
    paddingTop: "4px !important",
    paddingBottom: "4px !important",
    minHeight: "48px !important",
    '& .MuiInputBase-input': {
      height: '30px !important',
      fontFamily: "Silika-Light",
      fontSize: "16px",
      minWidth: '0px !important',
      "&::placeholder": {
        fontFamily: "Silika-Medium !important",
        fontSize: "16px",
        color: "#9B9B9D !important",
      },
    },
  },
  option: {
    fontFamily: "Silika-Medium",
    fontSize: "14px",
    color: "#0D0C22",
    padding: "7px 16px !important"
  },
  popupIndicator: {
    marginRight: "-4px"
  }
})(Autocomplete);

const CustomButton = styled(Button)({
  "&:hover": {
      backgroundColor: "black !important",
  },
  "&.blackBackground": {
    color: "white",
    backgroundColor: "black",
},
  "& .MuiButton-contained.Mui-disabled":{
      color:'white',
      backgroundColor: "lightgray",
      boxShadow:'none'
  }
})
const MenuItemFont = styled(MenuItem)({
  fontFamily: "Silika-Medium",
  fontSize: "14px",
  color: "#0D0C22",
  padding: "7px 16px !important"
})



function EditEducation(props:Props) {
  const { state, handleEducationClose,getApiCalled,openDeleteEducation } = props;
  const classes = useStyles();
  let CounntryArray: ICountry[] = Country.getAllCountries()
  const monthsValues = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" }
  ];
  const YearList = (new Date()).getFullYear();
 const AllYearLIst = Array.from(new Array(51), (_value, index) => YearList - index);

  const nextYears: number = YearList + 10
  const EndYearsRange = Array.from(new Array(61), (_value, index) => nextYears - index);
  const EndYearsList = EndYearsRange.map(years => {
    return { value: years.toString(), label: years.toString() };
  });

  const allYearsObject = AllYearLIst.map(YearList => {
      return { value: YearList.toString(), label: YearList.toString() };
  });

  const conditionRender=(values:string,placeholder:string)=>{
    return values ? undefined : () => <div style={webStyle.placeholderStyle}>{placeholder}</div>
  }

  const fieldValueData = (newInputValue: string) => {
    return newInputValue === null ? "" : newInputValue
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={state.editEducation}
      aria-describedby="alert-dialog-slide-description"
    >
      <ModalHeaderWrapper>
        <ModalTitle >{`${state.educationId ? "Edit": "Add"}  Education`}</ModalTitle>
        <img onClick={handleEducationClose} style={webStyle.closeImage} src={closeImage}></img>
      </ModalHeaderWrapper>
      <ModalContentWrapper>
        
          <Formik
            initialValues={state.editEducationInitialValue}
            onSubmit={(values) => {
              getApiCalled(values)
              handleEducationClose()
            }}
          validateOnMount={true}
          validationSchema={validationSchemaEducation}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              isValid,
            }) => {
              return (
                <Form style={{marginBottom:8}}>

                  <FieldsWrapper>
                  <Grid container style={webStyle.styleMargin}>
                    <GridItem item lg={12} md={12} sm={12}>
                      <FormLabelFont>Institution<span>*</span></FormLabelFont>
                      <StyledAutocomplete
                        popupIcon={<KeyboardArrowDownIcon />}
                        disableClearable
                        style={webStyle.autoComplateStyle}
                        value={values.institution}
                        id="combo-box-demo"
                        data-testId="institution"
                        options={state.InstituteList?.map(option => option?.attributes?.name) || []} 
                        getOptionLabel={(option) => (option as string) }
                        renderInput={(params) => <TextField {...params} variant="outlined"  placeholder="Eg. Harvard" />}
                        freeSolo
                        onChange={(_event, newValue) => { 
                          setFieldValue('institution', newValue === null ? "" : newValue)
                        }}
                        onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
                          const newInputValue = (event.target as HTMLInputElement).value;
                          setFieldValue('institution', fieldValueData(newInputValue))
                        }}
                      />
                      </GridItem>

                    </Grid>
                    <Grid container style={webStyle.styleMargin}>
                    <GridItem item lg={12} md={12} sm={12}>
                    <FormLabelFont>Qualification Type<span>*</span></FormLabelFont>
                      <TextFieldCustomText
                        name="qualification"
                        variant="outlined"
                        value={values.qualification}
                        onChange={handleChange}
                        placeholder="Eg. Masters"
                      />
                    </GridItem>

                  </Grid>
                  <Grid container style={webStyle.styleMargin}>
                    <GridItem item sm={12}>
                    <FormLabelFont>Field of Study<span>*</span></FormLabelFont>
                      <TextFieldCustomText
                        name="fieldOfStudy"
                        variant="outlined"
                        value={values.fieldOfStudy}
                        onChange={handleChange}
                        placeholder="Eg. UX Design"
                      />
                    </GridItem>

                  </Grid>

                  <Grid container style={webStyle.styleMargin}>
                  <GridItem item sm={12}>
                  <FormLabelFont>Country<span>*</span></FormLabelFont>
                      <StyledAutocomplete
                        popupIcon={<KeyboardArrowDownIcon />}
                        disableClearable
                        style={webStyle.autoComplateStyle}
                        id="combo-box-demo"
                        data-testId="country"
                        value={values.Country || ""}
                        options={CounntryArray.map((options) => options)}
                        getOptionLabel={(option: unknown) => (option as Option)?.name}
                        renderInput={(params) => <TextField {...params} variant="outlined"  placeholder="Country" />}
                        onChange={(_event, newValue) => {
                          setFieldValue('Country', newValue === null ? "" : newValue)
                        }}
                      />
                    </GridItem>
                  </Grid>
                  
                  <TwoGridWrapper container spacing={2}>
                    <GridItem item sm={6}>
                  <FormLabelFont>Start Date<span>*</span></FormLabelFont>
                    <CustomSelect
                        data-testId='startMonth'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.startMonth}
                        displayEmpty
                        renderValue={conditionRender(values.startMonth, "Month")}
                        disableUnderline
                        name='startMonth'
                        variant="outlined"
                        onChange={handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {monthsValues.map((month) => <MenuItemFont key={month.value} value={month.value}>{month.label}</MenuItemFont>)}
                      </CustomSelect>
                    </GridItem>
                    <GridWithoutLabel item sm={6}>
                    <CustomSelect
                        data-testId='startYear'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.startYear}
                        displayEmpty
                        renderValue={conditionRender(values.startYear, "Year")}
                        disableUnderline
                        name='startYear'
                        variant="outlined"
                        onChange={handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {allYearsObject.map((years) => <MenuItemFont key={years.value} value={years.value}>{years.label}</MenuItemFont>)}
                      </CustomSelect>
                    </GridWithoutLabel>
                  </TwoGridWrapper>

                  <TwoGridWrapper container spacing={2}>
                    <GridItem item sm={6}>
                  <FormLabelFont>End Date <span style={webStyle.endDateStyle}>(Or Expected)</span><span>*</span></FormLabelFont>
                      <CustomSelect
                        data-testId='endMonth'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.endMonth}
                        displayEmpty
                        renderValue={conditionRender(values.endMonth, "Month")}
                        disableUnderline
                        name='endMonth'
                        variant="outlined"
                        onChange={handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {monthsValues.map((month) => <MenuItemFont key={month.value} value={month.value}>{month.label}</MenuItemFont>)}
                      </CustomSelect>
                    </GridItem>
                    <GridWithoutLabel item sm={6}>
                       <CustomSelect
                        data-testId='endYear'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.endYear}
                        displayEmpty
                        renderValue={conditionRender(values.endYear, "Year")}
                        disableUnderline
                        name='endYear'
                        variant="outlined"
                        onChange={handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {EndYearsList.map((years) => <MenuItemFont key={years.value} value={years.value}>{years.label}</MenuItemFont>)}
                      </CustomSelect>
                    </GridWithoutLabel>
                  </TwoGridWrapper>
                  <Grid container style={webStyle.styleMargin}>
                  <GridItem item sm={12}>
                  <FormLabelFont>Description</FormLabelFont>
                      <TextFieldCustomText
                        name="description"
                        variant="outlined"
                        value={values.description}
                        onChange={handleChange}
                        placeholder="Eg. Jaguar Land Rover"
                        multiline
                        minRows={5}
                      />
                  </GridItem></Grid>

                  <Grid container>

                  <GridItem item sm={12}>
                  <FormLabelFont>Skills</FormLabelFont>
                      <StyledAutocomplete
                        style={webStyle.autoComplateStyle}
                        popupIcon={<KeyboardArrowDownIcon />}
                        multiple
                        limitTags={4}
                        id="tags-standard"
                        getOptionLabel={(options) => options as string}
                        onChange={(_event, newValue1) => {
                          setFieldValue('skills', newValue1);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, indexx) => (
                            <Chip
                              key={indexx}
                              variant="outlined"
                              label={
                                <Box component={'span'}>
                                  <ChipText noWrap>{option as string}</ChipText>
                                </Box>
                              }
                              {...getTagProps({ index: indexx })}
                              style={webStyle.ChipStyle}
                            />
                          ))
                        }
                        options={state.skill}
                        value={values.skills}
                        
                        data-testId="skillsdata"
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            placeholder={checkCondition(Boolean(values.skills.length), "", "Eg Figma") as string}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                        
                      />
                    </GridItem>
                  </Grid>
                  </FieldsWrapper>
                  <ButtonWrapper>
                  {state.educationId ?
                      <Button
                        variant="outlined"
                        className={classes.outlneButton}
                        onClick={() => openDeleteEducation(String(state.educationId))}
                        data-testid="delete-education-btn"
                      >
                        Delete
                      </Button>
                    :
                    <Button variant="outlined" className={classes.outlneButton} onClick={handleEducationClose}>Cancel</Button>
                  }
                    <CustomButton variant="contained" type="submit" className={`${classes.filledButton} ${isValid ? 'blackBackground' : ''}`} disabled={!isValid}>Save</CustomButton>
                  </ButtonWrapper>
                </Form>
              );
            }}
          </Formik>
      </ModalContentWrapper>

    </Dialog>
  )
}

export default EditEducation

const webStyle = {

  typographyStyle: {
    marginBottom: "-4px",
    color: '#000'
  },
  autoComplateStyle: {
    marginTop: '4px',
  } as React.CSSProperties,
  ChipStyle: {
    borderRadius: '3px'
  },
  marginStyle: {
    marginTop: "10px"
  },
  startYearMaxHeight: {
    maxHeight: '400px'
  },
  placeholderStyle: {
    color: "#b7b6b6",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
  } as React.CSSProperties,
  dateMarginStyle: {
    marginTop: '20px'
  },
  closeImage: {
    width: "20px",
    cursor: "pointer"
  } as React.CSSProperties,
  endDateStyle: {
    color: 'gray',
    fontSize: '14px'
  },
  starColor:{
    color:'red'
  },
  styleMargin: {
    marginBottom: '20px'
  } as React.CSSProperties
}
const ModalHeaderWrapper = styled(Box)({
  backgroundColor: "#0D0C22",
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 40px",
  "@media (max-width:600px)": {
    padding: "20px",
  }
})
const ModalTitle = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Silika-Medium",
  fontWeight: 500,
  lineHeight: "19px",
  color: "#fff"
})
const ModalContentWrapper = styled(DialogContent)({
  padding: "0 !important"
})
const FieldsWrapper = styled(Box)({
  padding: "35px 40px",
  "@media (max-width:600px)": {
    padding: "20px",
  }
})
const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "end",
  columnGap: "16px",
  padding: "16px 40px",
  "@media (max-width:600px)": {
    padding: "16px 20px",
  }
})

const FormLabelFont = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Silika-Medium",
  fontWeight: 500,
  lineHeight: "19px",
  color: "#000000",
  letterSpacing:"-0.15px",
  "& span":{
    color:"red"
  }
})
const GridItem = styled(Grid)({
  width: "100%"
})
const TwoGridWrapper = styled(Grid)({
  marginBottom: "12px"
})
const GridWithoutLabel = styled(Grid)({
  width: "100%",
  marginTop: "20px",
  "@media (max-width:600px)": {
    marginTop: "0px",
  }
})
const ChipText = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Silika-Light",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#010101",
})