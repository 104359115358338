import React  from "react";
import { Theme, styled, Dialog, DialogActions, DialogContent, Button, Typography, Box } from "@material-ui/core";

interface Props {
    open: boolean,
    title :string,
    onCancelClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onSuccessClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    cancelText?: string;
    successText?: string;
    onClose?: () => void;
  }

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& *': {
      fontSize: "16px",
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
      borderRadius: 4,
      boxShadow: 'none'
    },
    "& .headingBar" : {
        height : "60px",
        boxSizing: 'border-box' as 'border-box',
        width : "100%",
        backgroundColor : "#0D0C22",
        padding : "0 48px",
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
    },
    "& .blockHeadding" : {
        color : "white",
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize : "16px",
        lineHeight : "19.3px"
    },
    "& .dialogContainer" : {
        padding : "24px",
        display  :"flex",
        flexDirection  :"column",
    }
  }));
  
const StyledDialogActions = styled(DialogActions)(({ theme }: {theme: Theme}) => ({
    justifyContent: "flex-end",
    display: 'flex',
    padding : 0,
    gap : "16px",
    [theme.breakpoints.down('sm')] :{ 
      flexFlow: "column",
      gap : "10px",
    },
    '& button': {
      fontFamily: "Silika-Medium",
      textTransform: "none",
      boxShadow: "none",
      height: "48px",
      borderRadius: "2px",
      [theme.breakpoints.down('sm')] :{ 
        width  :"100%",
      },
    },
    "& .cancelBtn": {
      border: "1px solid #010101",
      color: "#010101",
    },
    "& .successBtn": {
      background: "#0D0C22",
      marginLeft : 0
    }
}));
  
const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: '100px',
}));


class DialogWithBtns extends React.Component<Props>{
    constructor(props: Props){
        super(props);
    }

    render(){
        return(
            <StyledDialog open={this.props.open} onClose={this.props.onClose} disableEscapeKeyDown={false}>
                <Box>
                    <div className="headingBar">
                        <Typography className="blockHeadding">
                            {this.props.title}
                        </Typography>
                    </div>
                    <Box className="dialogContainer">
                        <div>
                            {this.props.children}
                        </div>
                        <StyledDialogActions>
                            <StyledButton data-test-id="cancelButton" onClick={this.props.onCancelClick} color="primary" className="cancelBtn">
                                {this.props.cancelText}
                            </StyledButton >
                            <StyledButton type="submit" onClick={this.props.onSuccessClick} color="primary" variant="contained" className="successBtn">
                                {this.props.successText}
                            </StyledButton>
                        </StyledDialogActions>
                    </Box>
                </Box>
            </StyledDialog>
        )
    }
}

export default DialogWithBtns;
