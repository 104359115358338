import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  open:boolean;
  onClose:()=>void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  termsAndConditionsHeading:string;
  generalSiteUsage:string;
  lastRevised:string;
  termsAndConditionsSubHeading:string;
  yourAgreement:string;
  description:string;
  privacyPolicyDescription:string;
  // Customizable Area End
}
 // Customizable Area Start

 // Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPrivacyDataApiCallId: string = "";
  getTermsConditionsApiCallId: string = "";
  setNewPasswordApiCallId: string = "";
  labelTitle: string = "";
  signInApiCallId: string = "";
  timerInterval:ReturnType<typeof setInterval> | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      termsAndConditionsHeading:'',
      generalSiteUsage:'',
      lastRevised:'',
      termsAndConditionsSubHeading:'',
      yourAgreement:'',
      description:'',  
      privacyPolicyDescription:'', 
    };


    this.labelTitle = configJSON.labelTitle;
    this.timerInterval = setInterval(() => {}, 0);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    
    // Customizable Area Start
    this.loadTermsAndConditionsData();
    this.loadPrivacyData();
        // Customizable Area End
  }
  // Customizable Area Start
  
  
  
 
 
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.

    if (this.getTermsConditionsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );   
       this.setState({description:responseJson?.data[0].description})
    }
    if (this.getPrivacyDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );   
      
       let privacyData = responseJson?.data[0].description;
     this.setState({privacyPolicyDescription:privacyData})
    }
    // Customizable Area End
  }
  loadTermsAndConditionsData(){
    const termsConditionsData = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTermsConditionsApiCallId = termsConditionsData.messageId;

      termsConditionsData.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'terms_and_conditions/terms_and_conditions'
      ); 
      termsConditionsData.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethod
      );
    runEngine.sendMessage(termsConditionsData.id, termsConditionsData);
  }

  loadPrivacyData(){
    const privacyData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyDataApiCallId = privacyData.messageId;

    privacyData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'privacy_policy/privacy_policies'
    ); 
    privacyData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
  runEngine.sendMessage(privacyData.id, privacyData);
  }
  
  
  
 
}
