import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start


interface ArrayForError {
  token: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  accountId: number
  is_connected: boolean
  imageSrc?: string
  imageStyle?: React.CSSProperties
  token: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AddFriendsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  addConnectionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.addConnectionApiCallId) {
        this._handleAddFriendResponse(responseJson)
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start

  _handleAddFriendResponse(responseJson: { message: string; errors: ArrayForError[]; meta: { message: string | string[]; }; }) {
    try {
      if (responseJson?.message) {
        alert(responseJson?.message)
      } else if (responseJson && responseJson.errors && responseJson.errors?.length != 0 && responseJson.errors[0].token) {
        alert('Session Expired')
        const authMessage: Message = new Message(
          getName(MessageEnum.NavigationAuthenticationPage)
        );
        authMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(authMessage);
      } else if (responseJson?.meta?.message.includes("Connection created successfully")) {
        alert('Request sent successfully')
      } else if (responseJson?.meta?.message) {
        alert(responseJson?.meta?.message)
      }
    } catch (error) {
      alert(error)
    }
  }

  onPressAddPeople = async () => {
    if (!this.props.is_connected && this.props.token) {
      const header = {
        'Content-Type': configJSON.exampleApiContentType,
        "token": this.props.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addConnectionApiCallId = requestMessage.messageId;

      const bodyConnection = {
        "connection":
        {
          "receipient_id": this.props.accountId
        }
      }

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addConnectionEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyConnection)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  // Customizable Area End

}
