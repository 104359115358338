import React from "react";
// Customizable Area Start
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { share, save, searchIcon, addImage, close, CopyImage, ChatIcon, BackBtn, BannerDefault, calender, BackBtnDetail, WebSunnyIcon } from "./assets";
import {
  styled,
  TextField,
  InputAdornment,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  Avatar,
  Select,
  DialogContentText,
  DialogActions,
  Chip,
  TextareaAutosize,
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Typography,
  CardHeader,
  Button,
} from "@material-ui/core";
import {
  CountryInfo,
  DefaultCountry,
  DefaultCounty,
  DefaultTown,
  IAttendee,
  TownInfo,
} from "./AllEventsController.web";
import dayjs from "dayjs";
import utcTime from "dayjs/plugin/utc";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DatePicker from "react-datepicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { Field, Form, Formik } from "formik";
import CustomTextField from "../../../components/src/CustomTextField";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete } from "@mui/material";
import {
  CustomInput,
  CustomMenuItem,
  DialogStyledInputWebSiteWrapper,
  DialogStyledInputWrapper,
  ListProps,
  PostCodeContainer,
  StyledButton,
  StyledDialogCreateWrapper,
  StyledDropDown,
  StyledDropDownInPerson,
  StyledDropDownPickZone,
  StyledFormWrapper,
  StyledTimeWrapper,
  StyledWrapper,
  StyledWrapperAutocomplete,
  StyledWrapperTownAutocomplete,
  commonValidationFields,
} from "./AllEvents.web";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ClearIcon from '@material-ui/icons/Clear';
import * as Yup from "yup";
import {getStateArray} from "../../../components/src/GetStateArray.web";
import { getCityArray } from "../../../components/src/GetCityArray.web";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import HeaderNavbar from "../../../components/src/Navbar";
import { getSurname } from "../../../components/src/HelperUtils";
import { ShareLinkDialog, ShareMenuDialog } from "../../../components/src/ShareDialogs.web";
dayjs.extend(utcTime);
dayjs.extend(isSameOrAfter);

const StyledCard = styled(Card)({
  "& .MuiCard-root": {
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    }
  },
  "& .MuiCardHeader-root":{
    padding:"0px"
  },
  "& .dividerStyle":{
    paddingRight:"22px",
    paddingLeft:"40px"
  }
});

const StyledEventDetailPage = styled("div")({
  "& .searchInput":{
    padding:"18px 22px 18px 40px"
  }, 
  "& .attendeeList":{
    "@media (max-width:960px)": {
      padding:"9px 20px "
    }
  },
  "& .headerTitle":{
    padding:"15px 20px"
  },
  "& .heroImgContainer":{
    "@media (max-width:960px)": {
      maxHeight:"300px !important",
      minHeight:"300px !important"
    }
  },
  "& .headingEvent": {
    fontFamily: "Silika-Medium !important",
    fontWeight: "500 !important",
    fontSize: "26px !important",
    color:"#010101",
    "@media (max-width:754px)": {
      fontSize: "20px !important",
    }
  },
  "& .profileInfo": {
    padding: "8px",
    height:"37px"
  },
  "& .linkWeb":{
    color:"black",
    cursor:"pointer",
    opacity:"0.9",
    fontFamily:"Silika-Medium",
    fontSize:"12px"
  },
  "& .btnAction": {
    display: "flex",
    width: "22%",
    justifyContent: "flex-end",
    "@media (max-width:754px)": {
      width:"100% !important",
      justifyContent:"center",
      display:"flex",
      alignItems:"center",
      paddingBottom:"20px"
    }
  },
  "& .cardBtnAction":{
    gap:"15px",
    "@media (max-width:754px)": {
      marginTop: "10px !important",
    }
  },
  "& .btnActionBtn": {
    height: "20px",
    width: "20px",
    marginTop: "16px",
    minWidth:"24px !important"
  },
  "& .ownerName": {
    display: "flex",
    "@media (max-width:754px)": {
      fontSize: "15px !important",
      paddingBottom:"0px !important"
    }
  },
  "& .ownerText": {
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    color: "#9B9B9D",
    "@media (max-width:754px)": {
      fontSize: "15px !important",
    }
  },
  "& .cardContent": {
    padding: "0px",
  },
  "& .description": {
    fontFamily: "Silika-Light",
    fontSize:"16px",
    color:"#010101"
  },
  "& .descriptionOfDes":{
    marginTop:"42px",
    "@media (max-width:960px)": {
      marginTop:"20px"
    }
  },
  "& .profilePicAttendee": {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    textTransform:"uppercase !important" as "uppercase",
    fontSize:"15px",
    fontFamily:"silika-Medium"
  },
  "& .attendeeName": {
    paddingLeft: "40px",
    color: "#0D0C22",
  },
  "& .descriptionHeadingTitle": {
    fontFamily: "Silika-Medium",
    Weight: 500,
    fontSize: "16px",
    "@media (max-width:1000px)": {
      fontSize: "16px !important",
    },
  },
  "& .descriptionContainer":{
     padding:"35px 40px",
     "@media (max-width:1000px)": {
      padding:"15px 20px",
    },
  },
  "& .MuiCardActionArea-root": {
    backgroundColor: "transparent !important",
  },
  "& .menu": {
    width: "140px !important",
  },
  "& .deleteText": {
    fontFamily: "Silika-Light !important",
    fontWeight: "300 !important",
    fontSize: "16px !important",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    marginTop: "10px !important",
  },
  "& .firstBox":{
    marginTop:"-142px",
  },
  "& .boxUserInfo": {
    maxWidth:"1114.7px",
    "@media (max-width:1200px)": {
      width: "85% !important",
      marginBottom:"20px"
    },
    "@media (max-width:1000px)": {
      width: "90% !important"
    },
    "@media (max-width:600px)":{
      padding:"0px !important",
    }
  },
  "& .cardDetailUser":{
    width:"828px !important",
    "@media (max-width:1440px)": {
      minWidth:"828.7px",
    },
    "@media (max-width:1438px)": {
      minWidth:"unset",
      width:"70% !important"
    },
    "@media (max-width:1190px)": {
      marginLeft:"20px !important",
      padding:"0px",
      width:"70% !important"
    },
    "@media (max-width:812px)": {
      width:"auto !important"
    },
    "@media (max-width:754px)": {
      width:"100% !important",
      marginLeft:"0px !important",
    }
  },
  "& .cardUSContent":{
     padding:"0px !important",
    "@media (max-width:754px)": {
      padding:"0px !important"
    }
  },
  "& .descriptionDetailInfo":{
    fontFamily:"Silika-Medium",
    fontSize:"12px",
    LineHeight:"19.36px",
    "@media (max-width:500px)": {
      fontSize:"9.5px",
    }
  },
  "& .descriptionInfoTitle":{
    fontFamily:"Silika-Medium",
    fontSize:"12px",
    color:"gray !important",
    width:"60px"
  },
  "& .iconInfoDesc": {
    height: "18px",
    width: "18px",
    paddingRight: 10,
    opacity: "0.6"
  },
  "& .profilePhoto":{
    margin:"0px !important",
    backgroundColor: "#DFDFDF",
    color: "#9B9B9D",
    fontFamily: "Silika-Medium !important",
    fontSize: "88px",
    textTransform:"uppercase" as "uppercase",
    "@media (max-width:1190px)": {
    width:"190px !important",
    height:"190px !important",
    margin:"50px"
    },
    "@media (max-width:764px)": {
     width:"170px !important",
     height:"170px !important",
     margin:"auto !important",
     borderRadius:"50% !important",
      },
      "@media (max-width:580px)": {
        borderRadius:"50%",
        fontSize: "30px",
        width:"80px !important",
        height:"80px !important",
     }
  },
  "& .nameContainer":{
    width:"70% !important",
    "@media (max-width:754px)": {
      width:"95% !important",
      justifyContent:"center",
      display:"flex",
      flexDirection:"column",
      alignItems:"center"
    }
  },
  "& .cardUser":{
    "@media (max-width:754px)": {
     padding:"10px !important"
    }
  },
  "& .backBtnPage":{
    display:"none",
    "@media (max-width:510px)": {
      width: "40px !important",
      height: "40px !important"  
    },
    "@media (max-width:450px)": {
      width: "25px !important",
      height: "25px !important"  
    },

  },
  "& .descEvent": {
    "@media (max-width:1190px)": {
     marginTop:"20px !important",
     padding:"0px !important"
    },
  },
  "& .searchCard":{
    "@media (max-width:1190px)": {
      margin:"20px !important",
      padding:"0px !important",
     },
     "@media (max-width:600px)": {
      margin:"2px !important",
      padding:"0px !important",
     },
  },
  "& .infoContainerDiv":{
    flexWrap:"nowrap",
    height:"60px",
    marginBottom:"20px",
    "@media (max-width:1100px)": {
      height:"auto !important"
     },
    "@media (max-width:680px)": {
      margin:"10px !important",
     },
     "@media (max-width:760px)": {
       flexWrap:"wrap",
       justifyContent: "center",
       width: "100%"
     }
  },
  "& .heroImage":{
   "&:hover":{
    "& .backBtnPage":{
      display:"flex"
    }
   }
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none !important",
    border:"1px solid #DFDFDF"
  }
});

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    width: "140px !important",
    marginTop: "40px",
    border: "1px solid gray",
  },
  "& .menuItemText":{
    fontFamily:"Silika-Medium !important",
    fontSize:"16px  !important",
  },
});

const StyledInput = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "48px",
    width: "100%",
    borderColor: "transparent !important",
    borderRadius:"2px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DFDFDF !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DFDFDF !important",
  }
  },
  "& .MuiInputBase-input":{
    fontFamily: "Silika-Medium",
    fontSize:"16px",
    fontWeight: 500,
    width:"97% !important",
    border: "transparent!important",
    "&::placeholder": {
      fontWeight: 500,
      color: "#010101 !important",
      fontFamily: "Silika-Light",
      fontSize:"16px",
      opacity:"0.9"
    },
    "@media (max-width:754px)": {
      width: "80% !important",
    },
  
  },
});

export const DialogContentTextWrapper = styled(DialogContentText)({
  "& .text-field-contant": {
    position: "relative",
    "& .copyImg-contant": {
      position: "absolute",
      right: 0,
    }
  },
  "& .textfieldCopy": {
    border: "1px solid #DFDFDF",
    padding: "4px 6px",
    fontSize: "inherit !important",
    display:"flex",
    alignItems: "center",
  },
  "& .textfieldCopy-input": {
    paddingRight: "30px",
    fontFamily:"Silika-Medium !important",
    fontSize:"14px  !important",
    maxWidth: "580px",
    textWrap:"wrap",
    padding:"10px",
    overflowWrap: "break-word",
    overflow: "hidden",
    wordWrap: "break-word",
    "@media (max-width:509px)": {
      fontSize:"12px  !important",
    }
  },
  "& .copyImg": {
    cursor: "pointer"
  },
  "& .isErrorTextStyle": {
    padding: "12px 16px",
    background: "#f8f8f8",
    borderLeft: "4px solid red",
    marginTop: "12px",
    color: "red",

  },
  "& .isNoErrorTextStyle": {
    padding: "7px 16px",
    background: "#f8f8f8",
    borderLeft: "4px solid green",
    marginTop: "12px",
    color: "green",
    fontSize: '14px'
  }

}); 

const getValidationSchemaForEdit = (inPerson: string) => {
  const onlineSpecificFields = {
    linkToMeet: Yup.string().required("Link to meet is required"),
  };
  const inPersonSpecificFields = {
    postcode: Yup.string().required("Postcode* is required"),
    address: Yup.string().required("Address* is required"),
    country: Yup.object().shape({
      name: Yup.string().required('Country* is required'),
    }),
  };
  const bothSpecificFields = {
    ...onlineSpecificFields,
    ...inPersonSpecificFields,
  };
  switch (inPerson) {
    case "both":
      return Yup.object().shape({
        ...commonValidationFields,
        ...bothSpecificFields
      });
    case "online":
      return Yup.object().shape({
        ...onlineSpecificFields,
        ...commonValidationFields   
      });

      case "in person":
      return Yup.object().shape({
        ...commonValidationFields,
        ...inPersonSpecificFields
      });
    default:
      return Yup.object().shape({
        ...commonValidationFields
      });
  }
};

export const DialogContentTextWrapperChat = styled(DialogContentText)({
  display: "grid",
  gap: "24px",
  "& .textfieldCopy": {
    border: "1px solid #DFDFDF",
    padding: "4px 6px"
  },
  "& .textAreaStyle": {
    height: "97px !important",
    overflow: "auto",
    resize: "none",
    padding: '10px',
    fontFamily:"Silika-Light",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "white"
  },
  "& .MuiChip-outlined": {
    borderRadius: "0",
    borderColor: "#DFDFDF"
  },
  "& .chip-box-style": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  "& .imageLogoStyle": {
    border: "1px solid #DFDFDF",
    borderRadius: "100%"
  },
  "& .toText":{
    fontFamily:"Silika-Light"
  },
  "& .MuiInputBase-root":{
    fontFamily:"Silika-Light"
  },
  "& .MuiDialogContent-root":{
    width:"fit-content !important",
    "@media (max-width:520px)": {
      padding:"8px 12px !important"
    }
  },

  "& .MuiInputBase-input":{
    "@media (max-width:462px)": {
      width:"100% !important"
    },
  }

});

export const DialogActionWrapper = styled(DialogActions)({
  flex: "0 0 auto",
  display: "flex",
  padding: "8px",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "12px",
  "& .copyBtnStyle": {
    border: "0.5px solid #DFDFDF",
    padding: "16px 0px",
    boxShadow: "0px 4px 8px 5px rgba(0, 0, 0, 0.06)",
  },
  "& .SendBtnStyle": {
    marginLeft: "auto",
    padding: "24px",
    backgroundColor: "#0D0C22",
    color: "#fff",
    height: "50px",
    width: "120px",
    marginRight: "16px",
    fontFamily:"Silika-Medium",
    textTransform:"none"
  },
  "& .opacity-05": {
    opacity: 0.2
  }
});

export const StyledBox = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center",
  "& .imageLogoStyle": {
    border: "1px solid #DFDFDF",
    borderRadius: "100%"
  },
});

const StyledEditEventDatePick = styled("div")({
  "& .react-datepicker": {
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.189)",
    border: "none !important",
    fontFamily: "Silka",
    "@media (max-width:1258px)": {
      "& .react-datepicker__input-container":{
        width: "96% !important",
      },
      "& .react-datepicker": {
        width: "90%",
      },  
    },
  },
  "& .react-datepicker-wrapper":{
    width: "100% !important",
  },
})

const StyledDeleteDialogueModal = styled(Dialog)({
"& .MuiDialog-paperWidthSm":{
  maxWidth:"548px !important"
},
"& .deleteText": {
  fontFamily: "Silika-Light !important",
  fontWeight: "300 !important",
  fontSize: "16px !important",
  marginTop: "10px !important",
},
"& .btnAction":{
  marginLeft:"16px",
  marginRight:"16px",
  display:"flex",
  marginBottom:"37px",
  justifyContent:"space-between",
  marginTop:"30px"
},
"& .btnOfDelete":{
  "@media (max-width:609px)": {
   width:"180px !important"
  },
  "@media (max-width:515px)": {
    width:"150px !important",
    height:"40px !important",
    fontSize:"12px !important"
   },
   "@media (max-width:460px)": {
    width:"100px !important",
    height:"35px !important"
   }
}

})

// Customizable Area End

import EventViewController from "./EventViewController";
import { borderRadius } from "react-select/src/theme";


export default class EventView extends EventViewController {


  // Customizable Area Start

  RenderHeroImage = () => {
    return (
      <>
        <div style={{display:"flex",justifyContent:"center",width:"100%"}} className="heroImage" data-test-id="heroImg" onClick={this.handleStateCases}>
        <img
           className="backBtnPage" src={BackBtnDetail} onClick={this.navigateToListEventPage} style={styles.backButton}/>
          <img
            src={
              this.state.eventDetail.attributes?.banner_image
                ? this.state.eventDetail.attributes?.banner_image
                : BannerDefault
            }
            className="heroImgContainer"
            style={styles.heroImage}
            data-test-id="heroImgInner"
            onClick={this.handleStateCasesView}
          />
        </div>
      </>
    );
  };

  UserInfoCard = () => {
    return (
      <>
        <Box width="77.4%" marginBottom="80px" data-test-id = "boxuser" zIndex="1000" onClick={()=>{this.handleStateCov("BOX")}}className="boxUserInfo firstBox">
          <StyledCard style={styles.root} className="cardUser">
            <Avatar
              style={styles.cover}
              src={
                this.state.eventDetail?.attributes?.account.data.attributes
                  .photo
              }
              className="profilePhoto"
            >
              {this.state.eventDetail?.attributes?.account.data.attributes
                  .first_name[0]}{this.state.eventDetail?.attributes?.account.data.attributes
                    .last_name[0]}
            </Avatar>
            <div style={styles.details} className="cardDetailUser">
              <CardContent style={styles.content} className="cardUSContent">
                <Grid container direction="row" justifyContent="space-between" className="infoContainerDiv">
                  <Box  className="nameContainer">
                    <Typography className="headingEvent">
                    {this.handleResponsePass(this.state.eventDetail.attributes?.event_name)}
                    </Typography>
                    <Box
                      className="ownerName"
                    >
                      <Typography
                        variant="subtitle1"
                        className="ownerText"
                        color="textSecondary"
                      >
                        {this.handleResponsePass(this.state.eventDetail.attributes?.account.data
                          ?.attributes.first_name)}
                          {" "}
                          {!!this.state.eventDetail.attributes ? this.handleResponsePass(getSurname(this.state.eventDetail.attributes.account.data.attributes.last_name, this.state.eventDetail.attributes.account.data.attributes.account_id.toString())): ""}
                      </Typography>
                      {new Date(this.state.eventDetail.attributes?.end_date) >
                        new Date() && (
                          <Typography
                            variant="subtitle1"
                            className="ownerText"
                            color="textSecondary"
                            style={{ paddingRight: "10px", paddingLeft: "20px" }}
                          >
                            {this.handleDayDiffrence(
                              dayjs(
                                this.state.eventDetail.attributes?.created_at
                              ).format("YYYY-MM-DD")
                            )===0?"Today":this.handleDayDiffrence(
                              dayjs(
                                this.state.eventDetail.attributes?.created_at
                              ).format("YYYY-MM-DD")
                            ) + " days ago"}{" "}                          
                          </Typography>
                        )}
                    </Box>
                  </Box>
                  {sessionStorage.getItem("accountEmail") !==
                    this.state.eventDetail.attributes?.account.data?.attributes
                      .email && (
                      <Grid item className="btnAction cardBtnAction">
                        <Button className="btnActionBtn">
                          <img src={share} alt="Share" data-test-id="shareEventBtn" onClick={this.openPopUp}></img>
                        </Button>
                        {(this.state.favEventList.find((list) => list.attributes.favouriteable.data && list.attributes.favouriteable.data.id == this.state.eventDetail.id) ?
                          <Button className="btnActionBtn">
                            <TurnedInIcon />
                          </Button> :
                          <Button className="btnActionBtn" data-test-id="addFavEvent" onClick={this.handleFavouriteEvent}>
                            <img src={save} alt="Save"></img>
                          </Button>
                        )
                        }
                        {this.attendingBtn()}
                      </Grid>
                    )}
                  {sessionStorage.getItem("accountEmail") ===
                    this.state.eventDetail.attributes?.account.data?.attributes
                      .email && (
                      <Box
                        justifyContent="flex-end"
                        style={{ display: "flex",width:"5%" }}
                        data-test-id="editMenu"
                      >
                        <IconButton
                          aria-label="more"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={this.handleMenuOpen}
                          color="secondary"
                          className="descriptionInfoTitle"
                        >
                          <MoreVertIcon style={{ color: "gray" }} />
                        </IconButton>
                        <StyledMenu
                          id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleMenuClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <MenuItem
                            onClick={this.handleCreateEventPopUp}
                            data-test-id="editEventBtn"
                            className="menuItemText"
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={this.handleDeletePopUp}
                            data-test-id="deleteEventBtn"
                            className="menuItemText"
                          >
                            Delete
                          </MenuItem>
                        </StyledMenu>
                      </Box>
                    )}
                </Grid>

                <Divider />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className="profileInfo"
                >
                  <img
                    src={calender}
                    alt="Calender Icon"
                    className="iconInfoDesc"
                  />
                  <Box>
                    <Typography className="descriptionInfoTitle">When:</Typography>
                  </Box>
                  <Typography variant="subtitle1" className="descriptionDetailInfo">
                    {dayjs(
                      this.state.eventDetail.attributes?.start_date
                    ).format("ddd, D MMM")}
                    ,{" "}
                    {this.state.eventDetail.attributes?.start_time != null && (
                      <>
                        {dayjs(this.state.eventDetail.attributes?.start_time)
                          .utc()
                          .format("HH:mm")}
                      </>
                    )}{" "}
                    -{" "}
                    {dayjs(this.state.eventDetail.attributes?.end_date).format(
                      "ddd, D MMM"
                    )}
                    ,{" "}
                    {this.state.eventDetail.attributes?.end_time != null && (
                      <>
                        {dayjs(this.state.eventDetail.attributes?.end_time)
                          .utc()
                          .format("HH:mm")}
                      </>
                    )}{" "}
                    (GMT)
                  </Typography>
                </Grid>
                <Divider />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className="profileInfo"
                >
                  <FmdGoodOutlinedIcon
                    className="iconInfoDesc"
                  />
                  <Box>
                    <Typography className="descriptionInfoTitle">Where:</Typography>
                  </Box>
                  {this.state.eventDetail.attributes?.country ? (
                    <Typography variant="subtitle1" className="descriptionDetailInfo">
                      {this.handleResponseWithDash(this.state.eventDetail.attributes?.town)}
                      ,
                      {this.handleResponseWithDash(this.state.eventDetail.attributes?.county)}
                      ,
                      {this.handleResponseWithDash(this.state.eventDetail.attributes?.country)}
                      .
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" color="textSecondary" className="descriptionDetailInfo">
                      -
                    </Typography>
                  )}
                </Grid>
                <Divider />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className="profileInfo"
                >
                  <InsertLinkIcon
                     className="iconInfoDesc"
                  />
                  <Box>
                    <Typography className="descriptionInfoTitle" onClick={()=>{this.handleStatesCov("BOX")}} data-test-id="favrotitesss">Web:</Typography>
                  </Box>
                  <Typography variant="subtitle1" color="textSecondary">
                    <Typography className="linkWeb descriptionDetailInfo" data-test-id="linkToNavigate" onClick={()=>{this.handleNewLinkOpen(this.state.eventDetail.attributes.event_website)}}>
                      {this.handleResponseWithDash(this.state.eventDetail.attributes?.event_website)}
                    </Typography>
                  </Typography>
                </Grid>
                <Divider />
              </CardContent>
            </div>
          </StyledCard>
        </Box>
      </>
    );
  };

  DescriptionCard = () => {
    return (
      <>
        <Box width="77.4%" marginBottom="80px" className="boxUserInfo descEvent">
          <StyledCard>
            <Box>
              <CardContent className="cardContent">
                <CardHeader
                  title={"Description"}
                  titleTypographyProps={{
                    style: {
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontFamily:"Silika-Medium",
                      backgroundColor:"#0D0C22",
                      padding:"18px 40px"
                    },
                  }}
                  style={styles.headerStyle}
                ></CardHeader>
                <Box className="descriptionContainer">
                <Typography className="descriptionHeadingTitle">
                  {this.state.eventDetail.attributes?.event_name
                    ? this.state.eventDetail.attributes?.event_name
                    : ""}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className="description descriptionOfDes"
                >
                  {this.state.eventDetail.attributes?.description
                    ? this.state.eventDetail.attributes?.description
                    : ""}
                </Typography>
                </Box>
              </CardContent>
            </Box>
          </StyledCard>
        </Box>
      </>
    );
  };

  searchCard = () => {
    
    return (
      <>
        <Box width="77.4%" marginBottom="10vh !important" className="boxUserInfo searchCard">
          <StyledCard>
            <Box>
              <Box className="cardContent">
                <CardHeader
                  title={`Attendees (${this.state.eventDetail.attributes?.attendees.data.length})`}
                  titleTypographyProps={{
                    style: {
                      color: "#FFF",
                      fontSize: "16px",
                      paddingLeft: "25px",
                      fontFamily:"Silika-Medium",
                      backgroundColor:"#0D0C22",
                      padding:"18px 40px",
                      
                    },
                  }}
                  style={styles.headerStyle}
                ></CardHeader>
                <Box className="searchInput">
                  <StyledInput>
                    <TextField
                      data-test-id="searchText"
                      id="adSearchText"
                      variant="outlined"
                      type="text"
                      placeholder="Name"
                      value={this.state.searchText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleAttendeeSearch(event.currentTarget.value)
                      }
                      style={styles.input}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledInput>
                </Box>
                <Box maxHeight="390px" overflow="auto">
                  {this.state.eventDetail.attributes?.attendees.data
                    .filter((attendee) => {
                      const searchText = this.state.searchText?.toLowerCase();
                      if (searchText) {
                        let fullName =
                          `${attendee.attributes.first_name} ${attendee.attributes.last_name}`.toLowerCase();
                          return fullName.includes(searchText);
                      }
                      return true;
                    })
                    .map((data: IAttendee) => {
                      let fullName =
                      `${data.attributes.first_name} ${getSurname(data.attributes.last_name, data.attributes.account_id.toString())} `.toLowerCase();
                      return (
                        <Box key={data.attributes.email} className="dividerStyle">
                          <Divider />
                          <Box style={styles.attendeeList} className="attendeeList">
                            <Avatar
                              src={data.attributes.photo}
                              className="profilePicAttendee"
                              alt="profile"
                            >
                            {data.attributes.first_name[0]}{getSurname(data.attributes.last_name, data.attributes.account_id.toString())}</Avatar>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              className="ownerText attendeeName"
                            >
                              {fullName}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </StyledCard>
        </Box>
      </>
    );
  };


  attendingBtn =() => {
    const attendee = this.state.eventDetail.attributes?.attendees.data.find(
      (attendee) =>
        attendee.attributes?.email ===
        sessionStorage.getItem("accountEmail")
    );
    const isAttending = attendee !== undefined;

    const isEventInFuture = dayjs(this.state.eventDetail.attributes?.end_date)
    .isSameOrAfter(dayjs().startOf("day"));

    let buttonComponent = null;

    if (isAttending) {
      buttonComponent = (
        <Button style={styles.attendingButton}>
          Attending
        </Button>
      );
    } else if (isEventInFuture) {
      buttonComponent = (
        <Button
          style={styles.button}
          data-test-id="attendEventBtn"
          onClick={this.handleAttendEvent}
        >
          Attend
        </Button>
      );
    }

    return (
      <div>
        {buttonComponent}
      </div>
    );

  }

  renderCopyStatusFun() {
    return this.renderCopyStatus() && <Typography className="isNoErrorTextStyle">{this.renderCopyStatus()}</Typography>
  }

  renderCopyStatus() {
    if (this.state.isCopied !== null) {
      return this.state.isCopied ? "Link copied!" : "Copy error to clipboard";
    }
    if (this.state.msgSend) {
      return "Message sent!"
    }
  }

  getButtonClass() {
    return this.state.shareData?.length === 0 ? 'opacity-05' : '';
  }

  PageContent = () => {
    return (
      <>
        <HeaderNavbar navigation={this.props.navigation}/>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{position: 'relative',marginTop:"18px"}}
        >

          {this.RenderHeroImage()}
          {this.UserInfoCard()}
          {this.DescriptionCard()}
          {this.searchCard()}
          {this.DialogContent()}
          {this.DeleteContent()}
          <ShareLinkDialog
            isOpen={this.state.openModel}
            onClickChatButton={this.handleChat}
            onClose={this.handleClose}
            isChatSend={this.state.msgSend}
            shareLink={`${this.state.linkShare}`}
          />
          <ShareMenuDialog
            isOpen={this.state.isSendLinkToChatModalOpen}
            onClose={this.handleCloseChatModel}
            onClickBackButton={this.handleNavigateBack}
            profiles={this.state.getAllData?.map((option:{attributes:{account_id:number, first_name: string, last_name: string, photo: string}}) => ({
              account_id:option?.attributes?.account_id,
              firstName: option?.attributes?.first_name,
              lastName: getSurname(option?.attributes?.last_name, option?.attributes?.account_id?.toString()),
              photo: option?.attributes?.photo,
            }))}
            shareData={this.state.shareData}
            chatMessage={this.state.sendChatMessage}
            onChangeChatMessage={this.handlesendChatMessage}
            onShareDataChange={this.handleshareDataChatChange}
            onClickSendChat={this.handleSend}
          />
        </Grid>
      </>
    );
  };

  conditionalDropDown = (setFieldValue:any,values:any,getFieldProps:any) => (
    <>
      {this.handleCheckInpersonWithOnline() && (
        <Grid item style={styles.formContainer} className="formContainerEvent">
          <FormControl fullWidth>
            <FormLabel style={styles.formLableText}>
              <Box style={{ display: "flex" }}>
                Link to the meeting{" "}
                <Box style={{ color: "red" }}>*</Box>
              </Box>
            </FormLabel>
            <DialogStyledInputWrapper>
              <TextField
                {...getFieldProps("linkToMeet")}
                name="linkToMeet"
                type="textarea"
                variant="outlined"
                style={styles.input}
              />
            </DialogStyledInputWrapper>
          </FormControl>
        </Grid>
      )}
      {this.handleCheckInperson() && (
        <>
          <Grid container style={{ padding: "18px 37px 0px 0px" }} className="locationDropDown">
            <Grid
              item
              xl={4} lg={4} md={6} sm={6} xs={12}
              style={styles.fomrContainerDrop}
              className="formContainerEvent "
            >
              <FormControl fullWidth>
                <FormLabel style={styles.formLableText}>
                  <Box style={{ display: "flex" }}>
                    Country{" "}
                    <Box style={{ color: "red" }}>*</Box>
                  </Box>
                </FormLabel>
                <StyledWrapperAutocomplete>
                  <Autocomplete
                    options={this.getCountry()?.map(
                      (options) => options?.value
                    )}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                      />
                    )}
                    ListboxProps={ListProps}
                    popupIcon={<KeyboardArrowDownIcon style={{ color: "black" }} />}
                    data-testId="CountryData"
                    onChange={(_event, newValue) => {
                      setFieldValue(
                        "country",
                        this.handleResponsePass(newValue)
                      );
                      setFieldValue("town", DefaultTown);
                      setFieldValue(
                        "county",
                        DefaultCounty
                      );
                    }}
                    value={values.country ? values.country : DefaultCountry}
                    style={styles.autoComplateStyle}
                    id="CountryData"
                    getOptionLabel={(option: unknown) =>
                      (option as CountryInfo)?.name
                    }
                  />
                </StyledWrapperAutocomplete>
              </FormControl>
            </Grid>
            <Grid
              item
              xl={4} lg={4} md={6} sm={6} xs={12}
              style={styles.fomrContainerDrop}
              className="formContainerEvent"
            >
              <FormControl fullWidth>
                <FormLabel style={this.getStateArray(
                  values?.country?.isoCode || ""
                )?.map((options) => options.value).length == 0 ? styles.formDropTextDisabled : styles.formLableText}>
                  <Box style={{ display: "flex" }}>
                    Region{" "}
                    {this.getStateArray(
                      values?.country?.isoCode
                    )?.map((options) => options.value).length == 0 ? "" : <Box style={{ color: "red" }}>*</Box>}
                  </Box>
                </FormLabel>
                <StyledWrapperAutocomplete>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                      />
                    )}
                    disableClearable
                    style={styles.autoComplateStyle}
                    value={values.county ? values.county : DefaultCounty}
                    data-testId="RegionData"
                    getOptionLabel={(option: unknown) =>
                      (option as CountryInfo).name
                    }
                    ListboxProps={ListProps}
                    popupIcon={<KeyboardArrowDownIcon style={{ color: "black" }} />}
                    placeholder=""
                    disabled={this.getStateArray(
                      values?.country?.isoCode
                    )?.map((options) => options?.value).length == 0}
                    id="combo-box-demo"
                    onChange={(_event, newValue) => {
                      setFieldValue("county", newValue);
                      setFieldValue("town", DefaultTown);
                    }}
                    options={this.getStateArray(
                      values?.country?.isoCode
                    )?.map((options) => options?.value)}
                  />
                </StyledWrapperAutocomplete>
              </FormControl>
            </Grid>
            <Grid
              style={styles.fomrContainerDrop}
              xl={4} lg={4} md={6} sm={6} xs={12}
              item
              className="formContainerEvent"
            >
              <FormControl fullWidth>
                <FormLabel style={this.getCityArray(
                  values.country?.isoCode,
                  values.county?.isoCode
                )?.map((options) => options?.value).length == 0 ? styles.formDropTextDisabled : styles.formLableText}>
                  <Box style={{ display: "flex" }}>
                    Town{" "}
                    {this.getCityArray(
                      values.country?.isoCode,
                      values.county?.isoCode
                    )?.map((options) => options?.value).length == 0 ? "" : <Box style={{ color: "red" }}>*</Box>}
                  </Box>
                </FormLabel>
                <StyledWrapperTownAutocomplete>
                  <Autocomplete
                    disableClearable
                    value={values.town ? values.town : DefaultTown}
                    options={this.getCityArray(
                      values.country?.isoCode,
                      values.county?.isoCode
                    )?.map((options) => options?.value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                      />
                    )}
                    onChange={(_event, newValue) => {
                      setFieldValue(
                        "town",
                        this.handleResponsePass(newValue)
                      );
                    }}
                    ListboxProps={ListProps}
                    data-testId="TownData"
                    popupIcon={<KeyboardArrowDownIcon style={{ color: "black" }} />}
                    style={styles.autoComplateStyle}
                    id="combo-box-demo"
                    getOptionLabel={(option: unknown) =>
                      (option as TownInfo).name
                    }
                    disabled={this.getCityArray(
                      values?.country?.isoCode,
                      values?.county?.isoCode
                    )?.map((options) => options?.value).length == 0}
                  />
                </StyledWrapperTownAutocomplete>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xl={8}
              lg={8}
              md={6}
              style={styles.formContainer}
              className="formContainerEvent"
            >
              <FormControl fullWidth>
                <FormLabel style={styles.formLableText}>
                  <Box style={{ display: "flex" }}>
                    Address{" "}
                    <Box style={{ color: "red" }}>*</Box>
                  </Box>
                </FormLabel>
                <TextField
                  {...getFieldProps("address")}
                  value={values.address}
                  style={styles.input}
                  type="textarea"
                  name="address"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <PostCodeContainer
              item
              xl={4}
              lg={4}
              md={6}
              style={styles.formContainerPostCode}
              className="formContainerEvent"
            >
              <FormControl fullWidth>
                <FormLabel style={styles.formLableText}>
                  <Box style={{ display: "flex" }}>
                    Post code
                    <Box style={{ color: "red" }}>*</Box>
                  </Box>
                </FormLabel>
                <TextField
                  {...getFieldProps("postcode")}
                  variant="outlined"
                  style={styles.input}
                  name="postcode"
                  type="textarea"
                />
              </FormControl>
            </PostCodeContainer>
          </Grid>
        </>
      )}
    </>
  )

  DialogContent = () => {
    return (
      <>
        {this.state.isOpenedEditEventPopUp && (
          <StyledDialogCreateWrapper
            open={this.state.isOpenedEditEventPopUp}
            fullWidth
            maxWidth="lg"
            onClose={this.hideCreateEventModal}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid style={styles.experienceInnerDiv1} className="headCreateEvent">
              <DialogTitle style={styles.dialougeHeading}>
                {"Edit event"}
              </DialogTitle>
              <img
                src={close}
                id="close-img"
                onClick={this.hideCreateEventModal}
                data-test-id="closeIcon"
                style={{
                  width: "14px",
                  height: "14px",
                  marginRight: "37px",
                  cursor: "pointer",
                }}
              ></img>
            </Grid>
            <DialogContent>
              <Formik
                initialValues={this.state.editEventInitialValues}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { resetForm }) => {
                  const modifiedValues = {
                    ...values,
                    startTime: dayjs(values.startTime as string).format(
                      "HH:mm:ss"
                    ),
                    endDate: values.endDate,
                    endTime: dayjs(values.endTime as string).format(
                      "HH:mm:ss"
                    ),
                    inPerson: this.state.inPersonOnlineBoth,
                    bannerImage: this.state.uploadedFile,
                    startDate: values.startDate,
                  };
                  if (this.state.validFile) {
                    this.handleEditEvent(modifiedValues);
                    resetForm();
                  }
                }}
                validationSchema={() =>
                  getValidationSchemaForEdit(this.state.inPersonOnlineBoth)
                }
              >
                {({ handleSubmit, setFieldTouched,setFieldValue, values, errors, touched,getFieldProps }) => {
                  return (
                    <StyledFormWrapper onSubmit={handleSubmit}>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "auto",
                          width: "100%",
                        }}
                      >
                        <Grid
                          item
                          xl={12}
                          style={{
                            flexDirection: "column",
                            margin: "auto",
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <Grid item style={styles.formContainer}  className="formContainerEvent">
                            <FormControl fullWidth style={{ width: "100%" }}>
                              <FormLabel style={styles.formLableText}>
                                <Box style={{ display: "flex" }}>
                                  Event name{" "}
                                  <Box style={{ color: "red" }}>*</Box>
                                </Box>
                                <Box
                                  style={{
                                    alignContent: "flex-end",
                                    fontSize: "12px",
                                    fontFamily: "silika-Light",
                                  }}
                                >
                                  {values.eventName.length}/100
                                </Box>
                              </FormLabel>
                              <DialogStyledInputWrapper>
                              <TextField
                                {...getFieldProps("eventName")}
                                id="profileTitel"
                                name="eventName"
                                type="string"
                                fullWidth
                                variant="outlined"
                                title="Event name"
                                inputProps={{
                                  maxLength: 100
                                }}
                              />
                              </DialogStyledInputWrapper>
                            </FormControl>
                          </Grid>
                          <Grid item style={styles.formContainer} className="formContainerEvent">
                            <Grid
                              item
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormLabel style={styles.formLableText}>
                                Banner image
                              </FormLabel>
                              <Grid item sm={12} style={styles.imageGridStyle}>
                                <Grid item style={styles.imageDisplay}>
                                  {(this.state.uploadedFile ||
                                    this.state.uploadedFileUrl !== "" ) && (
                                      <Box style={styles.borderOfImage}>
                                        <>
                                          <img
                                            width="108px"
                                            height="108px"
                                            src={this.state.uploadedFileUrl}
                                            style={{ padding: "13px" }}
                                          ></img>
                                          <CloseIcon
                                            data-test-id="removeFile"
                                            onClick={this.handleRemoveFile}
                                            id="remove-image"
                                            style={styles.crossImage}
                                            width="12px"
                                            height="12px"
                                          />
                                        </>
                                      </Box>
                                    )}
                                  {this.state.uploadedFileUrl==="" && (
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="label"
                                      style={styles.borderOfImage}
                                    >
                                      <input
                                        type="file"
                                        name="uploadMultipleImages"
                                        accept="video/*,image/*"
                                        className="filetype"
                                        id="group_image1"
                                        data-test-id="uploadImageInput"
                                        hidden
                                        onChange={(event) =>
                                          this.handleImageChange(event)
                                        }
                                      />
                                      <Avatar
                                        style={{
                                          width: "90px",
                                          height: "90px",
                                          borderRadius: 0,
                                        }}
                                        className="profile-avatar"
                                        src={addImage}
                                      ></Avatar>
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                              {!this.state.validFile && (
                                <Box
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "10px",
                                    color: "#f44336",
                                  }}
                                >
                                  No upload can be greater than 5MB
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item style={styles.formContainer} className="formContainerEvent">
                            <FormControl fullWidth>
                              <FormLabel style={styles.formLableText}>
                                Add a description
                                <Box
                                  style={{
                                    fontFamily: "silika-Light",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  {values.description.length}/500
                                </Box>
                              </FormLabel>
                              <DialogStyledInputWrapper>
                              <Field
                                name="description"
                                placeHolder=""
                                multiline={true}
                                rows={20}
                                value={values.description}
                                component={CustomTextField}
                                type="string"
                                variant="outlined"
                                maxLength={500}
                              />
                              </DialogStyledInputWrapper>
                            </FormControl>
                          </Grid>
                          <Grid item style={styles.formContainer} className="formContainerEvent">
                            <FormControl fullWidth>
                              <FormLabel style={styles.formLableText}>
                                Event website
                              </FormLabel>
                              <DialogStyledInputWebSiteWrapper>
                              <TextField
                                variant="outlined"
                                data-test-id="eventWeb"
                                name="eventWeb"
                                type="textarea"
                                style={styles.input}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <InsertLinkIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                value={values.eventWeb}
                                onChange={(event) =>
                                  setFieldValue("eventWeb", event.target.value)
                                }
                              />
                              </DialogStyledInputWebSiteWrapper>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{ padding: "18px 37px 18px 40px"}}
                            className="formContainerEvent dateTimeContainer"
                          >
                            <FormLabel style={styles.formLableText}>
                              Date and time
                            </FormLabel>
                            <Grid container spacing={4} className="dateTimeContainerInner">
                              <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="dateFormContainer">
                                <StyledWrapper>
                                <StyledEditEventDatePick>
                                  <DatePicker
                                    data-test-id="startDatePicker"
                                    selected={this.state.startDate}
                                    minDate={new Date()}
                                    dateFormat={"DD/MM/YYYY"}
                                    value={values.startDate}         
                                    onChange={(value: Date) => {
                                      setFieldValue(
                                        "startDate",
                                        dayjs(value).format("DD/MM/YYYY")
                                      );
                                      this.setStartDate(value);
                                      setFieldValue("endDate", "");
                                      this.setEndDate(new Date());
                                    }}
                                    customInput={
                                      <CustomInput
                                        placeHolder={"Start Date *"}
                                        isStartIcon
                                      />
                                    }
                                  />
                                  {
                                    <Box
                                      style={{
                                        color: "#f44336",
                                        marginTop: "10px",
                                        fontSize: "12px",                
                                      }}
                                    >
                                      {errors.startDate}
                                    </Box>
                                  }
                                  </StyledEditEventDatePick>
                                </StyledWrapper>
                              </Grid>
                              <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="dateFormContainer">
                                <StyledTimeWrapper>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Field
                                        fullWidth
                                        component={DesktopTimePicker}
                                        name="startTime"
                                        label="Start Time *"
                                        data-test-id="startTimePicker"
                                        ampm={false}
                                        openTo="hours"
                                        value={this.handleResponsePass(values.startTime)}
                                        onBlur={() => {
                                          setFieldTouched("startTime", true);
                                        }}
                                        onChange={(value:any) => {
                                          setFieldTouched("startTime", true);
                                          setFieldValue("startTime", value?value:"00:00");
                                        }}
                                        format="HH:mm"
                                      />
                                  </LocalizationProvider>
                                  {
                                    <Box
                                      style={{
                                        color: "#f44336",
                                        marginTop: "10px",
                                        fontSize: "12px",                                  
                                      }}
                                    >
                                      {errors.startTime}
                                    </Box>
                                  }
                                </StyledTimeWrapper>
                              </Grid>
                              <Grid xl={4} lg={4} md={6} sm={6} xs={12} item className="dateFormContainer">
                              <FormControl fullWidth>
                              <StyledDropDown>
                              <StyledDropDownPickZone>
                                <Field
                                 fullWidth
                                 name="timeZone"
                                 startAdornment={
                                   <InputAdornment position="start">
                                     <img src={WebSunnyIcon}  alt="webSunnyIcon"/>
                                   </InputAdornment>
                                 }
                                 component={Select}
                                 variant="outlined"
                                 IconComponent={KeyboardArrowDownIcon}    
                                  data-test-id="timeZone"
                                  type="button"
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',     
                                      horizontal: 'left',                     
                                    },
                                    transformOrigin: {     
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null
                                  }}
                                 value={values.timeZone}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                   setFieldValue("timeZone",event.target.value);
                                   setFieldTouched("timeZone",true);
                                  }}
                                >
                                  <CustomMenuItem value="" disabled>
                                    Pick Time Zone *
                                  </CustomMenuItem>
                                  <CustomMenuItem value={"GMT"}>
                                    GMT
                                  </CustomMenuItem>
                                  <CustomMenuItem value={"UTC"}>
                                    UTC
                                  </CustomMenuItem>
                                  <CustomMenuItem value={"EST"}>
                                    EST
                                  </CustomMenuItem>
                                  <CustomMenuItem value={"EEST"}>
                                    EEST
                                  </CustomMenuItem>
                                </Field>
                                </StyledDropDownPickZone>
                              </StyledDropDown>   
                            </FormControl>
                            </Grid>
                            </Grid>
                            <Grid item xs={12} style={{marginTop:"10px"}}>
                              <Grid container spacing={4} style={{marginTop:"10px"}}  className="dateTimeContainerInner">
                                <Grid item xl={4} lg={4} md={6} sm={6}xs={12} className="dateFormContainer">
                                  <StyledWrapper>
                                  <StyledEditEventDatePick>
                                    <DatePicker
                                      data-test-id="endDatePicker"
                                      dateFormat={"DD/MM/YYYY"}
                                      disabled={
                                        values.startDate == "" ? true : false
                                      }
                                      onChange={(value: Date) => {
                                        setFieldValue(
                                          "endDate",
                                          dayjs(value).format("DD/MM/YYYY")
                                        );
                                        this.setEndDate(value);
                                      }}
                                      value={values.endDate}
                                      selected={
                                        this.state.endDate
                                          ? this.state.endDate
                                          : this.state.startDate
                                      }
                                      minDate={this.state.startDate}                                    
                                      customInput={
                                        <CustomInput
                                        isStartIcon
                                          placeHolder={"End Date *"}
                                         
                                        />
                                      }
                                    />
                                    {
                                      <Box
                                        style={{
                                          marginTop: "10px",
                                          fontSize: "12px",
                                          color: "#f44336",
                                        }}
                                      >
                                        {errors.endDate}
                                      </Box>
                                    }
                                   </StyledEditEventDatePick>
                                  </StyledWrapper>
                                </Grid>
                                <Grid item xl={4} lg={4} md={6} sm={6}xs={12} className="dateFormContainer">
                                  <StyledTimeWrapper>
                                  <StyledEditEventDatePick>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Field
                                        component={DesktopTimePicker}
                                        value={this.handleResponsePass(values.endTime)}
                                        name="endTime"
                                        label="End Time *"
                                        ampm={false}
                                        data-test-id="endTimePicker"
                                        openTo="hours"
                                        onBlur={() => {
                                          setFieldTouched("endTime", true);
                                        }}
                                        onChange={(value:any) => {
                                          setFieldTouched("endTime", true);
                                          setFieldValue("endTime", value?value:"00:00");
                                        }}
                                        format="HH:mm"
                                      />
                                      {
                                        <Box
                                          style={{         
                                            marginTop: "10px",
                                            fontSize: "12px",
                                            color: "#f44336",
                                          }}
                                        >
                                          {errors.endTime}
                                        </Box>
                                      }
                                    </LocalizationProvider>
                                    </StyledEditEventDatePick>
                                  </StyledTimeWrapper>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item  xl={4} lg={4} md={6} sm={6} xs={12} style={styles.formContainerDrop} className="formContainerEvent">
                            <FormControl fullWidth>
                              <FormLabel style={styles.formLableText}>
                                <Box style={{ display: "flex" }}>
                                  is in person or online?{" "}
                                  <Box style={{ color: "red" }}>*</Box>
                                </Box>
                              </FormLabel>
                              <StyledDropDownInPerson>
                              <StyledDropDown>
                                <Field
                                  fullWidth
                                  data-test-id="inPerson"
                                  variant="outlined"
                                  MenuProps={{
                                    anchorOrigin: {
                                      horizontal: 'left',
                                      vertical: 'bottom',                     
                                    },
                                    transformOrigin: {   
                                      horizontal: 'left',
                                      vertical: 'top',
                                    },
                                    getContentAnchorEl: null
                                  }}
                                  IconComponent={KeyboardArrowDownIcon}
                                  type="button"
                                  name="inPerson"
                                  component={Select}
                                  value={this.state.inPersonOnlineBoth}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    this.handleInpersonOnline(
                                      event.target.value
                                    );
                                    setFieldValue(
                                      "inPerson",
                                      event.target.value
                                    );
                                    setFieldValue(
                                      "country",
                                      this.state.editEventInitialValues.country? this.state.editEventInitialValues.country:""
                                    );
                                    setFieldValue(
                                      "county",
                                      this.state.editEventInitialValues.county? this.state.editEventInitialValues.county:""
                                    );
                                    setFieldValue(
                                      "town",
                                      this.state.editEventInitialValues.town? this.state.editEventInitialValues.town:""
                                    );
                                    setFieldValue(
                                      "linkToMeet",
                                      this.state.editEventInitialValues
                                        .linkToMeet
                                    );
                                    setFieldValue(
                                      "postcode",
                                      this.state.editEventInitialValues.postcode
                                    );
                                    setFieldValue(
                                      "address",
                                      this.state.editEventInitialValues.address
                                    );
                                  }}
                                >
                                  <CustomMenuItem value={"in person"}>
                                    In person
                                  </CustomMenuItem>
                                  <CustomMenuItem value={"online"}>
                                    Online
                                  </CustomMenuItem>
                                  <CustomMenuItem value={"both"}>
                                    Both
                                  </CustomMenuItem>
                                </Field>
                              </StyledDropDown>
                              </StyledDropDownInPerson>
                            </FormControl>
                          </Grid>
                            {this.conditionalDropDown(setFieldValue,values,getFieldProps)}
                          <Grid style={styles.btnAction} className="btnAction">
                            <Grid
                              item
                              style={{
                                display: "flex",
                                width: "98%",
                                justifyContent: "flex-end",
                              }}
                            >
                           {this.submitButton(values,errors)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </StyledFormWrapper>
                  );
                }}
              </Formik>
            </DialogContent>
          </StyledDialogCreateWrapper>
        )}
      </>
    );
  };

  submitButton = (values:any,errors:any) => {
    return (<StyledButton>
      <Button
        type="submit"
        disabled={
          !this.state.validFile || Object.keys(errors).length > 0 ||
          (getStateArray(values?.country?.isoCode).length > 0 && values?.county?.name == DefaultCounty.name) ||
          (getCityArray(values?.country?.isoCode, values?.county?.isoCode).length > 0 && values?.town?.name == DefaultTown.name)}
        data-test-id="submitCreateBtn"
        variant="contained"
        style={styles.createBtnEvent}
      >
        Update
      </Button>
    </StyledButton>)
  }

  DeleteContent = () => {
    return (
      <>
        {this.state.isOpenedDeleteEventPopUp && (
          <StyledDeleteDialogueModal
            open={this.state.isOpenedDeleteEventPopUp}
            fullWidth
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            onClose={this.handleHideDeletePopUp}
          >
            <DialogContent>
              <Box
                style={{
                  display: "flex",
                  paddingTop: "24px",
                  justifyContent: "center",
                }}
              >
                <Box className="deleteText">
                  Are you sure you want <br />
                  <p style={{ marginLeft: "10px" }}>to delete this event?</p>
                </Box>
              </Box>

              <Grid>
                <Grid item className="btnAction">
                  <Button
                    data-test-id="cancelDeleteEvent"
                    type="button"
                    style={{
                      width: "220px",
                      fontFamily:"Silika-Medium",
                      textTransform: "capitalize" as "capitalize",
                      border:"1px solid #010101",
                      fontSize:"16px"
                    }}
                    onClick={this.handleHideDeletePopUp}
                    className="btnOfDelete"
                  >
                    Cancel
                  </Button>

                  <StyledButton>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => {
                        this.handleDeleteEventApi();
                      }}
                      data-test-id="deleteEvent"
                      className="btnOfDelete"
                      style={styles.deleteBtnEvent}
                    >
                      Delete
                    </Button>
                  </StyledButton>
                </Grid>
              </Grid>
            </DialogContent>
          </StyledDeleteDialogueModal>
        )}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <StyledEventDetailPage>{this.PageContent()}</StyledEventDetailPage>
      </>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  heroImage: {
    height: "auto",
    width: "auto",
    // backgroundImage: `url(${hero})`,
    maxHeight: "489px",   
    minWidth:"100%",
    minHeight:"489px",
    objectFit:"cover" as "cover"
  },

  button: {
    backgroundColor: "#000",
    color: "#FFF",
    height: "48px",
    padding: "16px",
    width: "104px",
    textTransform: "capitalize" as "capitalize",
    fontFamily:"Silika-Medium"
  },

  attendingButton: {
    backgroundColor: "#00FFA7",
    color: "#000",
    height: "48px",
    padding: "16px",
    width: "104px",
    textTransform: "capitalize" as "capitalize",
    fontFamily:"Silika-Medium"
  },

  headerText: {
    color: "white",
  },

  headerStyle: {
    backgroundColor: "#000",
  },

  cover: {
    width: "190px",
    height: "190px",
    margin: "auto",
    borderRadius:"4px"
  },

  root: {
    display: "flex",
    padding: "24px",
    flexWrap:"wrap" as "wrap",
  },

  details: {
    display: "flex",
    flexDirectionProperty: "column",
    marginLeft: "45px",
    width:"70%"
  },

  content: {
    flex: "1 0 auto",
  },

  input: {
    width: "100%",
    heigth: "40px",
    alignItem: "center",
  },

  attendeeList: {
    padding: "18px 40px 18px 40px",
    display: "flex",
    alignItems: "center",
  },
  dialogContainer: {
    borderRadius: "4px 0px 0px 0px",
    opacity: "0px",
    top: "1px",
    border: "1px solid #DFDFDF",
    margin: "auto",
  },
  dialogContent: {
    width: "100%",
    height: "100%",
  },
  formContainer: {
    padding: "8px 37px 8px 40px",
    display: "flex",
    width: "100%",
  },
  headerDialog: {
    display: "flex",
    width: "100%",
    background: "#0D0C22",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
  },
  dateInputContainer: {
    width: "40%",
    display: "flex",
  },
  formLableText: {
    color: "#010101",
    display: "flex",
    fontWeight: 500,
    fontSize: "14px",
    justifyContent: "space-between",
    fontFamily: "Silika-Medium",
    marginBottom: "10px",
  },
  calenderInputDiv: {
    display: "inline-flex",
    width: "100%",
    marginBottom: "2%",
  },
  calInDiv: {
    width: "70%",
    boxSizing: "content-box" as "content-box",
    display: "flex",
    justifyContent: "center",
  },
  calInput: {
    padding: "4px 8px 5px 8px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "2px",
  },
  datepicker: {
    height: "40px",
  },
  datepickercontainer: {
    position: "relative" as "relative",
    display: "inline-block",
  },
  dialougeHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "15px",
  },
  experienceInnerDiv1: {
    display: "flex",
    justifyContent: "space-between",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "10px",
    color: "white",
    alignItems: "center",
  },
  imageGridStyle: {
    display: "flex",
    width: "100%",
  },
  imageGridStyleText: {
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontWeight: 500,
    color: "#010101",
  },
  imageDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row" as "row",
  },
  borderOfImage: {
    border: "1px dashed #9B9B9D",
    borderRadius: "4px",
    marginRight: "5px",
    position: "relative" as "relative",
    height:"132px",
    width:"132px"
  },
  crossImage: {
    position: "absolute" as "absolute",
    right: 1,
    cursor: "pointer",
    color: "black",
    height: "15px",
    width: "15px",
  },
  createBtnEvent: {
    background: "#010101",
    height: "48px",
    width: "104px",
    color: "white",
    textTransform: "capitalize" as "capitalize",
    alignItem: "flex-end",
    marginTop:"30px"
  },

  btnAction: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "10px",
    color: "white",
    alignItems: "center",
    paddingRight: "35px",
  },

  autoComplateStyle: {
    marginTop: "8px",
    fontSize: "15px",
  },
  autoAdSearchCompleteStyle: {
    fontSize: "15px",
  },

  deleteBtnEvent: {
    background: "#010101",
    height: "48px",
    width: "220px",
    color: "white",
    textTransform: "capitalize" as "capitalize",
    fontFamily:"Silika-Medium",
    fontSize:"16px",
    cursor:"pointer"
  },

  btnActionDelete: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "24px 20px 24px 20px",
    marginBottom: "20px",
    marginTop: "20px",
  },
  modelHeadingstyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modelHeadingstyleChatModel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  modalStyle: {
    width: 450,
    height: 150,
    bgcolor: 'white',
    border: 'none',
    p: 4,
    zIndex: 10,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)"
  },
  headingText: {
    width: "100%",
    textAlign: "center",
    fontSize: '24px',
    fontWeight: 500,
    color: "#010101",
    fontFamily:"Silika-Medium"
  },
  backButton: {
    position: 'absolute' as 'absolute',
    top: '20px',
    left: '20px', 
  },
  formContainerDrop:{
    width: "100%",
    display: "flex",
    padding: "8px 12px 8px 40px",
  },
  formDropTextDisabled:{
    marginBottom: "10px",
    fontSize: "14px",
    color: "gray",
    fontWeight: 500, 
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Silka",
  },
  fomrContainerDrop:{
    padding: "8px 12px 8px 40px",
    display: "flex",
    width: "100%",
    paddingRight:"0px"
  },
  formContainerPostCode: {
    padding: "8px 37px 8px 18px",
    display: "flex",
    width: "100%",
  },
};
// Customizable Area End
