import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { apiCall } from "../../../../packages/components/src/ApiCall";

// Customizable Area Start
interface IAccountProfile{
  data: {
    id: string,
    type: string,
    attributes: {
      hourly_rate_from: string,
      hourly_rate_to: string,
      photo:string
    }
  }
}

interface Account{
    data: {
      id: string,
      type: string,
      attributes: {
        account_profile: IAccountProfile
      }
    }
}
export interface IJob{
  job_title: string,
  job_description: string,
  country: string,
  county: string,
  town: string,
  link: string,
  salary: string,
  start_date: string,
  end_date: string,
  location: string,
  level: string[],
  category:string,
  created_at:string,
  profession:string[],
  budget:string,
  account:Account,
  banner_image:string
}

interface IJobResponse{
    id: string,
    type: string,
    attributes: IJob
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End


export interface S {
  // Customizable Area Start
  applyPopUpOpen:boolean;
  jobId:number;
  jobDetailData:IJobResponse
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
interface SS {
  id: any;
}


export default class JobProfileWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getJobDetailApiCallId:string="";
  applyApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      applyPopUpOpen:false,
      jobId:0,
      jobDetailData:{ }as IJobResponse
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    this.detailApi();
  }
  


 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {   
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getJobDetailApiCallId:
        this.handleJobDetailResoponse(responseJson);
        break;
        case this.applyApiCallId:
          this.handleJobDetailResoponse(responseJson);
          break;
      default:
       break;
    }
  }

  handleJobDetailResoponse = (responseJson:any) =>{
     this.setState({jobDetailData:responseJson.data});
  }

  handlePopupOpenApply = () =>{
    this.setState({ applyPopUpOpen:!this.state.applyPopUpOpen });
  }

  detailApi = async () => {
    this.getJobDetailApiCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getJobDetailApiEndPoint}/${this.props.navigation.getParam('id')}`,
      token: await getStorageData('token'),
    });
  };

  handleDayDiffrence = (startDateStr:string) => {
    const startDate = new Date(startDateStr);
    const differenceMs = new Date().getTime() - startDate.getTime();
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
    return differenceDays-1;
  }
  // Customizable Area End
}
