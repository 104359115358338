import React from "react";


import AddFriendsController, {
    Props,
} from "./AddFriendsController";

export default class AddFriends extends AddFriendsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start        
            <img
                className={`ADD_PEOPLE_BUTTON${this.props.accountId}`}
                style={{ width: '50px', height: '50px', borderRadius: '100px', ...this.props.imageStyle }}
                src={this.props.imageSrc}
                onClick={this.onPressAddPeople}
                alt='add icon'
            />
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
