import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import { closeIcon } from "../assets";
import CloseIconCross from "@mui/icons-material/Close";
import { Field, Form, Formik } from "formik";
import CustomTextField from "../../../../components/src/CustomTextField";
import * as Yup from "yup";
import { addImage, calender } from "../../../events/src/assets";
import DatePicker from "react-datepicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dayjs from "dayjs";
import { CityInfo, CountryInfo, DefaultAdSearchCountry, DefaultAdSearchCounty, DefaultAdSearchTown, ICustomInputProps, TownInfo } from "../../../events/src/AllEventsController.web";
import utcTime from 'dayjs/plugin/utc';
import { City, Country, ICity, ICountry, IState, State } from "country-state-city";
import { ListProps, StyledButton } from "../../../events/src/AllEvents.web";
import { Autocomplete } from "@mui/material";
import { StyledAutoCompletePaper } from "./AdvanceSearch";
dayjs.extend(utcTime);
interface Iprops {
  handleJobPostPopUp: () => void;
  open: boolean;
  handleCreateJob: (payload: ICreateJob) => void;
  seniorityLevels: {key:string,value:string}[];
  workTypes: string[];
  skills: string[];
  professions: {key:string,value:string}[];
}

export interface ICreateJob {
  bannerImage: File | null;
  jobTitle: string;
  jobType: {key:string,value:string}[]
  level:  {key:string,value:string}[]
  profession: string;
  startDate: string;
  salary: string;
  description: string;
  link: string;
  country: CountryInfo;
  town: TownInfo;
  county: CityInfo;
}

const intialValuesCreateJob = {
  bannerImage: null,
  jobTitle: "",
  jobType: [],
  level: [],
  profession: "",
  startDate: "",
  salary: "",
  description: "",
  link: "",
  country: DefaultAdSearchCountry,
  town: DefaultAdSearchTown,
  county: DefaultAdSearchCounty,
}

export const ValidationFields = Yup.object().shape({
  jobTitle: Yup.string()
    .required(" "),

  jobType: Yup.array()
    .required(" "),

  description: Yup.string().required(" "),

  profession: Yup.string()
    .required(" "),

  startDate: Yup.string()
    .required(" "),

  link: Yup.string()
    .required(" "),

  level: Yup.array()
    .required(" "),

});

export const StyledDialogueAction = styled(DialogActions)({
    padding:"0px !important"
})

export const StyledWrapperAutocomplete = styled("div")({
  "& .MuiFormControl-root": {
    width: "100% !important",
  },
  "& .MuiInputBase-input":{
    fontFamily:"Silika-Medium",
    fontSize:"14px",
    height:"48px",
    "&::placeholder":{
      fontFamily:"Silka",
      fontSize:"14px"
    },
  },
  "& .MuiOutlinedInput-input":{
    width: "100% !important",
  },
  "& .MuiAutocomplete-paper":{
    maxHeight: "100px", 
    overflowY: 'auto',
  },
  "& .MuiOutlinedInput-root":{
    padding:"0px !important",
    paddingLeft:"9px !important"
  }
});

const StyledDialogJobPostPopUp = styled(Dialog)({
  "& .borderNone":{
    border:"none !important"
  },
  "& .DropdownContainerLocation": {
    display: "flex",
    justifyContent: "space-between",
    marginTop:"30px"
  },
  "& .locationSelect": {
    width: "30%"
  },
  "& .MuiDialog-paperFullWidth": {
    maxWidth: "1195px",
  },
  "& .MuiDialogTitle-root": {
    padding: "15px 40px",
  },
  "& .MuiTypography-h6": {
    fontSize: "16px",
    fontFamily: "Silika-Medium",
  },
  "& .subTitle": {
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontWeight: 300,
    maxWidth: "480px",
    textAlign: "center",
    marginTop: "30px",
    color: "#010101",
  },
  "& .cancelBtn": {
    textTransform: "none",
    border: "1px solid black",
    borderRadius: "2px",
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    maxWidth: "521px",
    height: "48px",
    marginTop:"97px"
  },
  "& .yesBtn": {
    width: "112px",
    backgroundColor: "black",
    color: "white",
  },
  "& .btnContainer": {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "26px",
  },
  "& .opportunitySelectionBtn": {
    width: "521px",
    fontFamily: "silika-medium",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  "& .dialogueContent": {
    padding: "15px 40px",
    border: "1px solid black",
  },
  "& .labelOfSelect": {
    fontFamily: "silika-medium",
    fontSize: "16px",
    fontStyle: "normal",
    color: "black",
    marginTop: "20px",
  },
  "& .redStar": {
    color: "red",
    marginLeft: "-2px",
  },
  "& .borderOfImage": {
    border: "1px dashed #9B9B9D",
    borderRadius: "4px",
    marginRight: "5px",
    position: "relative" as "relative",
    height: "132px",
    width: "132px",
  },
  "& .crossImage": {
    position: "absolute" as "absolute",
    right: 1,
    cursor: "pointer",
    color: "black",
    height: "15px",
    width: "15px",
  },
  "& .startDateContainer": {
    width: "30%",
    marginTop: "4px"
  },
  "& .DropdownContainer": {
    display: "flex",
  },

});

export const StyledWrapper = styled("div")({
  "& .react-datepicker": {
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.189)",
    border: "none !important",
    width: "360px",
    fontFamily: "Silka",
    display: "flex",
    justifyContent: "center",
  },
  "& .react-datepicker__day--selected": {
    backgroundColor: "#DFDFDF",
    borderRadius: "24px",
    border: "1px solid var(--Grey, #9B9B9D)",
    color: "#010101",
  },
  "& .react-datepicker__header": {
    borderBottom: "none !important",
    backgroundColor: "white !important",
    marginTop: "20px",
    marginBottom: "20px",
  },
  "& .react-datepicker__current-month": {
    color: "black",
    weight: "700",
    fontFamily: "silka",
    marginBottom: "20px",
  },
  "& .react-datepicker__navigation": {
    top: "30",
    marginRight: "20px",
    marginLeft: "20px",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#DFDFDF",
    borderRadius: "24px",
    border: "1px solid var(--Grey, #9B9B9D)",
    color: "#010101",
  },
  "& .react-datepicker__day": {
    height: "40px",
    width: "40px",
    fontSize: "14px",
    lineHeight: "3 !important",
  },
  "& .react-datepicker__day:hover": {
    backgroundColor: "#DFDFDF",
    borderRadius: "24px",
    border: "1px solid var(--Grey, #9B9B9D)",
    color: "#010101",
    fontSize: "14px",
    lineHeight: "3 !important",
  },
  "& .react-datepicker__day-name": {
    width: "40px",
    color: "gray !important",
    fontSize: "14px",
  },
  "& .react-datepicker__month": {
    margin: "0px !important",
  },
  "& .react-datepicker__navigation--previous": {
    backgroundImage: "url('')",
  },
  "& .react-datepicker__input-container": {
    width: "100% !important",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "10px !important",
    width: "100% !important",
    "&::placeholder": {
      fontFamily: "Silka",
      weight: 400,
      fontSize: "14px",
      color: "#010101",
      opacity: "0.9"
    }
  },
  "& .MuiInput-underline": {
    "&::before": {
      border: "none"
    },
    "&::after": {
      border: "none"
    },
    "&:hover:not(.Mui-disabled)::before": {
      borderBottom: "none"
    }
  },
  "@media (max-width:550px)": {
    "& .react-datepicker": {
      width: "250px !important",
    },
    "& .react-datepicker__input-container": {
      width: "105% !important",
    },
    "& .react-datepicker__day": {
      height: "25px",
      width: "25px",
      fontSize: "9.5px",
      lineHeight: "3 !important",
    },
    "& .react-datepicker__day-name": {
      fontSize: "9.5px",
      height: "25px",
      width: "25px",
    },
    "& .react-datepicker__header": {
      width: "250px !important",
    }
  },
});

const StyledCreateEventDatePick = styled("div")({
  "& .react-datepicker-wrapper": {
    width: "100% !important",
  },
  "& .react-datepicker": {
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.189)",
    border: "none !important",
    fontFamily: "Silika-Medium",
    display: "flex",
    justifyContent: "center",
    "@media (max-width:1258px)": {
      "& .react-datepicker": {
        width: "90%",
      },
      "& .react-datepicker__input-container": {
        width: "96% !important",
      }
    },
  },
})

const StyledCustomInput = styled('div')({
  "& .MuiInputBase-input": {
    fontFamily: "Silika-Light",
    fontSize: "14px",
    fontWeight: "bolder",
    width: "85% !important",
    border: "transparent!important",
    "&::placeholder": {
      fontWeight: "bolder",
      color: "#010101 !important",
      fontFamily: "Silika-Light",
      fontSize: "1CreateJobWrapper4px",
    },
  }
})


export const CustomInput = React.forwardRef(
  ({ value, onClick, placeHolder, isEndIcon, isStartIcon, onBlur, onChange,name }: ICustomInputProps, reference: React.Ref<HTMLDivElement>) => (
    <StyledCustomInput className="custom-date-input" onClick={onClick} ref={reference} style={{ position: 'relative' }}>
      <Input
        type="text"
        value={value}
        style={{
          cursor: "pointer",
          border: "1px solid #DFDFDF",
          height: "42px",
          width: "100%",
          fontFamily: "Silika-Light",
          color: "#010101",
          borderRadius: "3px",
        }}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeHolder}
        startAdornment={isStartIcon ? (
          <img
            src={calender}
            style={{ marginRight: "10px", marginLeft: "10px" }}
            alt="Start Icon"
          />
        ) : null}
        endAdornment={isEndIcon ? (
          <KeyboardArrowDownIcon style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }} />
        ) : null}
      />
    </StyledCustomInput>
  )
);


const getCountry = () => {
  let CounntryArray: ICountry[] = Country.getAllCountries();
  let newArray = CounntryArray.map((countryVal: ICountry) => ({
    value: countryVal,
    label: countryVal.name,
  }));
  return newArray;
};

const getStateArray = (iosCodeOfState: string) => {
  let newArray = State.getStatesOfCountry(iosCodeOfState).map(
    (countryVal: IState) => ({ value: countryVal, label: countryVal.name })
  );
  return newArray;
};

const getCityArray = (iosCodeCountry: string, iosCodeCounty: string) => {
  let newArray = City.getCitiesOfState(iosCodeCountry, iosCodeCounty).map(
    (countryVal: ICity) => ({ value: countryVal, label: countryVal.name })
  );
  return newArray;
};


export const DialogJobPostPopUp = ({ open, handleJobPostPopUp, handleCreateJob,professions ,seniorityLevels}: Iprops) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [valid,setValidFile] = useState<boolean>(true);

  const handleImageChange = (events: React.ChangeEvent<HTMLInputElement>) => {
    const filesdr = events.target.files;
    if (filesdr) {
      const fileSizer = filesdr[0].size / (1024 * 1024);
      if (fileSizer <= 5 && filesdr[0].type.split("/").includes("image")) {
        setUploadedFile(filesdr[0]);
        setUploadedFileUrl(URL.createObjectURL(filesdr[0]));
        setValidFile(true);
      } else {
        setUploadedFileUrl(URL.createObjectURL(filesdr[0]));
        setValidFile(false);
      }
    }
  };
  const handleRemoveFile = () => {
    setUploadedFile(null);
    setUploadedFileUrl("");
    setValidFile(true)
  };

  const getErrorBoxValid = ()=> {
    return !valid  && (
      <Box
        style={{
          color: "#f44336",
          fontSize: "12px",
          marginTop: "10px",
        }}
      >
        No upload can be greater than 5MB
      </Box>
    )
   }

  const submitButton = (errors:object,touched:object) => {
    return <StyledButton>
    <Button disabled={Object.keys(errors).length > 0 || Object.keys(touched).length === 0||!valid} className="yesBtn cancelBtn borderNone" data-test-id="jobPost" type="submit">
      Post
    </Button>
  </StyledButton>
  }

  return (
    <StyledDialogJobPostPopUp
      fullWidth={true}
      maxWidth="md"
      open={open}
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={webStyle.experienceInnerDiv1}>
        <DialogTitle style={webStyle.dialougeHeading}>
          Post Position
        </DialogTitle>
        <img
          src={closeIcon}
          style={{
            width: "15px",
            height: "15px",
            marginRight: "32px",
            cursor: "pointer",
          }}
          data-test-id="closeBtnIcon"
          id="close-img"
          onClick={handleJobPostPopUp}
        ></img>
      </div>
      <DialogContent className="dialogueContent">
        <Formik
          validateOnBlur={true}
          initialValues={intialValuesCreateJob}
          validateOnChange={true}
          validationSchema={ValidationFields}
          onSubmit={(values) => {
            const modifiedValues = {
              ...values,
              bannerImage: uploadedFile
            };
            handleCreateJob(modifiedValues);
          }}
        >
          {({ handleChange, setFieldValue, getFieldProps, handleBlur, values, errors, touched }) => {
            return (
              <Form>
                <Typography className="labelOfSelect">
                  Banner Image 
                </Typography>
                { (uploadedFile || uploadedFileUrl !== "") && (
                  <Box className="borderOfImage">
                    <>
                      <img
                        width="108px"
                        height="108px"
                        src={uploadedFileUrl}
                        style={{ padding: "13px" }}
                      ></img>
                      <CloseIconCross
                        data-test-id="removeFile"
                        id="remove-image"
                        width="12px"
                        height="12px"
                        className="crossImage"
                        onClick={handleRemoveFile}
                      />
                    </>
                  </Box>
                )}
                {uploadedFileUrl == "" && (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    className="borderOfImage"
                  >
                    <input
                      onChange={(event) => handleImageChange(event)}
                      type="file"
                      data-test-id="uploadImageInput"
                      hidden
                      name="uploadMultipleImages"
                      accept="video/*,image/*"
                      className="filetype"
                      id="group_image1"
                    />
                    <Avatar
                      style={{
                        borderRadius: 0,
                        width: "90px",
                        height: "90px",
                      }}
                      className="profile-avatar"
                      src={addImage}
                    ></Avatar>
                  </IconButton>
                )}

                <>{getErrorBoxValid()}</>
                 <Typography className="labelOfSelect">
                 Job Title <span className="redStar">*</span>
                </Typography>
                <TextField
                  {...getFieldProps("jobTitle")}
                  name="jobTitle"
                  type="text"
                  variant="outlined"
                  value={values.jobTitle}
                  fullWidth
                />
                <Typography className="labelOfSelect">
                  Job Type <span className="redStar">*</span>
                </Typography>
                <Autocomplete
                    className="professionType"
                    data-test-id="jobType"
                    multiple
                    options={professions}
                    openOnFocus={false}
                    value={values.jobType}
                    PaperComponent={StyledAutoCompletePaper}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.value} 
                    renderOption={(props, option) => { 
                      return (
                        <Box style={{ fontFamily: 'Silika-Medium' }} {...props}>
                          {option.value}
                        </Box>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps }} variant="outlined" />}             
                    onChange={(_, newValue) => {
                         setFieldValue( "jobType",newValue)
                    }}
                />
                <Box className="DropdownContainerLocation">
                  <div className="locationSelect">
                    <FormControl fullWidth>
                      <StyledWrapperAutocomplete>
                        <Autocomplete
                          disableClearable
                          style={webStyle.autoComplateStyle}
                          ListboxProps={ListProps}
                          data-testId="CountryData"
                          data-test-id="CountryData"
                          value={values.country}
                          options={getCountry()?.map(
                            (options) => options.value
                          )}
                          getOptionLabel={(option: unknown) =>
                            (option as CountryInfo).name
                          }
                          popupIcon={<KeyboardArrowDownIcon style={{ color: "black" }} />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                            />
                          )}
                          onChange={(_event, newValue) => {
                            setFieldValue(
                              "country",
                              newValue === null ? "" : newValue
                            );
                            setFieldValue(
                              "county",
                              DefaultAdSearchCounty
                            );
                            setFieldValue("town", DefaultAdSearchTown);
                          }}
                        />
                      </StyledWrapperAutocomplete>
                    </FormControl>
                  </div>
                  <div className="locationSelect">
                    <FormControl fullWidth>
                      <StyledWrapperAutocomplete>
                        <Autocomplete
                          disableClearable
                          ListboxProps={ListProps}
                          placeholder=""
                          style={webStyle.autoComplateStyle}
                          value={values.county}
                          disabled={getStateArray(
                            values.country.isoCode
                          )?.map((options) => options.value).length == 0}
                          id="combo-box-demo"
                          data-test-id="RegionData"
                          options={getStateArray(
                            values.country.isoCode
                          )?.map((options) => options.value)}
                          getOptionLabel={(option: unknown) =>
                            (option as CountryInfo).name
                          }
                          popupIcon={<KeyboardArrowDownIcon style={{ color: "black" }} />}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                            />
                          )}
                          onChange={(_event, newValue) => {
                            setFieldValue("county", newValue);
                            setFieldValue("town", DefaultAdSearchTown);
                          }}
                        />
                      </StyledWrapperAutocomplete>
                    </FormControl>
                  </div>
                  <div className="locationSelect">
                    <FormControl fullWidth>
                      <StyledWrapperAutocomplete>
                        <Autocomplete
                          disableClearable
                          data-test-id="TownData"
                          style={webStyle.autoComplateStyle}
                          ListboxProps={ListProps}
                          id="combo-box-demo"
                          popupIcon={<KeyboardArrowDownIcon style={{ color: "black" }} />}
                          getOptionLabel={(option: unknown) =>
                            (option as TownInfo).name
                          }
                          value={values.town}
                          disabled={getCityArray(
                            values.country.isoCode,
                            values.county.isoCode
                          )?.map((options) => options.value).length == 0}
                          options={getCityArray(
                            values.country.isoCode,
                            values.county.isoCode
                          )?.map((options) => options.value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                            />
                          )}
                          onChange={(_event, newValue) => {
                            setFieldValue(
                              "town",
                              newValue === null ? "" : newValue
                            );
                          }}
                        />
                      </StyledWrapperAutocomplete>
                    </FormControl>
                  </div>
                </Box>
                <Typography className="labelOfSelect">
                  Level <span className="redStar">*</span>
                </Typography>
                 <Autocomplete
                    className="professionType"
                    data-test-id="level"
                    openOnFocus={false}
                    value={values.level}
                    options={seniorityLevels}
                    PaperComponent={StyledAutoCompletePaper}
                    multiple
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.value} 
                    renderOption={(props, option) => { 
                      return (
                        <Box style={{ fontFamily: 'Silika-Medium' }} {...props}>
                          {option.value}
                        </Box>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps }} variant="outlined" />}             
                    onChange={(_, newValue) => {
                         setFieldValue( "level",newValue)
                  }}
                />
                {console.log("levelr",values.level)}
                <Field
                  name="profession"
                  component={CustomTextField}
                  type="string"
                  variant="outlined"
                  title="Professions"
                  placeHolder=""
                  title1="*"
                  id="profession"
                />
                <Typography className="labelOfSelect">
                  Start Date <span className="redStar">*</span>
                </Typography>
                <StyledWrapper className="startDateContainer">
                  <StyledCreateEventDatePick>
                    <DatePicker
                      {...getFieldProps("startDate")}
                      data-test-id="startDatePicker"
                      onChange={(value: Date) => {
                        setFieldValue(
                          "startDate",
                          dayjs(value).format("DD/MM/YYYY")
                        );
                        setStartDate(value);
                      }}
                      dateFormat={"DD/MM/YYYY"}
                      value={values.startDate}
                      className="datePickDiv"
                      selected={startDate}
                      onBlur={handleBlur}
                      minDate={new Date()}
                      customInput={
                        <CustomInput
                          placeHolder={""}
                          isStartIcon
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="startDate"
                        />
                      }
                    />
                  </StyledCreateEventDatePick>
                </StyledWrapper>
                <Typography className="labelOfSelect">
                Salary 
                </Typography>
                <TextField
                  {...getFieldProps("salary")}
                  name="salary"
                  variant="outlined"
                  data-test-id="salary"
                  type="text"
                  value={values.salary}
                  className="startDateContainer"
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    const key = event.key;
                    if (!/\d/.test(key) && !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <Typography className="labelOfSelect">
                 Description <span className="redStar">*</span>
                </Typography>
                <TextField
                  {...getFieldProps("description")}
                  name="description"
                  type="textarea"
                  variant="outlined"
                  value={values.description}
                  fullWidth
                  multiline
                  minRows={3}
                />
                <Typography className="labelOfSelect">
                  Link <span className="redStar">*</span>
                </Typography>
                <TextField
                  {...getFieldProps("link")}
                  name="link"
                  type="text"
                  variant="outlined"
                  value={values.link}
                  fullWidth
                />
                <StyledDialogueAction
                >
                {submitButton(errors,touched)}
                </StyledDialogueAction>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>

    </StyledDialogJobPostPopUp>
  );
};

const webStyle = {
  experienceInnerDiv1: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1195px",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "19.3px",
    color: "white",
    alignItems: "center",
  },
  dialougeHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "16px !important",
    fontWeight: 500,
    lineHeight: "19px",
  },
  formLableText: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: "10px",
    color: "#010101",
    display: "flex",
    justifyContent: "space-between",
  },
  autoComplateStyle: {
    marginTop: "8px",
    fontSize: "15px",
  },
};
