import React from "react";
// Customizable Area Start
import DashboardController, {
  Props,
} from "./DashboardController";
import { styled, Theme, Typography, Table, TableBody, TableHead, TableCell, TableRow, Accordion, AccordionSummary, Grid, AccordionDetails, Box} from "@material-ui/core";
import { checkIfDueDateExpired } from "../../../components/src/HelperUtils";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// Customizable Area End

class CreativeDashboardListing extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderNoProjects = () => {
    return <Typography className="noProjects">You don't have any projects yet.</Typography>
  }
  renderExploreSection = () => {
    return (
      <div>

      </div>
    )
  }

  renderNextActions = () => {
    const data = [
      {
        project: "0001 Truckers",
        client: {
          name: "Car Imagine",
          logo: "path/to/car-imagine-logo.png",
        },
        deliverable: "01 Car circuits",
        action: "Submit deliverable",
        dueDate: "2024-12-31",
      },
      {
        project: "0002 Van O5",
        client: {
          name: "Car Imagine",
          logo: "path/to/car-imagine-logo.png",
        },
        deliverable: "02 Car circuits",
        action: "Submit Timesheet",
        dueDate: "2024-12-15",
      },
      {
        project: "0003 Van O5",
        client: {
          name: "Car Imagine",
          logo: "path/to/car-imagine-logo.png",
        },
        deliverable: "03 Car circuits",
        action: "Confirm Payment",
        dueDate: "2024-11-15",
      },
    ];
    return (
      <Box className="nextActionsContainer" boxShadow={1}>
          <Typography className="title">Next actions <span className="count">3</span></Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Deliverable</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Due</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {data.map(p => {
                  const d = checkIfDueDateExpired(p.dueDate);
                  return  <TableRow>
                    <TableCell>{p.project}</TableCell>
                    <TableCell className="client"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAsCAYAAAAATWqyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGnSURBVHgB7Zi9LwRBGMafE1QKHcmVIiok9BwlESXRaDVK/gMdaqVWEL3oJCLRkRCEnK8QjZCIiM/nNbvJmtu1M5cZe8U+yS/Z233fyy+3t+/MHVAjKUSOu0mzWRvOyHXkdS9pgn1eya5+coN8GTKj9XaSe4v+kMvwDergJgdkOJCpKq5EJHukhCplXIpIjqBk7mAZ1yKSUObBpsmHiOSY9JBb0wZfIpIy1CdzYVLsU0RyQobITVqhbxHJIenH7wGYiYhEJnGJXCFjEYnIDCAyTbMSQSAziJgv8H+LICJTRsYiknNot6ke7tMF9cgWyRPZIZvkTasrQy2UzkUayAKZCo6jkUk7Qk6184/hgctbs0ymYyQkHWSbtCU1uxIZJxMpNS1kDp5FJg3rxkgrPIoULWrb4VGk0aK2AI8i+4Z176h8cpyKrBvWrSJhs+RKZIWspdTINmA26aLLOSJPxDz5iLkmM6QPf+xJXE7WT6gfXktkFGpuPEON+K20Zh9rjayui7BMVqtvRXIRPbmInlxETy6iJxfRUzMi0UVP/vN8MWv72Wnl8ZpvvJNZkWqGYOgAAAAASUVORK5CYII=" alt=""/><span>{p.client.logo}</span></TableCell>
                    <TableCell>{p.deliverable}</TableCell>
                    <TableCell><span className="actionButton">{p.action}</span></TableCell>
                    <TableCell className={d.isDueDatePassed ? "dueDatePassed": ""}>{d.daysRemaining} Days</TableCell>
                  </TableRow>
                })}
            </TableBody>
          </Table>
      </Box>
    )
  }

  renderProjects = (projects: any, type: string) => {
    return (
      <Box className="openProjectsContainer" boxShadow={1}>
          <Typography className="title">{`${type === "open" ? "Open": "Closed"} projects`}</Typography>
          <div className={`openProjects ${type}`}>
          {projects.length > 0 ? projects.map((project: any) => {
            return (<Accordion key={project.id} className="accordionRow">
                <AccordionSummary className="accordionSummary" expandIcon={<NavigateNextIcon className="expandIcon"/>}>
                    <Grid container>
                        <Grid item>
                          <FolderOpenIcon className="folderIcon" />
                        </Grid>
                        <Grid item xs>
                          <Typography>
                            {project.id} {project.name} ({project.type})
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>Details</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                {project.details ? (
                  <div className="projectDetails">
                    <div className="projectInfo">
                      <div className="projectImgDiv">
                        <img
                          className="projectImg"
                          src="https://via.placeholder.com/50"
                          alt="Logo"
                        />
                      </div>
                      <div className="projectDescription">
                        <Typography className="subtitle1">{project.details.title}<span> &#40;Deliverable based&#41;</span></Typography>
                        <Typography className="body2">{project.details.client}</Typography>
                        <div className="subtitle2">
                          <div className="body3">
                          <span className="p1">Dates:</span> <span className="p2">{project.details.dates}</span>
                          </div>
                          <div className="body3">
                            <span className="p1">Budget</span> <span className="p2">{project.details.budget}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Table className="projectDeliveryTable">
                      <TableHead>
                        <TableRow>
                          <TableCell>Deliverable</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {project.details.deliverables.map((item: any) => (<TableRow>
                          <TableCell className="delivery"><span className="deliveryId">{item.id}</span><span className="deliveryName">{item.name}</span></TableCell>
                          <TableCell className={`status ${item.status.toLowerCase()}`}><span>{item.status}</span></TableCell>
                          <TableCell className="action"><span>{item.action}</span></TableCell>
                        </TableRow>))}
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <Typography>No details available.</Typography>
                )}
                </AccordionDetails>
            </Accordion>)
          }): <Typography className="noProjectsContainer">{`You currently have no ${type} projects.`}</Typography>}
          </div>
      </Box>
    )
  }

  renderOpenProjects = () => {
    return this.renderProjects(dummyProjects, "open")
  }

  renderClosedProjects = () => {
    return this.renderProjects(dummyProjects, "closed")
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledCreativeProfile data-test-id="creativeDashboardContainer">
          <Typography className="mainTitle">Dashboard</Typography>
          {this.renderNextActions()}
          {this.renderOpenProjects()}
          {this.renderClosedProjects()}
      </StyledCreativeProfile>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledCreativeProfile = styled("div")(({theme}: {theme: Theme}) => ({
  padding: "115px 80px 80px", 
  [theme.breakpoints.down("sm")]:{
    padding: "24px"
  },
  "& *":{
    fontFamily: "Silika-Medium"
  },
  "& .mainTitle":{
    fontSize: "32px",
    lineHeight: "40px"
  },
  "& .noProjects":{
    fontSize: "32px",
    lineHeight: "48.4px",
    textAlign: "center"
  },
  "& .nextActionsContainer":{
    padding: "32px 41px",
    marginTop: "68px",
    overflow: "auto",
    "@media (max-width: 649px)":{
      padding: "12px 8px",
    },
    "& .title":{
      fontSize: "23px",
      lineHeight: "24.13px",
      margin: "5px 10px 5px 0px",
      display: "inline-flex",
      marginBottom: "30px",
      fontWeight: "bold",
    },
    "& .count":{
      height: "25px",
      width: "25px",
      borderRadius: "50%",
      color: "#ffffff",
      background: "#dc2626",
      display: "block",
      textAlign: "center",
      marginLeft: "10px",
      fontSize: "16px"
    },
    "&  table":{
      "& thead":{
        borderTop: "1px solid #dfdfdf",
        "& th":{
          padding: "16px 8px",
          fontWeight: "bold",
          "@media (max-width: 800px)":{
           padding: "8px 4px"
          },
        }
      },
      "& tbody":{
        "& td":{
          padding: "8px",
          "@media (max-width: 600px)":{
            padding: "4px"
           },
        },
        "& .actionButton":{
          background: "#0d0c22",
          border: "1px solid #000",
          borderRadius: "2px",
          color: "#fff",
          width: "73%",
          display: "block",
          padding: "5px 0",
          textAlign: "center",
          "@media (max-width: 900px)":{
            width: "100%",
          }
        }, 
        "& .dueDatePassed":{
          color: "#ff5959"
        },
        "& .client":{
          display: "inline-flex",
          alignItems: "center",
          width: "100%",
          "& img":{
            aspectRatio: "1",
            height: "32px",
            marginRight: "12px",
            border: "1px solid #dfdfdf",
            borderRadius: "4px"
          },
          "@media (max-width: 1175px)":{
            display: "flex",
            flexFlow: "column",
            width: "100%",
            alignItems: "flex-start"
          }
        }
      }
    }
  },
  "& .openProjectsContainer":{
    marginTop: "80px",
    "& .noProjectsContainer":{
      textAlign: "center"
    },
    "& .closed":{
      "& *":{
        color: "#9b9b9d !important"
      }
    },
    "& .title":{
      background: "#dfdfdf",
      width: "calc(100% - 25px)",
      fontSize: "16px",
      lineHeight: "19.3px",
      padding: "21px 0 21px 25px"
    },
    "& .openProjects":{
      padding: "40px 42px 70px 42px",
      "@media (max-width: 649px)":{
        padding: "8px 4px",
      },
      "& .accordionRow":{
        boxShadow: "none",
        borderRadius: "0px",
        "&:hover":{
          background: "#dfdfdf"
        },
        "&:hover .MuiCollapse-entered": {
          background: "#fff"
        }
      },
      "& .MuiAccordion-root::before":{
        backgroundColor: "transparent !important",
      },
      "& .accordionSummary":{
        borderBottom: "1px solid #dfdfdf",
        padding: "10px 40px 10px 42px",
        "@media (max-width: 649px)":{
          padding: "8px",
        },
      },
      "& .folderIcon":{
        marginRight: "26px"
      },
      "& .MuiAccordionSummary-expandIcon":{
        padding: "12px 12px 12px 0",
      },
      "& .MuiAccordionDetails-root":{
        padding: "24px 35px 48px 44px",
        "@media (max-width: 649px)":{
          padding: "12px 8px",
        },
      },
      "& .projectDetails":{
        width: "100%",
        overflow: "auto",
        "& .projectInfo":{
          display: "inline-flex",
          width: "100%",
          "@media (max-width: 650px)":{
           display: "flex",
           flexFlow: "column"
          },
        },
        "& .projectImgDiv":{
          marginRight: "36px",
          "& .projectImg":{
            aspectRatio: "133/134",
            height: "134px"
          },
        },
        "& .projectDescription":{
          width: "100%",
          margin: "7px 0",
          "& .subtitle1":{
            fontSize: "26px",
            lineHeight: "31.37px",
            display: "flex",
            alignItems: "center",
            marginBottom: "4px",
            "& span":{
              fontFamily: "Silika-Light !important",
              fontSize: "16px",
              lineHeight: "24px",
              marginLeft: "10px",
              "@media (max-width: 800px)":{
                margin: "0px"
              }
            },
            "@media (max-width: 800px)":{
              flexWrap: "wrap"
            }
          },
          "& .subtitle2":{
            display: "inline-flex",
            width: "100%",
            borderTop: "1px solid #dfdfdf",
            borderBottom: "1px solid #dfdfdf",
            padding: "9px 0",
            "@media (max-width: 800px)":{
              display: "flex",
              flexFlow: "column"
            }
          },
          "& .body2":{
            width: "50%",
            fontSize: "16px",
            lineHeight: "19.2px",
            color: "#9b9b9b",
            marginBottom: "26px"
          },
          "& .body3":{
            width: "50%",
            display: "inline-flex",
            alignItems: "center",
            "@media (max-width: 800px)":{
              width: "100%"
            },
            "& .p1":{
              fontSize: "14px",
              lineHeight: "19.36px",
              color: "9b9b9d",
              marginRight: "13px"
            },
            "& .p2":{
              fontSize: "14px",
              lineHeight: "18px",
              color: "#010101"
            }
          }
        },
        "& .projectDeliveryTable":{
          marginTop: "52px",
          borderTop: "1px solid #dfdfdf",
          "& td":{
            border: "none",
            padding: "8px"
          },
          "& tr":{
            borderBottom: "1px solid #dfdfdf",
          },
          "& th":{
            padding: "12px"
          },
          "& .delivery":{
            display: "inline-flex",
            width: "100%",
            margin: "6px 0",
            "& .deliveryId":{
              padding: "0 24px 0 12px",
              marginRight: "7px"
            },
            "& .deliveryName":{
              padding: "0 0 0 12px"
            }
          },
          "& .status":{
            "& span":{
              fontSize: "12px",
              lineHeight: "18px",
              background: "#dfdfdf",
              borderRadius: "2px",
              padding: "2px 0",
              width: "52%",
              display: "block",
              textAlign: "center",
              "@media (max-width: 900px)":{
                width: "100%",
              }
            }
          },
          "& .paid":{
            "& span":{
              background: "#fef3c7 !important",
              color: "#d97706"
            }
          },
          "& .action":{
            height: "100%",
              "& span":{
                background: "#0d0c22",
                color: "#fff",
                display: "flex",
                width: "66%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: "32px",
                "@media (max-width: 900px)":{
                  width: "100%",
                }
              }
          }
        }
      }
    },
  }
}));

const dummyProjects = [
  {
    id: "0001",
    name: "Truckers",
    type: "Deliverable based",
    details: {
      title: "Automotive Designer",
      client: "Supercars",
      dates: "Feb 30 - Feb 20",
      budget: "£6,000",
      deliverables: [
        { id: "D001", name: "Car circuits", status: "Invoiced awaiting payment", action: "Make Payment" },
        { id: "D002", name: "Frontal Lights", status: "Submitted for Approval", action: "Approve/Reject" },
        { id: "D003", name: "Car Dashboard", status: "Paid", action: "Approve/Reject" },
      ],
    },
  },
  {
    id: "0002",
    name: "Van 05",
    type: "Hourly based",
    details: null,
  },
  {
    id: "0003",
    name: "Truckers",
    type: "Deliverable based",
    details: {
      title: "Automotive Designer",
      client: "Supercars",
      dates: "Feb 30 - Feb 20",
      budget: "£6,000",
      deliverables: [
        { id: "D001", name: "Car circuits", status: "Invoiced awaiting payment", action: "Make Payment" },
        { id: "D002", name: "Frontal Lights", status: "Submitted for Approval", action: "Approve/Reject" },
        { id: "D003", name: "Car Dashboard", status: "Paid", action: "Approve/Reject" },
      ],
    },
  },
];

export default CreativeDashboardListing;
// Customizable Area End
