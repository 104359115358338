import React from "react";

// Customizable Area Start
import { Button } from "@material-ui/core";
// Customizable Area End

import AddFavouritesControllerWeb, {
  Props,
  configJSON,
} from "./AddFavouritesController.web";

export default class AddFavouritesWeb extends AddFavouritesControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <Button id="addFavourites" variant="contained">{configJSON.labelTitleText}</Button>;
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End