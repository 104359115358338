import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SubscriptionPlan, SubscriptionPlanFeature, FeatureCategory} from "../../../components/src/types";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End


export interface S {
  // Customizable Area Start
  showSecurityCode : boolean;
  nameOnCard : string;
  cardNumber : string;
  expirationDate : string;
  securityCode : string;
  subscriptionPlans: SubscriptionPlan[];
  subscriptionFeatureCategories: FeatureCategory[];
  subscriptionFeatures: SubscriptionPlanFeature[];
  viewAllFeaturesOpen: boolean;
  windowWidth: number;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
interface SS {
  id: any;
}


export default class ClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchSubscriptionPlansApiCallId : string | null = "";
  fetchSubscriptionFeatureCategoriesCallId: string | null = "";
  fetchSubscriptionFeaturesCallId: string | null = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleResize = this.handleResize.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      showSecurityCode : false,
      nameOnCard : "",
      cardNumber : "",
      expirationDate : "",
      securityCode : "",
      subscriptionPlans: [],
      subscriptionFeatureCategories: [],
      subscriptionFeatures: [],
      viewAllFeaturesOpen: false,
      windowWidth: window.innerWidth,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    this.fetchSubscriptionPlans();
    this.fetchSubscriptionFeatures();
    this.fetchSubscriptionFeatureCategories();
    window.addEventListener("resize", this.handleResize);
  }
  


 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
        const responseType = getName(MessageEnum.RestAPIResponceMessage);
        if(responseType === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if(apiRequestCallId){
                switch(apiRequestCallId){
                    case this.fetchSubscriptionPlansApiCallId: 
                        this.setState({subscriptionPlans: responseJson.data})
                        break;
                    case this.fetchSubscriptionFeatureCategoriesCallId:
                        this.setState({ subscriptionFeatureCategories: responseJson.data})
                        break;
                    case this.fetchSubscriptionFeaturesCallId: 
                        this.setState({subscriptionFeatures: responseJson.data});
                        break;
                    default: {
                        break;
                    }
                }
            }
        }
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize() {
      const innerWidth = window.innerWidth;
      this.setState({ windowWidth:  innerWidth});
  }
  handleScrollUp = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}
  handleBtnClick(role : string){ 
    sessionStorage.setItem('selectedRole', role);
    this.props.navigation.navigate("AccountRegistration")
  }
  handleToggleSecurityNumber  = () =>{
    this.setState({showSecurityCode : !this.state.showSecurityCode});
  }
  handleKeyDown = (e:any) =>{
    if (
      (e.key < '0' || e.key > '9') &&  // If the key is not a number
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'Tab' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight'
  ) {
      e.preventDefault();  // Prevent other keys
  }
  }
  handleFeaturesOpen = () => {
    this.setState(prevState => ({...prevState, viewAllFeaturesOpen: !prevState.viewAllFeaturesOpen}))
  };
  fetchSubscriptionPlans = () => {
    const headers = {
      token: window.localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubscriptionPlansApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSubscriptionsPlan);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  fetchSubscriptionFeatures = () => {
    const headers = {
      token: window.localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubscriptionFeaturesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSubscriptionFeatures);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchSubscriptionFeatureCategories = () => {
    const headers = {
      token: window.localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchSubscriptionFeatureCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSubscriptionFeatureCategories);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
