import React, { useRef } from 'react';
import {
    makeStyles, Theme, createStyles, TextField, InputLabel, Chip, Avatar, TextareaAutosize
} from "@material-ui/core";
import { FieldProps } from 'formik';
import { AutocompleteGetTagProps, AutocompleteRenderInputParams, Autocomplete } from '@material-ui/lab';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "2px",
            "& .MuiOutlinedInput-input": {
                "width": "100% !important",
                "padding": "14px",
                borderRadius: "2px",
                border: "0px solid #9C9C9C",
                background: "#FFF",
                color: "#010101",
                "fontFamily": "Silika-Light",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "&:hover": {
                    border: "0px solid #DFDFDF",
                },
                "&:focus": {
                    border: "0px solid #DFDFDF",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Silika-Light",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
            },
            "& .MuiInputBase-fullWidth":{
                "width":"100%"
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
            "& .css-i4bv87-MuiSvgIcon-root" :{
                color:"#DFDFDF"
            },
            "&.MuiOutlinedInput-multiline" : {
              height : "100px",
              overflowY : "scroll",
              backgroundColor : "red"
            },
            "& .MuiChip-label":{
              fontFamily: 'Silika-Medium',
            }
        },

        inputLabel: {
            color: '#010101',
            fontFamily: 'Silika-Medium',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '19.3px',
            paddingBottom: '4px',
            marginTop: "20px"
        },
        errorText: {
            "color": "#DC2626",
            "fontFamily": "Silika-Light",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "marginBottom": "32px",
            "marginTop": "6px"
        },
        autoCompleteText: {
            width: "100%",
        },
        chitStyle: {
            backgroundColor: "red"
        }
    })
);

type Props = FieldProps & {
    placeHolder: string;
    title: string;
    multiline?: boolean;
    options: any
    image?: string
    isTag?: boolean;
    extractEmailDomain?: boolean; 
    isCreatable?: boolean;
};

interface Option {
    id: string | null;
    label: string;
    profileImg: string | null;
    value?: string
  }

const CustomTag: React.FC<Props> = ({
    field,
    form,
    placeHolder,
    options,
    title,
    multiline = false,
    image,
    isTag = false,
    extractEmailDomain = false,
    isCreatable = false
  }) => {
    const classes = useStyles();
  
    const handleChange = (event: React.ChangeEvent<{}>, value: Option[]) => {
      
      const processedValue = value.map((option) => {
        if (extractEmailDomain) {
          const atIndex = option.label.indexOf('@');
          if (atIndex !== -1) {
            return {
              ...option,
              label: option.label,
              value: option.label,
              id: Math.random()*100+Math.random()*100,
              profileImg:  null,
            };
          }
        }
        return option;
      });

      form.setFieldValue(field.name, processedValue);
    };
  
    const filterOptions = (options: Option[], { inputValue }: { inputValue: string }) => {
      return options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()) && 
        !field.value.some((selected: Option) => selected.id?.toString() === option.id?.toString())
      );
    };
    
  
    return (
      <>
        <InputLabel className={classes.inputLabel} style={{ marginTop: "20px" }} htmlFor={field.name}>
          {title}
        </InputLabel>
       {isCreatable ?  
       
       <Autocomplete
       className={classes.autoCompleteText}
       options={options}
       getOptionLabel={(option: Option) => option.label}
       multiple
       onChange={handleChange}
       filterOptions={(options, params) => {
         const filtered = options.filter(option =>
           option.label.toLowerCase().includes(params.inputValue.toLowerCase()) &&
           !field.value.some((selected: Option) => selected.id?.toString() === option.id?.toString())
         );
   
         if (params.inputValue.includes('@') && !filtered.some(option => option.label === params.inputValue)) {
           filtered.push({
             label: `Invite by email (This collaborator will be visible after they onboard to our platform)`,
             profileImg: null,
             id: (Math.random()*100+Math.random()*100).toString(),
             value: params.inputValue
           });
         }
   
         return filtered;
       }}
       value={field.value || []}
       renderTags={(value: Option[], props: AutocompleteGetTagProps) =>
        value.map((option: Option, index: number) => {
          return (
            <Chip
              avatar={
                !isTag && option.profileImg ? <Avatar alt={option.label} src={option.profileImg} /> : undefined
              }
              key={option.label}
              label={option.label}
              {...props({ index })}
              style={{ borderRadius: "2px", backgroundColor: "white", border: "1px solid #DFDFDF" }}
            />
          );
        })
       }
       renderInput={(params: AutocompleteRenderInputParams) => (
         <TextField
           {...params}
           placeholder={placeHolder}
           className={classes.root}
           fullWidth
           id={field.name}
           variant="outlined"
           error={!!form.errors[field.name] && !!form.touched[field.name]}
           helperText={
             !!form.errors[field.name] &&
             form.touched[field.name] &&
             form.errors[field.name]
           }
         />
       )}
       noOptionsText={
         isCreatable
           ? 'No matching collaborators, or invite by email'
           : 'No options available'
       }
   />: <Autocomplete
  className={classes.autoCompleteText}
  options={options}
  getOptionLabel={(option: Option) => option.label}
  multiple
  onChange={handleChange}
  filterOptions={filterOptions}
  value={field.value || []} // Ensure field.value is used
      renderTags={(value: Option[], props: AutocompleteGetTagProps) =>
        value.map((option: Option, index: number) => (
      <Chip
        avatar={
                !isTag && option.profileImg ? <Avatar alt={option.label} src={options.profile} /> : undefined
        }
        key={option.label}
        label={option.label}
        {...props({ index })}
            style={{ borderRadius: "2px", backgroundColor: "white", border: "1px solid #DFDFDF" }}
      />
        ))
      }
  renderInput={(params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={placeHolder}
      className={classes.root}
      fullWidth
      id={field.name}
      variant="outlined"
      error={!!form.errors[field.name] && !!form.touched[field.name]}
      helperText={
        !!form.errors[field.name] &&
        form.touched[field.name] &&
        form.errors[field.name]
      }
    />
  )}
/>
      }

      </>
    );
  };

export default CustomTag;
