import React from "react";
import { Button, styled } from "@material-ui/core";

export const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor:"transparent",
    color : "#0D0C22",
    width: '300px',
    marginBottom: '24px', 
    [theme.breakpoints.up('sm')]: {
      width: '327px',
    },
    [theme.breakpoints.up('md')]: {
      width: '360px',
    },
    "&:hover":{
        backgroundColor:"black",
        color:"white",
    }
  }));

type Props = {
    title : string,
    handleClick  :(e:any) =>void,
    id : string
}

function JoinForgeButtons(props:Props) {
    const {title, handleClick,id } = props
  return (
    <StyledButton
        variant="outlined"
        data-test-id={id }
        onClick={handleClick}
        style={{ ...webStyle.logInButton, textTransform: "none",  }}
    >
        {title}
    </StyledButton>
  )
}

export default JoinForgeButtons

const webStyle = {
    logInButton: {
        height: "56px",
        borderRadius: "2px",
        fontFamily: "Silika-Medium",
        fontSize: '16px',
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        cursor: "pointer"
      },
}