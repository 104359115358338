export const planIcon = require("../assets/addEvent/image-plane.png");
export const forwardIcon = require("../assets/addEvent/icons8-forward-52.png");
export const schooldropIcon = require("../assets/addEvent/schooldrop.png");
export const schoolpickIcon = require("../assets/addEvent/schoolpick.png");
export const GetTogetherIcon = require("../assets/addEvent/GetTogether.png");
export const MeetingIcon = require("../assets/addEvent/Meeting.png");
export const OutforCoffeeIcon = require("../assets/addEvent/OutforCoffee.png");
export const GymIcon = require("../assets/addEvent/Gym.png");
export const FlightIcon = require("../assets/addEvent/flight.png");
export const DoctorIcon = require("../assets/addEvent/doctor.png");
export const VetIcon = require("../assets/addEvent/vet.png");
export const RentIcon = require("../assets/addEvent/money.png");
export const FeesIcon = require("../assets/addEvent/fees2x.png");
export const ActivityIcon = require("../assets/addEvent/image-pulse2x.png");
export const CustomIcon = require("../assets/addEvent/image-custom2x.png");
export const cakeIcon = require("../assets/addEvent/image-cake.png");
export const AddIcon = require("../assets/addEvent/icons8-plus-48.png");
export const LocationIcon = require("../assets/addEvent/image-location.png");
export const DownArrowIcon = require("../assets/addEvent/image-right.png");
export const CricleIcon = require("../assets/addEvent/cicrleB.png");
export const CricleTickIcon = require("../assets/addEvent/tickCircle.png");
export const SquareTickIcon = require("../assets/addEvent/imageTick.png");
export const UpwardIcon = require("../assets/addEvent/upArroww.png");
export const downAIcon = require("../assets/addEvent/downArrow.png");
export const alarm = require("../assets/addEvent/alarm.png");
export const clear = require("../assets/clear.png");
export const locationTarget = require("../assets/target.png");

//
export const birthday = require("../assets/icon/birthday.png");
export const school_drop = require("../assets/icon/school_drop.png");
export const school_pick = require("../assets/icon/school_pick.png");
export const getTogether = require("../assets/icon/get_togther.png");
export const meeting = require("../assets/icon/meeting.png");
export const outForCoffee = require("../assets/icon/out_for_coffee.png");
export const gym = require("../assets/icon/gym.png");
export const flight = require("../assets/icon/flight.png");
export const vet = require("../assets/icon/vet.png");
export const rent = require("../assets/icon/rent.png");
export const fees = require("../assets/icon/fees.png");
export const activity = require("../assets/icon/activity.png");
export const custom = require("../assets/icon/custom.png");
export const doctor = require("../assets/icon/doctor.png");
export const selected = require("../assets/tick.png");

export const upcominglogo = require("../assets/menu.png");
export const addEvent = require("../assets/image-plus.png");
export const close = require("../assets/close.png");
export const edit = require("../assets/edit.png");
export const alllogo = require("../assets/all-logo.png");
export const addImage=require("../assets/add.svg");
export const calender=require("../assets/icon/calender.svg");
export const leftArrow=require("../assets/icon/left.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const dropDown = require("../assets/dropDown.svg");
export const cancel = require("../assets/cacle.svg")
export const share = require("../assets/share.svg")
export const save = require("../assets/save.svg")
export const location = require("../assets/location.svg")
export const hero = require("../assets/Hero.jpeg")
export const profilepic = require("../assets/profilepic.png")
export const link = require("../assets/link.png")
export const calenderPng = require("../assets/calender.png")
export const ChatIcon = require("../assets/image__.svg");
export const CopyImage = require("../assets/group_icon.svg");
export const BackBtn = require("../assets/back.svg");
export const BannerDefault= require("../assets/bannerDef_.png");
export const BackBtnDetail= require("../assets/backButton.svg");
export const WebSunnyIcon= require("../assets/sunnyWeb.svg");

