import React from "react";
// Customizable Area Start
import {
  SafeAreaView,
  StyleSheet,
} from "react-native";

import scale, { verticalScale } from "../../../components/src/Scale";
import PostCreationWebController, { Props } from "./PostCreationWebController";
import PostCreationPopUp from "./components/PostCreationPopUp";
import Preview from "./components/Preview"

// Customizable Area End

export default class PostCreationWeb extends PostCreationWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    
    
    return (
      <>
      <SafeAreaView style={styles.safeAreaView}>
        {/* Customizable Area Start */}
        <PostCreationPopUp onClickClose={this.props.onClickClose} handleKeyDownDes={this.handleKeyDownDes} handleKeyDown={this.handleKeyDown}  handleChangeToggle={this.handleChangeToggle} updatePost={this.updatePost} state={this.state} removeImage={this.removeImage} handleImageChange={this.handleImageChange} handlePreviewOpen={this.handlePreviewOpen} savePopUpData={this.savePopUpData} isEditMode={false} handleClosePopUp={this.handleDisplayClose} />
        {this.state.displayPreview &&
         <Preview   state={this.state} updatePost={this.updatePost} handlePreviewClose={this.handlePreviewClose} isEdited={false} profileImage={this.props.profileImage} handleSetVisiblePreview={this.handleSetVisiblePreview}  />
        }
       
        {/* Customizable Area End */}
      </SafeAreaView>
      </>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "90%",
    marginTop:"30px"
  },
  inputTextField: {
    height: verticalScale(80),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    margin: scale(3),
    padding: 10,
    marginTop: verticalScale(5)
  },

  inputTextField1: {
    height: verticalScale(50),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    paddingLeft: 10,
    paddingRight: 10,
    margin: scale(3)
  },

  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 22,
    backgroundColor: "#366ef9",
    height: scale(50),
    marginTop: verticalScale(20)
  },
  imageStyle: {
    width: scale(100),
    height: scale(100),
    borderRadius: scale(50),
    borderWidth: 1,
    borderColor: "black",
    marginTop: verticalScale(5),
    marginBottom: verticalScale(25),
    opacity: 0.6
  },
  buttonText: {
    color: "white"
  },
  editIcon: {
    position: "absolute",
    height: scale(20),
    width: scale(20),
    resizeMode: "contain",
    top: verticalScale(90),
    left: scale(90)
  }
});
// Customizable Area End
