import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Select,
  MenuItem,
  Theme,
  Divider,
  styled,
  Dialog
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DatePicker from 'react-datepicker';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { pdfType, imageType, plus } from '../assets';

const arrow = (props: any) => (<svg width="24"{...props} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.12459 9.00344L12.0046 12.8834L15.8846 9.00344C16.2746 8.61344 16.9046 8.61344 17.2946 9.00344C17.6846 9.39344 17.6846 10.0234 17.2946 10.4134L12.7046 15.0034C12.3146 15.3934 11.6846 15.3934 11.2946 15.0034L6.70459 10.4134C6.51734 10.2266 6.41211 9.97296 6.41211 9.70844C6.41211 9.44392 6.51734 9.19027 6.70459 9.00344C7.09459 8.62344 7.73459 8.61344 8.12459 9.00344Z" fill="black" />
</svg>)

const AttachmentContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  overflowX: "auto",
  padding: "10px 0",
  "& .fileBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70px",
  },
  "& .filePreview": {
    height: "70px",
    width: "70px",
    border: "1px solid black",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
  },
  "& .fileName": {
    width: "70px",
    fontSize: "10px",
    textAlign: "center",
    marginTop: "4px",
  },
  "& .uploadLabel": {
    height: "70px",
    width: "70px",
    border: "1px dashed #9B9B9B",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  }
});

const StyledShareChatDial = styled(Dialog)({
  "& .MuiPaper-root": {
    minWidth: "1195px",
  },
  "& .cancelBtn": {
    textTransform: "unset",
    color: "#0D0C22",
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#FFFFFF",
    padding: "8px 10px",
   
    fontWeight: 500,
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px"
  },
  "& .yesBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    
    cursor: "pointer",
    borderRadius: "1px",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#010101",
    padding: "8px 10px",
    border: "1px solid #010101",
    "&.disabledButton": {
      backgroundColor: "#c4c4c4",
      cursor: "not-allowed",
      border: "none",
      padding: "10px 10px",
    },
  },
})
const StyledDialogTitle = styled(DialogTitle)(({ theme }: { theme: Theme }) => ({
  padding: "0px",
  "& .headding": {
    height: "60px",
    borderRadius: "4px 4px 0px 0px",
    
    alignContent: "center",
    fontSize: "16px",
    background: "#0D0C22",
    color: "#FFFFFF",
    fontFamily: "Silka",

    paddingLeft: "40px",
    fontWeight: "500",
  },
  '& .flexWrap': {
    display: 'flex',
    justifyContent: "space-between",
    padding: "0px 38px",
    fontFamily: "Silika-Light",
    alignItems: "center",
    curser: "pointer"
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  flexFlow: "column",
  padding: "40px 41px",
  [theme.breakpoints.down('sm')]: {
    width: "calc(100% - 48px)",
    oveflow: "hidden",
  },
  
  "& .textStyle": {
    fontFamily: "Silika-Light",
    fontWeight: "500",
    marginBottom: "8px",
    fontSize: "16px",
   
    color: "black",
    "& span": {
      color: "#FF5959"
    }
  },
  "& .textMuted": {
    fontFamily: "Silika-Light",
   
    marginBottom: "8px",
    color: "#9B9B9B",
    fontSize: "16px",
    textAlign: 'right',
    fontWeight: "500",
  },
  "& .title": {
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "8px",
    color: "black",
    display:'flex',
    justifyContent:"flex-start",
    alignItems:"center",
    gap:'30px',
    "& span": {
      color: "#FF5959"
    },
   
  },
  "& .selectStyle": {
    width: "100%",
    height: "48px",
    "&.Mui-error": {
      borderColor: "#FF5959"
    },
    "& .MuiSelect-select": {
      padding: "12px"
    }
  },
  "& .dateStyle": {
    width: "100%",
    marginRight: "20px",

    "& .MuiInputBase-root": {
      height: "48px",

      borderLeft: "1px solid #DFDFDF",

    },
    "& .MuiIconButton-edgeStart": {
      color: "black"
    },
    "& .react-datepicker-wrapper": {
      borderLeft: "1px solid #DFDFDF",
      borderTop: "1px solid #DFDFDF",

      width: "100%",
      borderBottom: "1px solid #DFDFDF",
      borderRadius: "2px",
      "&. error": {
        borderColor: "#FF5959",
      }
    },
    "& .datepickerStyle": {
      "&.error": {
        "& input": {
          borderRadius: "2px"
        }
      },
      width: "100%",
    },
    "& input": {
      width: "100%",
      height: "48px",
      padding: "10px 8px",
      borderRight: "1px solid #DFDFDF",
      "&:focus": {
        outline: "none",
        borderColor: "#0D0C22"
      }
    }
  },
  "& .MuiFormControl-root": {
    width: "100%"
  },
  "& .inputStyle": {
    "& input": {
      width: "1114px",
       padding: "10px 8px 10px 8px",
      border: "1px solid var(--Light-Grey, #DFDFDF)",
      height: "48px",
     
    }
  },
  "& .CustominputStyle": {
    width: "100%",
    "& input": {
      height: "48px",
     
      width: "100%",
      padding: "10px 8px",
      border: "1px solid #DFDFDF",
      "&:focus": {
        outline: "none",
        borderColor: "#0D0C22"
      }
    }
  },
  "& .inputStyle1": {
    "& input": {
      height: "48px",
      border: "1px solid var(--Light-Grey, #DFDFDF)",
      padding: "10px 8px 10px 8px",
    }
  },
  "& .datepickerStyle": {
    "& input": {
      border: "none",
      outline: 'none',
      height: "48px",
      width: "100%"
    },
  },
  "& .textAreaStyle": {
    height: "139px !important",
    border: "1px solid #DFDFDF",
    padding: "10px 8px",
    fontSize: "16px",
    fontFamily: "Silka",
    width: "100%",
    
    resize: "none",
    "&:focus": {
      outline: "none",
      borderColor: "#0D0C22"
    }
  },
  "& .textArea": {
    height: "139px !important",
    width: "100%",
   
    resize: "none",
    border: "1px solid #DFDFDF",
    padding: "10px 8px",
    fontSize: "16px",
    fontFamily: "Silika-Light",
    "&:focus": {
      outline: "none",
      borderColor: "#0D0C22"
    }
  },

  "& .errorStyle": {
    height: "139px !important",
    width: "100%",
    border: "1px solid #FF5959",
   
    resize: "none",
    padding: "10px 8px",
    fontSize: "16px",
    fontFamily: "Silka",
  },

  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    color: "#FF5959",
    marginTop: "4px",
    fontSize: "12px"
  }

}))


const NewProjectForm = ({
  open,
  onClose,
  projFiles,
  handleBannerChange,
  handleFormSubmit,
  formatDate
}: any) => {
  return (
    <StyledShareChatDial
      open={open}
      onClose={onClose}
      data-test-id="new-form"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      hideBackdrop
    >
      <StyledDialogTitle id="alert-dialog-title"
      ><Box className="headding">
          New Project
        </Box>
      </StyledDialogTitle>

      <Formik
        initialValues={{
            projectName: '',
          description: '',
          budget: '',
          startDate: null,
          endDate: null,
          projectBase: 'Deliverables',
          name: '',
          profession: '',
          roleBudget: '',
          workType: '',
          location: ''
        }}
        onSubmit={(values) => {
        }}
        validationSchema={
          Yup.object().shape({
            projectName: Yup.string().required('This field is required'),
            description: Yup.string().required('This field is required'),
          })
        }
        isInitialValid={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isValid,
          handleSubmit
        }) => (
          <Form >
            <StyledDialogContent>
              <Box style={{ marginBottom: "15px" }}>
                <Typography className="textStyle">Project Name <span>*</span></Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="CustominputStyle"
                  name="projectName"
                  value={values.projectName}
                  onChange={handleChange("projectName")}
                  onBlur={handleBlur("projectName")}
                  error={errors.projectName && touched.projectName ? true : false}
                  helperText={errors.projectName && touched.projectName ? errors.projectName : ""}
                />
              </Box>

              <Box style={{ marginBottom: "15px" }}>
                <Typography className="textStyle">Description <span>*</span></Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="CustominputStyle"
                  name="description"
                  value={values.description}
                  onChange={handleChange("description")}
                  onBlur={handleBlur("description")}
                  error={errors.description && touched.description ? true : false}
                  helperText={errors.description && touched.description ? errors.description : ""}
                />
              </Box>

              <Box style={{ marginBottom: "15px" }}>
                <Typography className="textStyle">Project budget </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="CustominputStyle"
                  name="budget"
                  value={values.budget}
                  onChange={handleChange("budget")}
                  onBlur={handleBlur("budget")}
                />
              </Box>


              <Grid container spacing={2} style={{ padding: "25px 0" }}>
                <Grid md={6} item>
                  <Box className="dateStyle">
                    <Typography className="textStyle">Project dates </Typography>
                    <DatePicker
                      selected={values.startDate}
                      onChange={(date) => setFieldValue('startDate', date)}
                      className={`datepickerStyle`}
                      customInput={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <InputAdornment position="start">
                            <IconButton>
                              <CalendarTodayIcon style={{ fontSize: 20, color: "#000000" }} />
                            </IconButton>
                          </InputAdornment>
                          <input
                            value={values.startDate ? formatDate(values.startDate) : ''}
                            onBlur={handleBlur('startDate')}
                          />
                        </div>
                      }
                    />
                  </Box>
                </Grid>

                <Grid md={6} item>
                  <Box className="dateStyle">
                    <Typography style={{ visibility: 'hidden', marginBottom: '8px' }}> End date</Typography>
                    <DatePicker
                      selected={values.endDate}
                      onChange={(date) => setFieldValue('endDate', date)}
                      className={`datepickerStyle`}
                      customInput={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <InputAdornment position="start">
                            <IconButton >
                              <CalendarTodayIcon style={{ fontSize: 20, color: "#000000" }} />
                            </IconButton>
                          </InputAdornment>
                          <input
                            value={values.endDate ? formatDate(values.endDate) : ''}
                            onBlur={handleBlur('endDate')}
                          />
                        </div>
                      }
                    />
                    
                  </Box>
                </Grid>
              </Grid>

              <Box style={{ marginBottom: "25px" }}>
                <Typography className="textStyle">Project base on:</Typography>
                <Select
                  labelId="profession-label"
                  id="profession-select"
                  name="projectBase"
                  value={values.projectBase}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  error={errors.projectBase && touched.projectBase ? true : false}
                  className="selectStyle"
                  fullWidth
                  IconComponent={
                    arrow
                  }
                >
                  <MenuItem value="Developer">Deliverables</MenuItem>
                  <MenuItem value="Designer">Time</MenuItem>

                </Select>

              </Box>

              <Box>
                <Typography className="attachmentsTitle">Attachments</Typography>
                <AttachmentContainer>
                  {projFiles.map((file:any, index:any) => (
                    <Box className="fileBox" key={index}>
                      <Box className="filePreview">
                        <img src={file.type === "pdf" ? pdfType : imageType} />
                      </Box>
                      <Typography className="fileName">{file.name}</Typography>
                    </Box>
                  ))}

                  <label className="uploadLabel">
                    <input
                      accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                      type="file"
                      onChange={(e) => handleBannerChange(e, "projFiles")}
                      style={{ display: "none" }}
                      data-test-id="fileChangeTextId"
                    />
                    <AddIcon className="addIcon" />
                  </label>
                </AttachmentContainer>
              </Box>

              <Divider style={{ marginTop: 25, marginBottom: 25 }} />

              <Typography className="textStyle" >Role </Typography>


              <Box style={{ marginBottom: "15px" }}>
                <Typography className="textStyle">Role Name </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="CustominputStyle"
                  name="name"
                  value={values.name}
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                 
                />
              </Box>

              <Box style={{ marginBottom: "15px" }}>
                <Typography className="textStyle">Profession </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="CustominputStyle"
                  name="profession"
                  value={values.profession}
                  onChange={handleChange("profession")}
                  onBlur={handleBlur("profession")}
                
                />
              </Box>

              <Box style={{ marginBottom: "15px" }}>
                <Typography className="textStyle">Role Budget </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="CustominputStyle"
                  name="roleBudget"
                  value={values.roleBudget}
                  onChange={handleChange("roleBudget")}
                  onBlur={handleBlur("roleBudget")}
                  
                />
              </Box>

             

              <Grid container spacing={2} style={{ padding: "5px 0", marginBottom:15 }}>
                <Grid md={6} item>
                  <Box className="dateStyle">
                    <Typography className="textStyle">Work Type </Typography>
                    <Select
                      labelId="profession-label"
                      id="profession-select"
                      name="projectBase"
                      value={values.projectBase}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={errors.projectBase && touched.projectBase ? true : false}
                      className="selectStyle"
                      fullWidth
                      IconComponent={
                        arrow
                      }
                    >
                      <MenuItem value="Designer">Full Time</MenuItem>

                    </Select>
                  </Box>
                </Grid>

                <Grid md={6} item>
                  <Box className="dateStyle">
                    <Typography className="textStyle"> Location</Typography>
                    <Select
                      labelId="profession-label"
                      id="profession-select"
                      name="projectBase"
                      value={values.projectBase}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      error={errors.projectBase && touched.projectBase ? true : false}
                      className="selectStyle"
                      fullWidth
                      IconComponent={
                        arrow
                      }
                    >
                      <MenuItem value="Developer">Remote</MenuItem>

                    </Select>

                  </Box>
                </Grid>
              </Grid>

              <Typography className="title" >Add Deliverable
                <img src={plus} />
              </Typography>

              <Divider style={{ marginTop: 25, marginBottom: 25 }} />

              <Typography className="title" >Add Role  <img src={plus} />
              </Typography>

            </StyledDialogContent>
            <DialogActions style={{ padding: "40px 41px" }}>
              <Box>
                <Button
                  variant="outlined"
                  onClick={onClose}
                  style={{ marginRight: "8px" }}
                  className="cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!isValid}
                  variant="contained"
                  className={`yesBtn ${!isValid ? 'disabledButton' : ''}`}
                >
                  Create
                </Button>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledShareChatDial>
  );
};

export default NewProjectForm;