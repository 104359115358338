import { Box, Button, Grid, Theme, Typography, createStyles, makeStyles, Dialog, Avatar, styled } from '@material-ui/core';
import React from 'react';
import { ProjectData, S } from '../PostCreationWebController'
import { visibilityOn, visibilityOff, blackClose } from "../assets";
import {returnTruthyString, getSurname } from "../../../../components/src/HelperUtils";
import { CustomTooltip, StyledEyeIcon } from '../ListPost.web';

const ProfileAvatar = styled(Avatar)({
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  "& .MuiAvatar-img": {
    objectFit: "fill",
  }
});

const StyledPreviewDialog = styled(Dialog)(({theme}: {theme: Theme}) => ({
  "& .postImgDivs":{
    maxWidth: "1141px",
    height: "584px",
    objectFit:"cover",
    position: "relative",
    [theme.breakpoints.down("sm")]:{
      height: "189px"
    },
  },
  "& .hiddenImage":{
    position: "absolute",
    top: 0,
    left: 0, 
    width: "100%",
    height: "100%",
    backgroundColor: "#0d0c22",
    opacity: "0.6",
    borderRadius: "4px"
  },
  "& .imageStyle": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "4px"
  },
  "& .mainDiv":{
    marginTop: "42px"
  },
  "& .otherImage":{
    marginBottom: "20px"
  },
  "& .profileDivMob":{
    display: "flex",
    [theme.breakpoints.down("sm")]:{
      padding: "24px 24.5px 0px 24.5px",
    },
    gap: "40px",
    position: "relative"
  },
  "& .userNameDiv": {
    display: "flex",
    justifyContent: "right",
    flexFlow: "column",
  },
  "& .describtionInterior":{
    fontSize: "14px",
    color: "#121212",
    fontWeight: 500,
    marginBottom: "12px",
    fontStyle: "normal",
    fontFamily: "Silika-Medium",
    lineHeight: "140.625%",
  },
  "& .profileimgContainer": {
    maxWidth: "100px",
  },
  "& .userNameInterior":{
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#64748B",
    lineHeight: "normal",
    fontFamily: "Silika-Medium",
  },
  "& .descBoxMob": {
    padding: "40px 7.5px",
    wordWrap: "break-word",
    "& p":{
      fontSize: "14px",
      fontFamily: "Silika-Light",
      lineHeight: "16.88px",
      color: "#000000",
      whiteSpace: 'pre-wrap' as 'pre-wrap'
    }
  },
  "& .mainContainer":{
    border: "1px solid #D9D9D9",
    backgroundColor : "white",
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexFlow: "column",
    padding: "90px 44px 0 44px",
    [theme.breakpoints.down("sm")]:{
      padding: "25px 0px 0px",
    },
  },
  "& .options":{
    display: "flex",
    alignItems: "center",
    position: "absolute" as "absolute",
    right: 0,
    [theme.breakpoints.down("sm")]:{
      right: "24.5px"
    },
  },
  "& .closeIcon":{
    height: "24px",
    width: "24px",
    "& path":{
      fill: "#010101"
    }
  },
}))

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalBackdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        imageStyle: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
        buttonOutline: {
            borderRadius: "2px",
            borderColor: "black",
            float: "right",
            textDecoration: "none",
            textTransform: "none"
        },
        buttonFilled: {
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
            float: "right",
            textDecoration: "none", textTransform: "none",
            marginLeft: "16px"
        },
        buttonClass: {
            width: "100%",
            padding: "41px 0px 41px 0",
            "& button":{
              fontFamily: "Silika-Medium"
            }
        },
        projectTitle: {
            fontFamily: "Silika-Medium",
            fontSize: "20px",
            fontWeight: 500,
            padding: "20px"
        },
          profileImgStyle : {
            width: "68px",
            height: "68px",
            [theme.breakpoints.down('xs')]: {
              width: "40px",
              height: "40px",
            },
          }
    })
);
type Props = {
    state: S
    updatePost: (value: ProjectData, isEdited:boolean) => void
    handlePreviewClose : ()=>void
    isEdited:boolean
    profileImage:any
    handleSetVisiblePreview : (id : number, is_visible : boolean, index: number) =>void
}

function Preview(this: any, props: Props) {
    const { state, updatePost, handlePreviewClose, isEdited, profileImage, handleSetVisiblePreview } = props
    const classes = useStyles();
    const post = state.postData.attributes
    const avatarName = returnTruthyString(post?.profile?.data.attributes?.first_name[0]) + returnTruthyString(post.profile.data.attributes.last_name[0]);
    const showImagesVideos = (image:any, index:number, extraClass: string) =>{
      return(
        <div data-test-id="post-img-div" className={'postImgDivs ' + extraClass}>
        {!state.savedValues.images[index]?.is_visible && <span className="hiddenImage" />}
        {image.type.includes("image") ? (
          <img
          className="imageStyle"
          src={URL.createObjectURL(image)}
        />
        ): <video className="imageStyle" controls>
        <source
          src={URL.createObjectURL(image)}
          type="video/mp4"
        />
          </video>}
            {renderVisibilityIcons(state.savedValues.images[index]?.is_visible, () => handleSetVisiblePreview(state.savedValues.images[index].id, false, index), () => handleSetVisiblePreview(state.savedValues.images[index].id, true, index))}
        </div>
      )
    } 

    const renderVisibilityIcons = (isVisible: boolean, onClickVisibilityOffHandler: () => void, onClickVisibilityOnHandler: () => void) => {
      const visibleImages = state.savedValues.images.filter(image => image.is_visible === true);
      const hasVisibleImages = visibleImages.length > 1;
      if(isVisible){
        if(hasVisibleImages){
        return (
          <CustomTooltip title="Visible in gallery" placement="bottom-end">
          <StyledEyeIcon data-test-id="eyeOpen"
          onClick={() => onClickVisibilityOffHandler()}
          ><img
            src={visibilityOn}
            alt="eyeOpen"
          />
          </StyledEyeIcon>
          </CustomTooltip>
        )
        }
      }else{
        return <CustomTooltip title="Not visible in gallery" placement="bottom-end">
        <StyledEyeIcon data-test-id="eyeClose"
        onClick={() => onClickVisibilityOnHandler()}
        ><img
          src={visibilityOff}
          alt="eyeClose"
        />
        </StyledEyeIcon> 
        </CustomTooltip>
      }
    }

    return (
        <StyledPreviewDialog
      open={state.displayPreview}
      onClose={handlePreviewClose}
      PaperProps={{
        style: { backgroundColor: 'transparent', boxShadow: 'none', maxWidth: "1200px" },
      }}
      BackdropProps={{
        classes: {
          root: classes.modalBackdrop,
        },
      }}
    >
        <div className="mainContainer">
          <div className="profileDivMob">
            <div className="profileimgContainer">
              <ProfileAvatar src={profileImage} className={classes.profileImgStyle}>
                {avatarName}
              </ProfileAvatar>
            </div>
            <div className="userNameDiv">
                <Typography className="describtionInterior">{state.savedValues.projectTitle}</Typography>
                <Typography className="userNameInterior">
                {post.profile.data.attributes.first_name + " " + getSurname(post.profile.data.attributes.last_name, post.account_id.toString())}
                </Typography>
            </div>
            <div className="options">
            <img src={blackClose} alt="blackClose" className="closeIcon"  onClick={handlePreviewClose}
                  data-test-id="ProfileInfoPhoto"/>
          </div>
          </div>
          { (
            <>
             {state.uploadMultipleImagesAPI.length > 0 && showImagesVideos(state.uploadMultipleImagesAPI[0].file, 0, "mainDiv")}
            </>
            )}
            <div className="descBoxMob">
              <Typography>
                {state.savedValues.description}
              </Typography>
            </div>
            <>
            {state.uploadMultipleImagesAPI.slice(1)?.map((srcImage, index) => (
                    showImagesVideos(srcImage.file, index+1, "otherImage")
            )
            )}
            <Box className={classes.buttonClass}>
                <Button variant="contained" id='publishPost' className={classes.buttonFilled}  type="submit" onClick={() => updatePost(state.savedValues, isEdited)}>Publish</Button>
                <Button variant="outlined" className={classes.buttonOutline} onClick={handlePreviewClose} >Cancel</Button>
            </Box>
            </>
        </div>
        </StyledPreviewDialog>
    )
}


export default Preview
