import React from 'react'
import { Typography, Button, Table, TableCell, TableRow, TableBody, TableHead, TableContainer} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';

import {  createTheme, styled } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });
export default function PlanCardAndTable() {
    const subscriptionPlans = [
        {
            "id": "1",
            "type": "subscription_plan",
            "attributes": {
                "plan_name": "Free",
                "amount": 0,
                "currency": "£",
                "duration": "per month",
                "is_archive": false,
                "description": "Perfect plan for Starters",
                "features": [
                    {
                        "id": 7,
                        "title": "Portfolio page",
                        "subscription_plan_feature_category_id": 3,
                        "created_at": "2024-09-21T05:05:01.448Z",
                        "updated_at": "2024-09-21T05:05:01.448Z"
                    },
                    {
                        "id": 8,
                        "title": "Opportunities feed",
                        "subscription_plan_feature_category_id": 6,
                        "created_at": "2024-09-21T05:05:44.867Z",
                        "updated_at": "2024-09-21T05:05:44.867Z"
                    },
                    {
                        "id": 9,
                        "title": "Opportunities feed",
                        "subscription_plan_feature_category_id": 4,
                        "created_at": "2024-09-21T05:06:12.705Z",
                        "updated_at": "2024-09-21T05:06:12.705Z"
                    }
                ],
                "created_at": "2024-09-18T08:50:55.988Z"
            }
        },
        {
            "id": "1",
            "type": "subscription_plan",
            "attributes": {
                "plan_name": "Free",
                "amount": 0,
                "currency": "£",
                "duration": "per month",
                "is_archive": false,
                "description": "Perfect plan for Starters",
                "features": [
                    {
                        "id": 7,
                        "title": "Portfolio page",
                        "subscription_plan_feature_category_id": 3,
                        "created_at": "2024-09-21T05:05:01.448Z",
                        "updated_at": "2024-09-21T05:05:01.448Z"
                    },
                    {
                        "id": 8,
                        "title": "Opportunities feed",
                        "subscription_plan_feature_category_id": 6,
                        "created_at": "2024-09-21T05:05:44.867Z",
                        "updated_at": "2024-09-21T05:05:44.867Z"
                    },
                    {
                        "id": 9,
                        "title": "Opportunities feed",
                        "subscription_plan_feature_category_id": 4,
                        "created_at": "2024-09-21T05:06:12.705Z",
                        "updated_at": "2024-09-21T05:06:12.705Z"
                    }
                ],
                "created_at": "2024-09-18T08:50:55.988Z"
            }
        },
        {
            "id": "26",
            "type": "subscription_plan",
            "attributes": {
                "plan_name": "Freelance",
                "amount": 12.0,
                "currency": "£",
                "duration": "per months ",
                "is_archive": true,
                "description": "Work on projects professionally. ",
                "features": [
                    {
                        "id": 1,
                        "title": "Can initiate messages",
                        "subscription_plan_feature_category_id": 1,
                        "created_at": "2024-09-16T04:29:11.535Z",
                        "updated_at": "2024-09-16T04:29:11.535Z"
                    },
                    {
                        "id": 2,
                        "title": "Apply to opportunities",
                        "subscription_plan_feature_category_id": 1,
                        "created_at": "2024-09-16T04:29:21.324Z",
                        "updated_at": "2024-09-16T04:29:21.324Z"
                    },
                    {
                        "id": 3,
                        "title": "Create events",
                        "subscription_plan_feature_category_id": 1,
                        "created_at": "2024-09-16T05:27:49.881Z",
                        "updated_at": "2024-09-16T05:27:49.881Z"
                    },
                    {
                        "id": 5,
                        "title": "Opportunities feed",
                        "subscription_plan_feature_category_id": 6,
                        "created_at": "2024-09-18T08:33:30.044Z",
                        "updated_at": "2024-09-18T08:33:30.044Z"
                    },
                    {
                        "id": 6,
                        "title": "View and search events",
                        "subscription_plan_feature_category_id": 4,
                        "created_at": "2024-09-18T08:34:23.539Z",
                        "updated_at": "2024-09-18T08:35:44.742Z"
                    }
                ],
                "created_at": "2024-09-18T08:36:31.515Z"
            }
        },
        {
            "id": "26",
            "type": "subscription_plan",
            "attributes": {
                "plan_name": "Studio",
                "amount": 12.0,
                "currency": "£",
                "duration": "per months ",
                "is_archive": true,
                "description": "Build teams and work on projects.",
                "features": [
                    {
                        "id": 1,
                        "title": "Boost your profile",
                        "subscription_plan_feature_category_id": 1,
                        "created_at": "2024-09-16T04:29:11.535Z",
                        "updated_at": "2024-09-16T04:29:11.535Z"
                    },
                    {
                        "id": 2,
                        "title": "Shortlist designers",
                        "subscription_plan_feature_category_id": 1,
                        "created_at": "2024-09-16T04:29:21.324Z",
                        "updated_at": "2024-09-16T04:29:21.324Z"
                    },
                    {
                        "id": 3,
                        "title": "Payment management",
                        "subscription_plan_feature_category_id": 1,
                        "created_at": "2024-09-16T05:27:49.881Z",
                        "updated_at": "2024-09-16T05:27:49.881Z"
                    },
                ],
                "created_at": "2024-09-18T08:36:31.515Z"
            }
        }
      ]
      const subscriptionFeatureCategories = [
        {
            "id": "1",
            "type": "subscription_plan_feature_category",
            "attributes": {
                "name": "Profile management",
                "created_at": "2024-09-16T04:27:57.609Z",
                "updated_at": "2024-09-16T04:27:57.609Z"
            }
        },
        {
            "id": "2",
            "type": "subscription_plan_feature_category",
            "attributes": {
                "name": "Search",
                "created_at": "2024-09-16T04:28:09.505Z",
                "updated_at": "2024-09-16T04:28:09.505Z"
            }
        },
        {
            "id": "3",
            "type": "subscription_plan_feature_category",
            "attributes": {
                "name": "Messaging \u0026 Interaction",
                "created_at": "2024-09-16T04:28:17.521Z",
                "updated_at": "2024-09-16T04:28:17.521Z"
            }
        },
        {
            "id": "4",
            "type": "subscription_plan_feature_category",
            "attributes": {
                "name": "Events",
                "created_at": "2024-09-16T04:28:26.383Z",
                "updated_at": "2024-09-16T04:28:26.383Z"
            }
        },
        {
            "id": "5",
            "type": "subscription_plan_feature_category",
            "attributes": {
                "name": "Content",
                "created_at": "2024-09-16T04:28:33.386Z",
                "updated_at": "2024-09-16T04:28:33.386Z"
            }
        },
        {
            "id": "6",
            "type": "subscription_plan_feature_category",
            "attributes": {
                "name": "Opportunities",
                "created_at": "2024-09-16T04:28:41.704Z",
                "updated_at": "2024-09-16T04:28:41.704Z"
            }
        },
        {
            "id": "7",
            "type": "subscription_plan_feature_category",
            "attributes": {
                "name": "Account Permissions",
                "created_at": "2024-09-16T04:28:53.274Z",
                "updated_at": "2024-09-16T04:28:53.274Z"
            }
        }
      ]
      const subscriptionFeatures = [
        {
            "id": "1",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Subscribe and manage subscription",
                "created_at": "2024-09-16T04:29:11.535Z",
                "updated_at": "2024-09-16T04:29:11.535Z",
                "feature_category": {
                    "id": 1,
                    "name": "Profile management",
                    "created_at": "2024-09-16T04:27:57.609Z",
                    "updated_at": "2024-09-16T04:27:57.609Z"
                }
            }
        },
        {
            "id": "2",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Portfolio page management",
                "created_at": "2024-09-16T04:29:21.324Z",
                "updated_at": "2024-09-16T04:29:21.324Z",
                "feature_category": {
                    "id": 1,
                    "name": "Profile management",
                    "created_at": "2024-09-16T04:27:57.609Z",
                    "updated_at": "2024-09-16T04:27:57.609Z"
                }
            }
        },
        {
            "id": "3",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Portfolio page",
                "created_at": "2024-09-16T05:27:49.881Z",
                "updated_at": "2024-09-16T05:27:49.881Z",
                "feature_category": {
                    "id": 1,
                    "name": "Profile management",
                    "created_at": "2024-09-16T04:27:57.609Z",
                    "updated_at": "2024-09-16T04:27:57.609Z"
                }
            }
        },
        {
            "id": "4",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Tagging/crediting other collaborators",
                "created_at": "2024-09-16T05:28:43.477Z",
                "updated_at": "2024-09-16T05:28:43.477Z",
                "feature_category": {
                    "id": 1,
                    "name": "Profile management",
                    "created_at": "2024-09-16T04:27:57.609Z",
                    "updated_at": "2024-09-16T04:27:57.609Z"
                }
            }
        },
        {
            "id": "5",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Opportunities feed",
                "created_at": "2024-09-18T08:33:30.044Z",
                "updated_at": "2024-09-18T08:33:30.044Z",
                "feature_category": {
                    "id": 6,
                    "name": "Opportunities",
                    "created_at": "2024-09-16T04:28:41.704Z",
                    "updated_at": "2024-09-16T04:28:41.704Z"
                }
            }
        },
        {
            "id": "6",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "View and search events",
                "created_at": "2024-09-18T08:34:23.539Z",
                "updated_at": "2024-09-18T08:35:44.742Z",
                "feature_category": {
                    "id": 4,
                    "name": "Events",
                    "created_at": "2024-09-16T04:28:26.383Z",
                    "updated_at": "2024-09-16T04:28:26.383Z"
                }
            }
        },
        {
            "id": "7",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Can initiate messages",
                "created_at": "2024-09-21T05:05:01.448Z",
                "updated_at": "2024-09-21T05:05:01.448Z",
                "feature_category": {
                    "id": 3,
                    "name": "Messaging \u0026 Interaction",
                    "created_at": "2024-09-16T04:28:17.521Z",
                    "updated_at": "2024-09-16T04:28:17.521Z"
                }
            }
        },
        {
            "id": "8",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Apply to opportunities",
                "created_at": "2024-09-21T05:05:44.867Z",
                "updated_at": "2024-09-21T05:05:44.867Z",
                "feature_category": {
                    "id": 6,
                    "name": "Opportunities",
                    "created_at": "2024-09-16T04:28:41.704Z",
                    "updated_at": "2024-09-16T04:28:41.704Z"
                }
            }
        },
        {
            "id": "9",
            "type": "subscription_plan_feature",
            "attributes": {
                "title": "Create events",
                "created_at": "2024-09-21T05:06:12.705Z",
                "updated_at": "2024-09-21T05:06:12.705Z",
                "feature_category": {
                    "id": 4,
                    "name": "Events",
                    "created_at": "2024-09-16T04:28:26.383Z",
                    "updated_at": "2024-09-16T04:28:26.383Z"
                }
            }
        }
      ]

    const StyledCard = styled("div")({
    border: "0.78px solid #DFDFDF",
    padding: "24px",
    width: "198px",
    background: "#fff",
    marginRight: "12px",
    marginBottom: "12px",
    display: "flex",
    flexFlow: "column",
    borderRadius: "4px",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      marginRight: "0px",
      width: "100%",
      marginBottom: "24px",
      boxShadow: "0px 2px 8px 0px #00000014",
      transition: "boxShadow 0.6s ease",
      "& .actionBtn": {
        background: "#0D0C22 !important",
        color: "#fff",
        border: "1px solid #0D0C22",
        transition: "background-color 0.6s ease, color 0.7s ease",
        height: "44px",
        width: "100%",
        }
      },
      "& .title": {
        fontSize: "18.72px",
        fontWeight: 400,
        lineHeight: "24.96px",
        letterSpacing: " -0.09359999746084213px",
        marginBottom: "8px"
      },
      "& .description": {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18.72px",
        fontFamily: "Silika-Light",
        color: "#010101",
      },
    "& .price": {
      marginTop: "15.13px",
      fontSize: "37.44px",
      lineHeight: "43.68px",
      display: "block"
    },
    "& .perMonth": {
      fontSize: "12px",
      lineHeight: "18.72px",
      display: "block",
      fontFamily: "Silika-Light",
      fontWeight: 400,
      color: "#9B9B9B"
    },
    "& .groupFeatures": {
      margin: "38px 0"
    },
    "& .groupFeature": {
      display: "inline-flex",
      width: "100%",
      alignItems: "center",
      height: "19px",
      marginBottom: "8px",
      "& svg": {
        color: "#00FFA7",
        height: "16px"
      }
    },
    "& .groupFeatureName": {
      fontSize: "12px",
      lineHeight: "18.72px",
      marginLeft: "8px",
      fontFamily: "Silika-Light",
      fontWeight: 300,
      color: "#010101"
  
    },
    "& .actionBtn": {
        width: "184.08px",
        height: "43.68px",
        gap: '6.24px',
        borderRadius: '2px',
        lineHeight: '18.72px',
        border: "0.39px solid #DFDFDF",
        textTransform: "none",
        fontSize: "12px",
        marginBottom: "18px",
        fontWeight: 500,
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]: {
          marginRight: "0px",
          width: "100%",
          },
      },
    "& .cancelBtn": {
      background: "none",
      border: "none",
      fontSize: "10px",
      lineHeight: "18.72px",
      padding: "0px",
      fontFamily: "Silika-Light",
      fontWeight: 300,
      color: "#010101"
    }
  });
  
  const StyledCardList = styled("div")({
    display: "inline-flex",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      width: "calc(100vw - 50px)",
      margin: "0 auto"
    }
  });
  const StyledTableContainer = styled(TableContainer)({
    boxShadow: 'none',
    border: `1px solid #DFDFDF`,
    marginTop: "38px",
    borderBottom: "0px",
    [theme.breakpoints.down('sm')]: {
      margin: "0 auto",
      width: "calc(100vw - 50px)"
    }
  });
  const StyledTableHead = styled(TableHead)({
    backgroundColor: '#f8f8f8',
    borderBottom: "0px",
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
      fontSize: '14px',
      padding: '12px',
      borderBottom: '2px solid #ddd',
      color: '#333',
    },
    "& .tableCellEmpty": {
      width: "296px",
      [theme.breakpoints.down('sm')]: {
        width: "126px"
      }
    },
    "& .tablecell": {
      padding: "16px 28px 12px 28px",
      width: "98px",
      borderRight: "0.5px solid #DFDFDF",
      borderLeft: "0.5px solid #DFDFDF",
      height: "100%",
      "&:last-child": {
        borderRight: "0px",
      },
      [theme.breakpoints.down('sm')]: {
        padding: "10px 5px",
        height: "127px",
        width: "12%"
      }
    },
    "& .headTitle": {
      display: "flex",
      flexFlow: "column",
      height: "100%",
      alignItems: "center",
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        writingMode: "sideways-lr",
        textOrientation: "mixed",
        width: "max-content !important",
        alignItems: "baseline",
        "@supports (-webkit-appearance: none)": {
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
          alignItems: "flex-start"
        }
      },
      "& p": {
        borderRadius: '3.12px',
        fontWeight: 500,
        background: "#0D0C22",
        color: "#fff",
        fontSize: "12",
        lineHeight: "18.72px",
        fontFamily: "Silika-Medium",
        width: "98px",
        height: "28px",
        marginTop: "8px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
          margin: "0",
          padding: "0 0.5px",
          height: "94px",
          textAlign: "center",
          borderRadius: "59px"
        }
      },
      "& span": {
        letterSpacing: "-0.09359999746084213px",
        fontWeight: 500,
        marginTop: "0px",
        marginBottom: "auto",
        textAlign: "center",
        fontSize: "18.72px",
        lineHeight: "24.96px",
        [theme.breakpoints.down('sm')]: {
          margin: "0",
          lineHeight: "14px",
          marginRight: "4px"
        }
      }
    }
  });
  const StyledTableBody = styled(TableBody)({
    '& .MuiTableCell-root': {
      padding: '14.5px 12px',
      borderBottom: `0.5px solid #DFDFDF`,
      borderRight: "0.5px solid #DFDFDF",
      fontSize: '14px',
      color: '#010101',
      fontFamily: "Silika-Light",
      lineHeight: "18.72px",
      [theme.breakpoints.down('sm')]: {
        padding: "8px 12px"
      }
    },
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    "& .tickMark": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& svg": {
        color: "#00FFA7"
      }
    },
    "& .titleBar": {
      "& td": {
        background: "#DFDFDF",
        fontFamily: "Silika-Medium",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: '22px',
      }
    }
  });
  return (
    <div>
              <div>
        <StyledCardList className="subscriptionCardsList">
                  {
                    subscriptionPlans.length > 0 &&
                    subscriptionPlans.map((subscription) => {
                      const title = subscription.attributes.plan_name;
                      const description = subscription.attributes.description;
                      const currency = subscription.attributes.currency;
                      const duration = subscription.attributes.duration;
                      const amount = subscription.attributes.amount;
                      const features = subscription.attributes.features.slice(0, 3).map((f) => f.title);
                      const actionBtntring = `Get ${title}`;
                      return (
                        <StyledCard data-test-id="subscriptionCard">
                          <div className="top">
                            <Typography className="title">{title}</Typography>
                            <Typography className="description">{description}</Typography>
                          </div>
                          <div className="bottom">
                            <span className="price">
                              {currency} {amount}
                            </span>
                            <span className="perMonth">
                              {duration}
                            </span>
                            <div className="groupFeatures">
                              {features.length > 0 ? features.map(feature => <div className="groupFeature">
                                <CheckIcon />
                                <span className="groupFeatureName">{feature}</span>
                              </div>) : <></>}
                            </div>
                            <Button className="actionBtn">
                              {actionBtntring}
                            </Button>
                            <Typography className="cancelBtn" component="button">Cancel anytime. No strings attached.</Typography>
                          </div>
                        </StyledCard>
                      )
                    })
                  }
                </StyledCardList>
  
                <StyledTableContainer data-test-id="subscriptionCardsContainer">
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell className="tableCellEmpty"></TableCell>
                        {subscriptionPlans.map((subscription) =>
                          <TableCell className="tablecell">
                            <div className="headTitle">
                              <span>{subscription.attributes.plan_name}</span>
                              <Typography>Get it Now</Typography>
                            </div>
                          </TableCell>)}
                      </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                    {subscriptionFeatureCategories?.map((sCategory) => {
                      let categoryId = sCategory.id;
                      let categoryFeatures = subscriptionFeatures.length > 0 ? subscriptionFeatures.filter((sFeature) => sFeature.attributes.feature_category.id.toString() == categoryId) : [];
                      if (categoryFeatures.length > 0) {
                        return (
                          <>
                            <TableRow key={-1} className="titleBar">
                              <TableCell colSpan={5}>{sCategory.attributes.name}</TableCell>
                            </TableRow>
                            {
                              categoryFeatures.map((feature) => (
                                <TableRow key={feature.id}>
                                  <TableCell>{feature.attributes.title}</TableCell>
                                  {subscriptionPlans && subscriptionPlans.length > 0 ? subscriptionPlans.map((sPlan) => {
                                    const sFeatureIds = sPlan.attributes.features.filter((f) => f.subscription_plan_feature_category_id.toString() === categoryId).map((f) => f.id.toString());
                                    return <TableCell>
                                      <div className="tickMark">
                                        {sFeatureIds.includes(feature.id) ? <CheckIcon /> : <></>}
                                      </div>
                                    </TableCell>
                                  }) : <></>}
                                        </TableRow>))
                                          }
                                      </>
                                  )
                              }
                              return <></>
                          })}
                    </StyledTableBody>
                  </Table>
                </StyledTableContainer>
      </div>
    </div>
  )
}
