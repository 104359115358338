import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import LoginSignUp, { webStyle , StyledButton, AccountStatement, StyledTitle, BorderOr, StyledTextField} from "./LoginSignUp.web";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CommonDialog from "../../../components/src/CommonDialog.web";

const validationSchema = Yup.object().shape({
  email: Yup.string().email('This email is not valid').required('Email is required'),
  password: Yup.string()
    .required("Password is required"),
});

// Customizable Area End

import AccountLoginController, { Props } from "./AccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AccountLogin extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div 
        style={{overflow:'hidden'}}>
<LoginSignUp navigation={this.props.navigation} id={""}>
          <Formik
            initialValues={{
              email: this.state.signInFormData.email,
              password: this.state.signInFormData.password,
            }}
            data-test-id="formikForm"
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            isInitialValid={false}
          >
            {({ isValid, touched, values, errors, handleSubmit, handleChange, handleBlur }) => {
               return (
                <form autoComplete="off" onSubmit={handleSubmit} >
                  <StyledTitle className="textCenter marginBottom48">Log In</StyledTitle>
                  <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style = {{ display : "flex", alignItems : "center", flexDirection : "column"}}>
                    <StyledTextField
                      className="marginBottom16"
                      fullWidth
                      data-test-id={"txtEmail"}
                      name={"email"}
                      error={(touched.email && !!errors.email)||!!this.state.pswrdEmailError}
                      helperText={(touched.email  && errors.email)||this.state.pswrdEmailError}
                      placeholder="Email"
                      variant="outlined"
                      inputProps={{ maxLength: 100 }}
                      value={values.email}
                      onChange={handleChange}
                      InputProps={{
                        classes:{
                          error: "formError"
                        }
                      }}
                      onBlur={handleBlur}
                      FormHelperTextProps={{
                        style: {
                          marginLeft:0,
                        },
                        classes: {
                          error: "formHelperErrorText"
                        }
                      }}
                    />
                    <StyledTextField
                    className="marginBottom14"
                      fullWidth
                      variant="outlined"
                      type={this.state.showPassword ? "text" : "password"}
                      value={values.password}
                      placeholder="Password"
                      data-test-id={"txtPassword"}
                      name="password"
                      error={(touched.password && !!errors.password)||!!this.state.errorMsg}
                      helperText={(touched.password && errors.password)||this.state.errorMsg}
                      autoComplete='new-password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      FormHelperTextProps={{
                        style: {
                          marginLeft:0,
                        },
                        classes: {
                          error: "formHelperErrorText"
                        }
                      }}
                      InputProps={{
                        classes:{
                          error: "formError"
                        },
                        endAdornment: !this.state.showPassword ? (
                          <VisibilityOffIcon
                            style={webStyle.passwordVisibilityOffIcon}
                            onClick={this.handlePassShow}
                          />
                        ) : (
                          <VisibilityIcon
                            style={webStyle.passwordVisibilityOnIcon}
                            onClick={this.handlePassShow}
                          />
                        ),
                      }}
                    />
                    <Typography
                      align="right"
                      className="forgotPasswordLink"
                      onClick={() => {
                       this.handleNavigate()
                      }}
                      style={webStyle.isForgotPassword}
                    >
                      Forgot Password?
                    </Typography>
                    <StyledButton
                      disabled={!isValid}
                      type="submit"
                      data-test-id="submitBtn"
                      className={isValid ? "" : "disabledButton"}
                    >
                      Log In
                    </StyledButton>
  
                  <div style={webStyle.borderContainer}>
                    <div>
                      <BorderOr />
                    </div>
                    <Typography style={webStyle.or}>or</Typography>
                    <div>
                      <BorderOr />
                    </div>
                  </div>
  
                  <div style={webStyle.isAccountDiv}>
                    <AccountStatement>
                      <Typography>
                        Don't have an account?
                      </Typography>
                    </AccountStatement>
                    <span
                      onClick={() => {
                       this.handleSignUpClick();
                      }}
                      style={{...webStyle.signUplink,fontFamily:'Silika-Medium'}}
                    >
                      Sign Up
                    </span>
                  </div>
                  
                  </Box>
  
                </form>
              )
            }}
          </Formik>
        </LoginSignUp>
    </div>
      <CommonDialog
        title="Account Reactivated"
        description="Thank you, your account has now been reactivated."
        onClose={this.handleOk}
        onHandleOk={this.handleOk}
        okText="Ok"
        hasCloseButton={false}
        open={this.state.isAccountReactivated}
        isOkdisabled={false}
        data-test-id="reactivated"
      />
      <CommonDialog
        title="Reopen Account"
        description="If you continue we will re-active your subscription."
        onClose={this.handleClose}
        onCancelClick={this.handleClose}
        onSuccessClick={this.handleReactivateAccount}
        open={this.state.accountRecoveryModalOpen}
        cancelText="Cancel"
        successText="Ok"
        hasCloseButton={false}
        data-test-id="reopen"
      />
      <CommonDialog 
        open={this.state.isEmailUpdated}
        title="Change email"
        description={<span>Thank you, your email has been updated.</span>}
        onClose={this.handleEmailUpdated}
        onHandleOk={this.handleEmailUpdated}
        okText="Ok"
        hasCloseButton={false}
        data-test-id="emailUpdatedDialog"
      />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
