import React from "react";

import {
    Box,
    Grid, Typography, Button, createStyles, createTheme, makeStyles, Theme, ThemeProvider
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { S } from "../TermsAndConditionWebController";
// import {accountLight,visibilityLight,termsLight,projectLight,notificationLight,accountDark,visibilityDark,termsDark,projectDark,notificationDark} from "../../assets"
// Customizable Area Start

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },

    },
});
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridClass: {
            height: "75vh",
            overflowY: "scroll",
            marginTop: "20px",
            marginLeft: "25px"
        },
        textStyles: {
            color: "#010101",
            fontFamily: "Silika-Light",
            fontSize: "14px",
            fontStyle: "normal",
        },
        TermsHeading: {
            fontFamily: "Silika-Bold",
            fontSize: "18px",

        },
        buttonClass: {
            backgroundColor: "#0D0C22 !important",
            borderRadius: "2px !important",
            color: "white !important",
            right: "0px",
            textTransform: 'none',

        },
        containerClass: {
            display: "flex",
            justifyContent: "flex-end"
        },
        leftPadding: {

        },
        mainGrid: {
            padding: "4%",
            backgroundColor: "#FFF",
            boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
        },
        headingGrid: {
            paddingTop: "10%"
        },
        subGrid: {
            padding: "4% 10%",
            width: "100%",
            '&:hover': {
                cursor: "pointer"
            },
        },
        selectedStyle: {
            padding: "4% 10%",
            backgroundColor: "black",
            width: "100%",
            '&:hover': {
                cursor: "pointer"
            },
        },
        selectedMainText: {
            color: "white",
            fontFamily: "Silka",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            paddingLeft: "3%"
        },
        selectedSubText: {
            color: "white",
            fontFamily: "Silka",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 300,
            marginTop: "4%",
            paddingLeft: "12%"
        },
        notSelectedMainText: {
            color: "black",
            fontFamily: "Silka",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            paddingLeft: "3%"

        },
        notSelectedSubText: {
            color: "#9B9B9D",
            fontFamily: "Silka",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 300,
            marginTop: "4%",
            paddingLeft: "12%"

        },

        headingText: {
            color: "black",
            fontFamily: "Silka",
            fontSize: "32px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            paddingLeft: "10%"
        },
        iconGrid: {
            display: "flex",
            marginBottom: "5%"
        }
    })
);
// Customizable Area End

interface Props {
    state: S
}


export function Terms(props: Props) {


    // Customizable Area Start
    // Customizable Area End
    const { state } = props
    let classes = useStyles()
    const listData = [{
        heading: "Account & Billing",
        descripton: "Settings related to your personal information and account credentials.",
        // icon: accountLight,
        // icon1: accountDark

    }, {
        heading: "Visiblity & Privacy",
        descripton: "Control how your information is seen by others.",
        // icon: visibilityLight,
        // icon1: visibilityDark
    }, {
        heading: "Terms & Conditions",
        descripton: "Privacy Policy and Terms of Use.              ",
        // icon: termsLight,
        // icon1: termsDark
    }, {
        heading: "Project Working",
        descripton: "Define your hourly rate and other project settings.",
        // icon: projectLight,
        // icon1: projectDark
    }, {
        heading: "Notifications",
        descripton: "All settings related to notifications.",
        // icon: notificationLight,
        // icon1: notificationDark
    }
    ]
    return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
            <Grid item container spacing={2}>
                <Grid item xs={3} >
                    <Grid container className={classes.mainGrid}>
                        <Typography className={classes.headingText}>Settings</Typography>
                        <Grid item container className={classes.headingGrid}>
                            {listData.map((item, index) => {
                                return <Grid item className={item.descripton == "Terms & Conditions" ? classes.selectedStyle : classes.subGrid}>
                                    <Grid item className={classes.iconGrid}>
                                        {/* <img src={item == "Terms & Conditions" ? item.icon : item.icon1}></img> */}
                                        <Typography className={item.descripton == "Terms & Conditions" ? classes.selectedMainText : classes.notSelectedMainText}>{item.heading}</Typography>
                                    </Grid>
                                    <Typography className={item.descripton == "Terms & Conditions" ? classes.selectedSubText : classes.notSelectedSubText}>{item.descripton}</Typography>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9} className={classes.leftPadding} >
                    <Grid sm={7} className={classes.gridClass}>
                        <Typography className={classes.TermsHeading}>Terms and Conditions</Typography>
                        <Typography className={classes.textStyles} style={{ marginTop: "20px" }} dangerouslySetInnerHTML={{ __html: state.termsAndcondition }}>
                        </Typography>
                        <br />
                        <Box className={classes.containerClass}>

                            <Button className={classes.buttonClass}>Agree</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
        // Customizable Area End
    );
}



export default Terms