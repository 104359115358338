import React, { Component } from 'react'
import {
    Box,
    Button,
    Dialog,
    Typography,
    styled,
    DialogTitle,
    DialogContent
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    navigation?: {};
    open: boolean;
    description: string
    leftButtonTitle: string;
    rightButtonTitle: string;
    handleLeftBtn: () => void;
    handleRightBtn: () => void;
    handleCloseModal: () => void
}

interface S { }

export default class CustomModal extends Component<Props, S> {
    constructor(props: Props) {
        super(props);

        this.state = {
        };
    }
    render() {
        const {
            open,
            description,
            leftButtonTitle,
            rightButtonTitle,
            handleLeftBtn,
            handleRightBtn,
            handleCloseModal
        } = this.props

        return (
            <>
                {open ?
                    <DialogWrapper
                        open={open}
                        className='custom-modal'
                        BackdropProps={{
                            className: "backdrop-class"
                        }}
                        PaperProps={{
                            style: { maxWidth: "548px" }
                        }}
                        aria-labelledby="custom-dialog-title"
                        aria-describedby="custom-dialog-description"
                    >
                        <DialogHeaderWrapper id="custom-dialog-title">
                            <CloseBtn onClick={handleCloseModal} />
                        </DialogHeaderWrapper>
                        <DialogContentWrapper>
                            <DescriptionText>{description}</DescriptionText>
                        </DialogContentWrapper>
                        <ButtonsWrapper>
                            <Button id="left-btn"
                                onClick={handleLeftBtn}
                                style={style.leftBtn}
                            >
                                {leftButtonTitle}
                            </Button>
                            <Button id="right-btn"
                                onClick={handleRightBtn}
                                style={style.rightBtn}
                            >
                                {rightButtonTitle}
                            </Button>
                        </ButtonsWrapper>
                    </DialogWrapper>
                    :
                    <></>
                }
            </>

        )
    }
}

const DialogWrapper = styled(Dialog)({
    "& .backdrop-class": {
        backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
})
const DialogHeaderWrapper = styled(DialogTitle)({
    padding: "22px 32px !important",
    display: "flex",
    justifyContent: "end",

})
const DialogContentWrapper = styled(DialogContent)({
    textAlign: "center",
    padding: "24px 14px !important"
})
const CloseBtn = styled(CloseIcon)({
    cursor: "pointer",
    width: "24px",
    height: "24px",
})
const DescriptionText = styled(Typography)({
    fontWeight: 500,
    fontFamily: "Silika-Medium",
    fontSize: "24px",
    color: '#010101',
    lineHeight: "29px"
})
const ButtonsWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "16px",
    padding: "40px"
})

const style = {
    leftBtn: {
        height: "48px",
        width: "100%",
        maxWidth: "226px",
        color: '#010101',
        border: '1px solid #010101',
        padding: '14.5px 0',
        backgroundColor: "#FFFFFF",
        fontWeight: 500,
        fontFamily: "Silika-Medium",
        fontSize: "16px"
    } as React.CSSProperties,
    rightBtn: {
        height: "48px",
        width: "100%",
        maxWidth: "226px",
        color: "#FFFFFF",
        backgroundColor: "#0D0C22",
        padding: '14.5px 0',
        fontWeight: 500,
        fontFamily: "Silika-Medium",
        fontSize: "16px"

    } as React.CSSProperties

}