import { Container, Typography, createTheme } from "@material-ui/core";
import React from "react";
import {  ThemeProvider } from "react-native-elements";
// Customizable Area Start
import HeaderNavbar from "../../../components/src/Navbar";
import JobProfileWebController, {IJob, Props} from "./JobProfileWebController";
import JobCard from "../../../components/src/JobCard.web";
import {shareImage, saveImage, companyLogo, closeIconImage} from "./assets"
import {styled, DialogActions,Button, DialogContent,DialogTitle,Dialog} from "@material-ui/core";
import dayjs from "dayjs";
import utcTime from "dayjs/plugin/utc";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(utcTime);
dayjs.extend(isSameOrAfter);
const aspectRatio = (489 / 1440) * 100;
const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff"
      }
    }
  });

const StyledContainer = styled("div")({
  "& .coverImageWrapper": {
    position: 'relative' as 'relative',
    width: '100%',
    paddingTop: `${aspectRatio}%`,
    backgroundColor: '#ccc',
  },
  "& .coverImage": {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

const OpportunityStyled = styled("div")({
 
  "@media (max-width: 1440px)": {
    "& .opportunityCard":{
      height : "310px !important",
      width : "375.97px !important",
    }
   },
  "@media (max-width: 1416px)": {
   "& .opportunityCard":{
    width:"49% !important",
    marginBottom:"25px",
    height:"auto !important"
   }
  },
  "@media (max-width: 1164px)": {
   "& .opportunityCard":{
    marginBottom:"15px"
   }
  },
  "@media (max-width: 736px)": {
    padding:"12px !important",
    "& .opportunityCard":{
      width:"98% !important",
    }
   },
});

const DescrtiptionStyled = styled("div")({
"@media (max-width: 736px)": {
 "& .descriptionContainer":{
  padding:"12px !important",
 }}
});

const HeaderStyled = styled("div")({
  "@media (max-width: 736px)": {
    padding:"0px 0px 0px 12px !important",
  }
});

const StyledProfileCard =styled("div")({
  "@media (max-width: 736px)": {
    marginTop:"-70px !important"
  }
})

const StyledDialogApplyJob = styled(Dialog)({
  "& .MuiDialog-paperFullWidth":{
    maxWidth: "600px",
    height:"234px"
  },
  "& .MuiDialogTitle-root":{
    padding:"15px 40px"
  },
  "& .MuiTypography-h6":{
    fontSize:"16px",
    fontFamily:"Silika-Medium"
  },
  "& .subTitle":{
    fontFamily:"Silika-Light",
    fontSize:"16px",
    fontWeight:300,
  },
  "& .cancelBtn":{
    textTransform:"none",
    border:"1px solid black",
    borderRadius:"2px",
    fontSize:"16px",
    fontFamily:"Silika-Medium",
    width:"104px",
    height:"48px"
  },
  "& .yesBtn":{
    width:"112px",
    backgroundColor:"black",
    color:"white",
    marginRight:"32px"
  }
})


const headingBar = (title : string) =>{
    return(
      <HeaderStyled style={webStyle.headingBar}>
          <Typography style={webStyle.blockHeadding}>
            {title}
          </Typography>
      </HeaderStyled>
    )
  }
const ProfileCard = (handleApplyPopUp:()=>void,jobDetailData:IJob,dayDiff:string)=>{
    return(
      <StyledProfileCard data-test-id="profile-container" style={webStyle.profileContainer}>
          <JobCard
              title={jobDetailData?.job_title}
              category={jobDetailData?.category||"Supercars"}
              updatedTime= {dayDiff}
              level={jobDetailData?.level}
              location={`${jobDetailData?.county}, ${jobDetailData?.country}`}
              type="Full Time | Remote"
              profession={jobDetailData?.profession}
              dates="13 Mar 24 - 25 Apr 24"
              rate={`£${jobDetailData?.account.data.attributes.account_profile.data.attributes.hourly_rate_from}-
              ${jobDetailData?.account.data.attributes.account_profile.data.attributes.hourly_rate_to}`}
              budget={"£"+`${jobDetailData?.budget}`}
              description=""
              imageUrl={jobDetailData?.account.data.attributes.account_profile.data.attributes.photo}
              data-test-id="jobcard"
              handleApplyPopUp={handleApplyPopUp}
          />
      </StyledProfileCard>
    )
  }

  const descriptionContainer = (jobDetailData:IJob)=>{
    return(
      <DescrtiptionStyled style={webStyle.administratorBlock}>
          {headingBar("Description")}
       
          <div style={webStyle.descriptionContainer} className="descriptionContainer">
        <Typography style={webStyle.descriptionTitle}>
           {jobDetailData?.job_title}
        </Typography>
        <Typography style={webStyle.descriptionDes}>
        {jobDetailData?.job_description}
        </Typography>
      </div>
      </DescrtiptionStyled>
    )
  }

  const OpportunityContainer = ()=>{
    return(
      <div style={webStyle.administratorBlock}>
          {headingBar("Recomended Opportunities")}
          <OpportunityStyled style={webStyle.opportunityBody}>
            <Opportunity />
            <Opportunity />
            <Opportunity />
          </OpportunityStyled>
      </div>
    )
  }

  const Opportunity=()=>{
    return(
      <div style={webStyle.opportunityCard} className="opportunityCard">
        <img src={companyLogo} style={webStyle.opportunityImage} alt="Job" />
        <div style={webStyle.titleDiv}>
          <Typography style={webStyle.opportunityTitle}>
            Automotive Designer
          </Typography>
          <div style={webStyle.iconDIv}>
            <img src={shareImage} style={webStyle.iconStyle}></img>
            <img src={saveImage} style={webStyle.iconStyle}></img>
          </div>
        </div>
        <div style={webStyle.subTitleDiv}>
        <Typography style={webStyle.opportunityCategory}>
            Supercars
          </Typography>
          <Typography style={webStyle.opportunityTime}>
            3 Days Ago
          </Typography>
        </div>
        <Typography style={webStyle.opportunityDescription}>
          An Automotive Designer is responsible for creating the design and  styling of new vehicles, both for production and concept models. Working  with a team of designers, engineers and other professionals..
          </Typography>
      </div>
    )
  }

    
// Customizable Area End

export default class JobProfile extends JobProfileWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const jobDetailData =this.state?.jobDetailData?.attributes;
    const DialogApplyJobPopUp = () =>(
      <StyledDialogApplyJob
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="md"
      open={this.state.applyPopUpOpen}
  >
      <div style={webStyle.experienceInnerDiv}>
          <DialogTitle style={webStyle.dialougeHeading}>
            Appy Job
          </DialogTitle>
          <img src={closeIconImage} style={webStyle.cancelBtn} data-test-id="closeBtnIcon" id="close-img" onClick={()=>this.handlePopupOpenApply()}></img>
      </div>
      <DialogContent style={webStyle.dialogContent}>
        <Typography className="subTitle">Are you sure you want to apply for this role?</Typography>
      </DialogContent>
      <DialogActions style={{marginBottom:"16px"}}>
          <Button className="cancelBtn" data-test-id="cancelBtn" onClick={()=>this.handlePopupOpenApply()}>Cancel</Button>
          <Button className="yesBtn cancelBtn" data-testId="sendDataId" >yes, Apply</Button>
        </DialogActions>
      </StyledDialogApplyJob>
      )
    
    return (
      <ThemeProvider theme={theme}>
       <HeaderNavbar navigation={this.props.navigation}/>
          <StyledContainer>  
        <Container maxWidth='xl'  style ={webStyle.parentDiv}>   
          {DialogApplyJobPopUp()}   
          <div className="coverImageWrapper" >
                <div className="coverImage"  style={{backgroundImage:`url(${jobDetailData?.banner_image})`}}/>
          </div>
          {ProfileCard(this.handlePopupOpenApply,jobDetailData,
            this.handleDayDiffrence( dayjs(
              jobDetailData?.created_at||""
            ).format("YYYY-MM-DD")
          )===0?"Today":this.handleDayDiffrence(
            dayjs(
              jobDetailData?.created_at||""
            ).format("YYYY-MM-DD")
          ) + " days ago")}
         {descriptionContainer(jobDetailData)}
          <OpportunityContainer />
        </Container>
          </StyledContainer>
       
      </ThemeProvider>
    );
    // Customizable Area End  
  }
}

// Customizable Area Start
export const webStyle = {
    parentDiv : {
        width : "100%",
        padding: "0 0 95px 0",
        display : 'flex', 
        flexDirection : 'column' as 'column', 
        alignItems : 'center',
        marginTop:"18px"
      },
      profileContainer : {
        marginTop : `-110px`,
        width: '88.8%',
        border: '1px solid #DFDFDF',
        zIndex: 2,
        backgroundColor: "white",
        borderRadius: '4px',
      },
      headingBar : {
        height : "60px",
        width : "100%",
        backgroundColor : "#0D0C22",
        padding : "0 0 0 48px",
        display : "flex",
        alignItems : "center"
        
      },
      blockHeadding : {
        color : "white",
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize : "16px",
        lineHeight : "19.3px"
      },
      administratorBlock : {
        width : '88.8%',
        border : '1px solid #DFDFDF',
        marginTop : '70px',
        overflow : "hidden",
        borderRadius : '4px',
      },
      descriptionDes : {
        fontFamily : "Silika-Light",
        fontWeight : 200,
        fontSize : "16px",
        lineHeight : "18.98px",
      },
      descriptionContainer : {
        width : '100%',
        padding : "48px",
        boxSizing: 'border-box' as 'border-box',
        display : "flex",
        flexDirection : "column" as "column",
        gap : "24px"
      },
      opportunityBody : {
        width : '100%',
        padding : "41px 43px 41px 43px",
        boxSizing: 'border-box' as 'border-box',
        display : "flex",
        flexWrap  :"wrap" as "wrap",
        justifyContent : "space-between"
      },
      descriptionTitle : {
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize : "16px",
        lineHeight : "19.3px",
      },
      opportunityCard : {
        height:"auto",
        width:"31%",
        border : "1px solid #DFDFDF",
        padding : "20px",
        boxSizing: 'border-box' as 'border-box',
        borderRadius : "2px"
      },
      opportunityImage : {
        height : "88px",
        width : "94.26",
        border : "1px solid #DFDFDF",
        borderRadius : "2px"
      },
      titleDiv : {
        display : "flex",
        justifyContent : "space-between",
        marginTop : "38px",
        alignItems : "center"
      },
      opportunityTitle : {
        fontFamily : "Silika-Medium",
        fontSize : "20px",
        LineHeight : "24.13px"
      },
      iconDIv : {
        display : "flex",
        gap : "4px"
      },
      iconStyle : {
        height : "24px",
        width : "24px"
      },
      subTitleDiv : {
        display : "flex",
        gap : "75px",
        marginTop : "5px",
        paddingBottom  :"9px",
        borderBottom : "1px solid #DFDFDF"
      },
      opportunityCategory : {
        fontFamily : "Silika-Light",
        fontWeight : 300,
        fontSize: '14px',
        lineHeight : "19.69px"
      },
      opportunityTime : {
        fontFamily : "Silika-Medium",
        fontWeight : 500,
        fontSize: '12px',
        lineHeight : "16.88px",
        color : "#9B9B9B"
      },
      opportunityDescription : {
        fontFamily : "Silika-Light",
        fontWeight : 200,
        fontSize: '12px',
        lineHeight : "14.23px",
        marginTop : "20px"
      },
      experienceInnerDiv: {
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "1195px",
        background: "#000",
        borderRadius: "4px, 4px, 0px, 0px",
        fontSize: "14px !important",
        fontWeight: 500,
        fontFamily: "Silika-Light",
        lineHeight: "19.3px",
        color: "white",
        alignItems: "center"
      },
      dialougeHeading: {
        fontFamily: "Silika-Medium",
        fontSize: "16px !important",
        fontWeight: 500,
        lineHeight: "19px",
      },
      dialogContent:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
      },
      cancelBtn:{
        width: "15px",
        height: "15px", 
        marginRight: "32px", 
        cursor: "pointer" 
      },
};
// Customizable Area End
