import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import parse, { domToReact } from "html-react-parser";

interface HtmlTypographyProps extends TypographyProps {
  htmlContent: string;
}

const HtmlTypography: React.FC<HtmlTypographyProps> = ({
  htmlContent,
  className,
  ...props
}) => {
  if (typeof htmlContent !== 'string') {
    console.error("Invalid htmlContent: Expected a string");
    return null;
  }
  const transform = (node: any) => {
    if (node.name === "p" && node.children.length === 1 && node.children[0].name === "br") {
      return <div key={node.key} style={{ margin: '1em 0' }} />;
    }
    if (node.name === "p") {
      return (
        <Typography
          key={node.key}
          className={className}
          style={{ margin: 0, padding:0 }} 
          {...props}
        >
          {domToReact(node.children)}
        </Typography>
      );
    }
    if (node.name === "br") {
      return <br key={node.key} />;
    }

    return node;
  };

  return (
    <div className={className}>
      {parse(htmlContent, { replace: transform })}
    </div>
  );
};

export default HtmlTypography;
