export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const accountLight = require("../assets/accountLight.svg")
export const visibilityLight = require("../assets/visibilityLight.svg")
export const termsLight = require("../assets/termsLight.svg")
export const projectLight = require("../assets/projectLight.svg")
export const notificationLight = require("../assets/notificationLight.svg")
export const accountDark = require("../assets/accountBlack.svg")
export const visibilityDark = require("../assets/visibilityBlack.svg")
export const termsDark = require("../assets/termsBlack.svg")
export const projectDark = require("../assets/projectBlack.svg")
export const notificationDark = require("../assets/notificationBlack.svg")
export const more = require("../assets/more.svg")
export const correct = require("../assets/correct.svg")
export const closeImage = require("../assets/close.svg");
