import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from "@material-ui/core";
import { closeIcon } from "../assets";

interface Iprops{
 handlePopUpOpportunity:()=>void;
 open:boolean;
 handleChooseOptionForOpportunity:(type:string)=>void;
 handleOpenPopUpRelatedPopup:()=>void
 typeOpportunity:string
}

const StyledDialogApplyJob = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    maxWidth: "600px",
    height: "406px",
  },
  "& .MuiTypography-h6": {
    fontSize: "16px",
    fontFamily: "Silika-Medium",
  },
  "& .MuiDialogTitle-root": {
    padding: "15px 40px",
  },
  "& .subTitle": {
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontWeight: 300,
    maxWidth:"480px",
    textAlign: "center",
    marginTop:"30px",
    color:"#010101"
  },
  "& .cancelBtn": {
    textTransform: "none",
    border: "1px solid black",
    borderRadius: "2px",
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    maxWidth: "521px",
    height: "48px",
    marginBottom:"26px"
  },
  "& .yesBtn": {
    width: "112px",
    backgroundColor: "black",
    color: "white",
    marginRight: "32px",
    marginBottom:"15px"
  },
  "& .btnContainer":{
    width:"100%",
    justifyContent:"center",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginTop:"26px"
  },
  "& .opportunitySelectionBtn":{
    width:"521px",
    fontFamily:"silika-medium",
    fontSize:"16px",
   "&:hover":{
    backgroundColor:"black",
    color:"white"
   }
  },
  "& .opportunitySelected":{
    backgroundColor:"black",
    color:"white"
  },
  "& .unselectedBtn":{
    border:"1px solid #C4C4C4 !important",
    color:"#C4C4C4"
  },
  "& .nextDummy":{
    display:"none !important"
  }
});

export const DialogOpportunity = ({open,
  handlePopUpOpportunity,
  handleChooseOptionForOpportunity,
  handleOpenPopUpRelatedPopup,
  typeOpportunity
}:Iprops) => {

  const checkNullOrValid = (typeOpportunityNeedCheck:string):boolean =>{
    return typeOpportunity!==typeOpportunityNeedCheck && typeOpportunity!==""
  }

  return (
    <StyledDialogApplyJob
    maxWidth="md"
    fullWidth={true}
    open={open}
    aria-describedby="alert-dialog-slide-description"
    >
      <div style={webStyle.experienceInnerDiv1}>
        <DialogTitle style={webStyle.dialougeHeading}>New Opportunity</DialogTitle>
        <img
          src={closeIcon}
          style={{
            width: "15px",
            height: "15px",
            marginRight: "32px",
            cursor: "pointer",
          }}
          onClick={handlePopUpOpportunity}
          data-test-id="closeBtnIcon"
          id="close-img"
        ></img>
      </div>
      <DialogContent style={webStyle.dialogContent}>
        <Typography className="subTitle">
        This designer has been sent an invitation to be shortlisted. 
        You will be notified when they accept.
        </Typography>
        <div className="btnContainer">
          <Button className={`cancelBtn opportunitySelectionBtn
         ${typeOpportunity === "Permanent" &&
              typeOpportunity ? "opportunitySelected" : ""} 
          ${checkNullOrValid("Permanent") ? "unselectedBtn" : ""}`} data-test-id="PermanentBtn"
            onClick={() => handleChooseOptionForOpportunity("Permanent")}
          >Permanent Position</Button>
          <Button className={`cancelBtn opportunitySelectionBtn
         ${typeOpportunity === "Freelance" &&
              typeOpportunity ? "opportunitySelected" : ""}  
         ${checkNullOrValid("Freelance") ? "unselectedBtn" : ""}`}
            data-test-id="FreelanceBtn"
            onClick={() => handleChooseOptionForOpportunity("Freelance")}>Freelance Role</Button>
        </div>
      </DialogContent>
      <DialogActions style={{ maxHeight: "88px",display:"flex",alignItems:"center" }}>
        <Button className="yesBtn cancelBtn" data-test-id="nextBtn" 
         onClick={()=>handleOpenPopUpRelatedPopup()}>
         Next
        </Button>
        <Button className="yesBtn cancelBtn nextDummy" data-test-id="nextBtn2" 
         onClick={()=>handleOpenPopUpRelatedPopup()}>
         Next1
        </Button>
        <Button className="yesBtn cancelBtn nextDummy" data-test-id="nextBtn3" 
         onClick={()=>handleOpenPopUpRelatedPopup()}>
         Next2
        </Button>
      </DialogActions>
    </StyledDialogApplyJob>
  );
};

const webStyle = {
  experienceInnerDiv1: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1195px",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "19.3px",
    color: "white",
    alignItems: "center",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap:"wrap" as "wrap"
  },
  dialougeHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "16px !important",
    fontWeight: 500,
    lineHeight: "19px",
  },
};
