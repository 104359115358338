Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.apiMethodTypePut = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.profileListApiEndPoint = "/bx_block_profile/find_talent";
exports.giveFeedbackApiEndPoint = "/bx_block_like/likes";
exports.getAllApiEndPoint = "/bx_block_like/likes";
exports.getProfessionsListApiEndPoint="/profession/professions";
exports.getWorkTypesListApiEndPoint="/profession/work_types";
exports.getAllSkilsApiEndPoint="skill/skills";
exports.getSeniorityListApiEndPoint="profession/seniority_level";
exports.favouritePeopleApiEndPoint="/bx_block_favourites/favourites";
exports.favouritePostApiEndPoint="bx_block_posts/posts/add_to_favorite";
exports.likePostEndPoint="/bx_block_posts/posts/like_image";
exports.sharePostEmailApiEndPoint="bx_block_posts/posts/share_email";
exports.shareProfileEmailApiEndPoint="bx_block_profile/share_email";
// Customizable Area End