import React from "react";
import DialogWithBtns from "../../../../components/src/DialogWithBtns.web";
import { Box, Grid, styled, TextField, Typography } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { Autocomplete } from "@mui/material";

type Props = {
    open: boolean,
    title :string,
    onCancelClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onSuccessClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    cancelText?: string;
    successText?: string;
    onClose?: () => void;
}
const StyledTextField = styled(TextField)({
  "& .formHelperClass":{
      color: "#DC2626",
      marginLeft: "0px",
      fontFamily: "Silika-Medium",
  },
  "& input, input::placeholder":{
      fontFamily: "Silika-Medium !important",
  }
});

const AutocompleteCustom = styled(Autocomplete)({
  "& *":{
      fontFamily: "Silika-Medium"
  },
  "& input":{
      height: "11px"
  },
  "& input::placeholder":{
      fontFamily: "Silika-Medium !important",
      color: "hsl(0,0%,50%) !important",
      opacity: "1 !important",
  }
})

function AddAdministrator(props:Props) {
  const {open, title, cancelText,successText, onCancelClick, onClose, onSuccessClick} = props
  return (
    <DialogWithBtns
          open={open}
          title={title}
          cancelText={cancelText}
          successText={successText}
          onCancelClick={onCancelClick}
          onClose={onClose}
          onSuccessClick={onSuccessClick}
        >
           <Box >
                            <Formik
                                initialValues={{
                                    firstName: "",
                                    lastName: "",
                                    workemail : "",
                                    authority : "",
                                    file: null
                                }}
                                data-test-id="formikForm"
                                onSubmit={(values) => { 
                                    
                                }}
                                enableReinitialize
                            >
                                {({
                                    touched, handleSubmit,values, setFieldValue, handleBlur, handleChange, errors, setFieldError, setFieldTouched, validateField
                                }) => {
                                    return (
                                        <form style={{ height: '100%' }} autoComplete="off" onSubmit={handleSubmit}>
                                            <Grid container spacing={2} style={{ textAlign: 'center' }}>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"firstName"}
                                                        value={values.firstName}
                                                        inputProps={{ maxLength: 30, style: { height: 10 } , className:"customTextField"}}
                                                        placeholder="First Name"
                                                        variant="outlined"
                                                        data-test-id='firstName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="firstName" />}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"lastName"}
                                                        data-test-id='lastName'
                                                        value={values.lastName}
                                                        placeholder="Last Name"
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="lastName" />}
                                                        inputProps={{ maxLength: 30, style: { height: 10 }, className:"customTextField" }}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} style={{ textAlign: 'center' }}>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"workemail"}
                                                        value={values.workemail}
                                                        inputProps={{ maxLength: 30, style: { height: 10 } , className:"customTextField"}}
                                                        placeholder="Work email"
                                                        variant="outlined"
                                                        data-test-id='workemail'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="workemail" />}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                <AutocompleteCustom
                                                  disableClearable
                                                  id="authority-combo-box"
                                                  data-test-id="AuthorityData"
                                                  value={values.authority}  
                                                  options={['Project Manager', 'Purchasing Authority', 'Design Authority']} 
                                                  getOptionLabel={(option:any) => option}  
                                                  renderInput={(params:any) => (
                                                    <StyledTextField
                                                      placeholder="Select Authority"
                                                      {...params}
                                                      variant="outlined"
                                                      FormHelperTextProps={{
                                                        className: "formHelperClass",
                                                      }}
                                                    />
                                                  )}
                                                  onChange={(_event:any, newValue:any) => {
                                                    setFieldValue('authority', newValue); 
                                                  }}
                                                  disabled={false} 
                                                  isOptionEqualToValue={(option:any, value:any) => option === value}  
                                                />

                                                </Grid>
                                            </Grid>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Box>
    </DialogWithBtns>
  )
}

export default AddAdministrator

const StyledTypography = styled(Typography)({
    fontFamily  :"Silika-Medium", 
    fontWeight  :300, 
    textAlign : "center", 
    fontSize : "16px", 
    marginBottom  :"24px",
})
