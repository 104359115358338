import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from "@material-ui/core";
import { closeIcon } from "../assets";

interface IProps{
 open:boolean;
 handleApplySuccessPopUp:()=>void
}

const StyledDialogApplyJob = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    maxWidth: "600px",
    height: "175px",
  },
  "& .MuiDialogTitle-root": {
    padding: "15px 40px",
  },
  "& .MuiTypography-h6": {
    fontSize: "16px",
    fontFamily: "Silika-Medium",
  },
  "& .subTitle": {
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontWeight: 300,
    maxWidth:"480px",
    textAlign: "center",
    color:"#010101"
  },
  "& .dialogContent":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap:"wrap" as "wrap"
  },
  "& .dialougeHeading": {
    fontFamily: "Silika-Medium",
    fontSize: "16px !important",
    fontWeight: 500,
    lineHeight: "19px",
  },
  "& .experienceInnerDiv1": {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1195px",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "19.3px",
    color: "white",
    alignItems: "center",
  },
  "& .closeBtnIcon":{
    width: "15px",
    height: "15px",
    marginRight: "32px",
    cursor: "pointer",
  }
});

export const ApplySuccessPopUp = ({open,handleApplySuccessPopUp}:IProps) => {
  return (
    <StyledDialogApplyJob
      fullWidth={true}
      maxWidth="md"
      open={open}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="experienceInnerDiv1">
        <DialogTitle className="dialougeHeading">Apply Job</DialogTitle>
        <img
          src={closeIcon}
          className="closeBtnIcon"
          data-test-id="closeBtnIcon"
          id="close-img"
          onClick={handleApplySuccessPopUp}
        ></img>
      </div>
      <DialogContent className="dialogContent">
        <Typography className="subTitle">
        You applying this job successfully.
        </Typography>
      </DialogContent>
    </StyledDialogApplyJob>
  );
};

