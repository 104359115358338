import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { designResearch, exterior } from "./assets";
export interface IFirstPageData{
  id: string,
  type: string,
  attributes:IAttributes
}

export interface IAttributes{
  id: number,
  title: string,
  description: string,
  images: IMages[]
}

export interface IMages{
  id: number,
  url: string
}
interface FAQ {
  id: string;
  type: string;
  attributes: {
    id: number;
    question: string;
    answer: string;
    created_at: string;
    updated_at: string;
  };
}

interface HelpFeature {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface HelpFeatureData {
  data: HelpFeature[];
}

interface HelpAttributes {
  id: number;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  help_features?: HelpFeatureData;
  technical_talent_features?: HelpFeatureData;
  design_talent_features?: HelpFeatureData;
}

interface Help {
  id: string;
  type: string;
  attributes: HelpAttributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landingPageData:IFirstPageData[];
  faqData:FAQ[];
  supportFeatureData:Help[];
  findDesignTalents:Help[];
  findTechnicalTalents:Help[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLandingPageApiCallId: string= "";
  getFAQApiCallId: string= "";
  getSupportFeatureApiCallId:string="";
  getFindDesignTalentApiId:string="";
  getFindTechnicalTalentApiId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      landingPageData:[],
      faqData : [],
      supportFeatureData : [],
      findDesignTalents : [],
      findTechnicalTalents : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   this.handleGetLandingPageData()
   this.handleGetFAQData()
   this.handleGetSupportFeaturesData()
   this.handleGetFindDesignTalentData()
   this.handleGetFindTechnicalTalentData()
    window.scroll({
      top: 0,
      left: 0
    })
      this.validateClearStorage()
  }
  validateClearStorage(){
    window.localStorage.clear();
    sessionStorage.clear();
  }

  private handleApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.getFAQApiCallId:
        this.handleGetFaqApiResponse(responseJson);
        break;
      case this.getSupportFeatureApiCallId:
        this.handleGetSupportFeatureApiResponse(responseJson);
        break;
      case this.getFindDesignTalentApiId:
        this.handleDesignTalentApiResponse(responseJson);
        break;
      case this.getFindTechnicalTalentApiId:
        this.handleTechnicalTalentApiResponse(responseJson);
        break;
      default:
        this.handleLandingPageApiResponse(responseJson);;
    }
  }

  private handleLandingPageApiResponse(responseJson: any) {
    this.setState({ landingPageData:responseJson.data });
  }

  private handleGetFaqApiResponse(responseJson: any) {
    this.setState({ faqData:responseJson.data });
  }

  private handleGetSupportFeatureApiResponse(responseJson: any) {
    this.setState({ supportFeatureData:responseJson.data });
  }

  private handleDesignTalentApiResponse(responseJson:any){
    this.setState({findDesignTalents:responseJson.data});
  }

  private handleTechnicalTalentApiResponse(responseJson:any){
    this.setState({findTechnicalTalents:responseJson.data});
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNavigation = (screenName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    runEngine.sendMessage(message.id, message);
};

handleGetLandingPageData = () => {
  const requestMessager = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getLandingPageApiCallId = requestMessager.messageId;

  requestMessager.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getLandingPageDataApiEndPoint}`
  );

  requestMessager.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessager.id, requestMessager);

  return true;
};

handleGetFAQData = () => {
  const requestMessager = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getFAQApiCallId = requestMessager.messageId;

  requestMessager.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getFAQApiEndPoint}`
  );

  requestMessager.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessager.id, requestMessager);

  return true;
};

handleGetSupportFeaturesData = () => {
  const requestMessager = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSupportFeatureApiCallId = requestMessager.messageId;

  requestMessager.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getSupportFeatureApiEndPoint}`
  );

  requestMessager.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessager.id, requestMessager);

  return true;
};

handleGetFindDesignTalentData = () => {
  const requestMessager = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getFindDesignTalentApiId = requestMessager.messageId;

  requestMessager.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getFindDesignTalentApiEndPoint}`
  );

  requestMessager.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessager.id, requestMessager);

  return true;
};

handleGetFindTechnicalTalentData = () => {
  const requestMessager = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getFindTechnicalTalentApiId = requestMessager.messageId;

  requestMessager.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getFindTechnicalTalentApiEndPoint}`
  );

  requestMessager.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessager.id, requestMessager);

  return true;
};
  // Customizable Area End
}
