import React from "react";
// Customizable Area Start
import {
  Button,
  Container,
  Typography,
  Popover 
} from "@material-ui/core";
import { createTheme, styled, } from "@material-ui/core/styles";
import {likeImage, shareImage, saveImage, ChatIcon, location, companyLogo, profileImg} from "./assets"
import JobCard from "../../../components/src/JobCard.web";
import CoverImageComponent from "../../../components/src/CoverImageComponent.web"
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddAdministrator from "./components/AddAdministrator";
import DeletePopUp from "./components/DeletePopUp";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
import ProfileInfoController,{
  Props
} from "./ProfileInfoController";



const PopOverContainer = styled('div')({
  padding : "4px",
  "& .popOverButton" : {
    width: "200px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    gap: "8px",
    boxSizing: "border-box",
    '&:hover': {
      backgroundColor: '#0D0C22', 
      borderRadius : "2px",
      cursor: 'pointer',
      "& .popOverIcons" : {
          color : "#fff"
      },
      "& .popOverTitle" : {
        color : "#fff"
    }
    },
  },
  "& .popOverIcons" : {
    height : "18px",
    width : "18px",
  },
  "& .popOverTitle" : {
    fontFamily : "Silika-Medium",
    fontWeight  :500,
    fontSize : "14px"
  }
});



// Customizable Area End

export default class ClientProfile extends ProfileInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headingBar = (title : string, count : number, icon:boolean) =>{
    return(
      <div style={webStyle.headingBar}>
          <Typography style={webStyle.blockHeadding}>
            {title+` (${count})`}
          </Typography>
          {icon && <AddIcon onClick={this.handleOpenAdministratorPopUp} sx={{color : "white", cursor : "pointer"}}/>} 
      </div>
    )
  }

  administrator = (AdministratorId : number, name:string, headline : string, roles : any[]) =>{
    const openx = Boolean(this.state.anchorEl);
    const id = openx ? "simple-popover" : undefined;
    return(
      <div style={webStyle.adminstratorBlock}>
          <img src={profileImg} style={webStyle.administratorprofile}>
          </img>
          <div>
            <Typography style={webStyle.administratorName}>
              {name}
            </Typography>
            <Typography style={webStyle.administratorHeadline}>
              {headline} 
            </Typography>
            <div style={webStyle.borderBottom}></div>
            <div style={webStyle.professionBox}>
              {roles.map((role, index)=>(
                <div key={index} style={webStyle.professionCard}>
                <Typography style={webStyle.professionTitle}>
                  {role.title}
                </Typography>
              </div>
              ))}
            </div>
          </div>
          <MoreVertIcon style={webStyle.moreIcon} onClick={(e)=>this.handleClick(e, AdministratorId)} />
          <Popover
            id={id?.toString()}
            open={openx}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClosePopUp}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
              },
            }}
          >
            <PopOverContainer>
              <div  className="popOverButton" onClick={this.handleEditAdministratorPopUpOpen}>
                <EditIcon className="popOverIcons" />
                <Typography className="popOverTitle">Edit</Typography>
              </div>
              <div className="popOverButton" onClick={this.handleDeleteAdministratorPopUpOpen}>
                <DeleteOutlineIcon className="popOverIcons"  />
                <Typography className="popOverTitle">Delete</Typography>
              </div>
            </PopOverContainer>
          </Popover>
      </div>
    )
  }

  profileCard = (company:any)=>{
    return(
      <div style={webStyle.profileContainer}>
        <EditIcon onClick={this.handleEditProfilePopUpOpen} style={webStyle.editIcon} />
          <img style={webStyle.clientImgStyle} src={companyLogo}>
          </img>
        <div style={webStyle.clientDetailsDiv}>
            <Typography style={webStyle.clientName}>
              {company.company_name}
            </Typography>
            <Typography style={webStyle.description}>
            {company.headline}
            </Typography>
            <div style = {webStyle.attributesDivParent}>
            <div style={webStyle.attributesDiv}>
            <Typography style={webStyle.attributeTitle}>
              Location:
            </Typography>
            <img src={location} style={webStyle.iconStyle}>
            </img>
            <Typography style={webStyle.attributeDesc}>
             {company.registered_address}
            </Typography>
            </div>
            <div style={webStyle.borderBottom}></div>
            <div style={webStyle.attributesDiv}>
            <Typography style={webStyle.attributeTitle}>
              Web:
            </Typography>
            <Typography style={webStyle.attributeDesc}>
            www.supercars.com
            </Typography>
            </div>
            </div>
            <div style={webStyle.clientActionDiv}>
                <img src={likeImage} style={webStyle.iconStyle}>
                </img>
                <div style={webStyle.clientActionright}>
                <img src={ChatIcon} style={webStyle.iconStyle}>
                </img>
                <img src={shareImage} style={webStyle.iconStyle}>
                </img>
                <img src={saveImage} style={webStyle.iconStyle}>
                </img>
                </div>
            </div>
        </div>
      </div>
    )
  }

  descriptionContainer = (description : string) =>{
    return(
      <div style={webStyle.descriptionContainer}>
        <Typography style={webStyle.descriptionTitle}>
              Description
        </Typography>
        <Typography style={webStyle.descriptionDes}>
        {description}
        </Typography>
      </div>
    )
  }

  administratorContainer = ()=>{
    return(
      <div style={webStyle.administratorBlock}>
          {this.headingBar("Administrators", 6, true)}
          <div style={webStyle.administratorBody}>
            <div style={webStyle.cardParent}>
            {this.state.administrators.map((administrator:any) => (
              this.administrator(administrator.id,administrator.name, administrator.headline, administrator.roles )
            ))}
          </div>
          <Button style={webStyle.showMoreButton}>
            Show More +
          </Button>
        </div>
      </div>
    )
  }

  opportunityContainer = ()=>{
    return(
      <div style={webStyle.administratorBlock}>
        {this.headingBar("Opprortunities", 5, false)}
        <div style={webStyle.opportunityBody}>
          <JobCard
              title="Automotive Designer"
              category="Supercars"
              updatedTime="5 days ago"
              level="Senior"
              location="Coventry, UK"
              type="Full Time | Remote"
              profession="Interior Design | UI/UX Design | Visualization"
              dates="13 Mar 24 - 25 Apr 24"
              rate="£45.00/hr"
              budget="£2,000"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
              imageUrl={companyLogo}
          />
          <Button style={webStyle.showMoreButton}>
            Show More +
          </Button>
        </div>
      </div>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const company=this.state.getProfile?.attributes||{};
    return (
      <div>
        <DeletePopUp
          open={this.state.isDeleteJob}
          title="Delete Job"
          description ="Are you sure you want to delete
          this job?"
          cancelText="Cancel"
          successText="Yes, delete"
          onCancelClick={this.handleCloseDeletePopUp}
          onClose={this.handleCloseDeletePopUp}
          onSuccessClick={this.handleDeleteJob}
        />
        <DeletePopUp
          open={this.state.isDeleteAdministrator}
          title="Delete Administrator"
          description ="Are you sure you want to delete
          this Administartor?"
          cancelText="Cancel"
          successText="Yes, delete"
          onCancelClick={this.handleDeleteAdministratorPopUpCLose}
          onClose={this.handleDeleteAdministratorPopUpCLose}
          onSuccessClick={this.handleDeleteAdministrator}
        />
        <AddAdministrator
          open={this.state.isAddAdministrator}
          title="Add Administrators"
          cancelText="Cancel"
          successText="Add"
          onCancelClick={this.handleCloseAdministratorPopUp}
          onClose={this.handleCloseAdministratorPopUp}
          onSuccessClick={this.handleUpdateAdministrator}
        />
        <AddAdministrator
          open={this.state.isEditAdministrator}
          title="Edit Administrator"
          cancelText="Cancel"
          successText="Save"
          onCancelClick={this.handleEditAdministratorPopUpCLose}
          onClose={this.handleEditAdministratorPopUpCLose}
          onSuccessClick={this.handleEditAdministrator}
        />
        <Container maxWidth='xl'  style ={webStyle.parentDiv}>
          <CoverImageComponent />
          {this.profileCard(company)}
          {this.descriptionContainer(company.description)}
          {this.administratorContainer()}
          {this.opportunityContainer()}
        </Container>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = {
  parentDiv : {
    width : "100%",
    padding: "0 0 95px 0",
    display : 'flex', 
    flexDirection : 'column' as 'column', 
    alignItems : 'center'
  },
  profileContainer : {
    marginTop : `-110px`,
    width: '85%',
    border: '1px solid #DFDFDF',
    zIndex: 2,
    backgroundColor: "white",
    borderRadius: '4px',
    display: "flex",
    padding: "48px",
    gap: "48px",
    boxSizing: 'border-box' as 'border-box',
    position : "relative" as "relative"
  },
  descriptionContainer : {
    width : '85%',
    border : '1px solid #DFDFDF',
    marginTop : '70px',
    borderRadius : '4px',
    padding : "48px",
    boxSizing: 'border-box' as 'border-box',
    display : "flex",
    flexDirection : "column" as "column",
    gap : "24px"
  },
  administratorBlock : {
    width : '85%',
    border : '1px solid #DFDFDF',
    marginTop : '70px',
    borderRadius : '4px',
  },
  headingBar : {
    height : "60px",
    boxSizing: 'border-box' as 'border-box',
    width : "100%",
    backgroundColor : "#0D0C22",
    padding : "0 48px",
    display : "flex",
    justifyContent : "space-between",
    alignItems : "center",
  },
  blockHeadding : {
    color : "white",
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "16px",
    lineHeight : "19.3px"
  },
  clientImgStyle :{
    height : "170px",
    width : "170px",
    border : "1px solid #D9D9D9",
    objectFit : "cover" as "cover",
  },
  clientDetailsDiv : {
    height : "100%",
    display : "flex",
    flex:1,
    flexDirection : "column" as "column",
    gap : "24px"
  },
  clientName : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "26px",
    lineHeight : "31.37px"
  },
  description : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "14px",
    lineHeight : "19.69px"
  },
  borderBottom : {
    borderBottom : "1px solid #DFDFDF"
  },
  attributesDiv : {
    display : "flex",
    gap  :"8px",
    alignItems : "center"
  },
  attributeTitle : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "12px",
    lineHeight : "16.88px",
    color : "#9B9B9D"
  },
  descriptionTitle : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "20px",
    lineHeight : "24.13px",
  },
  descriptionDes : {
    fontFamily : "Silika-Light",
    fontWeight : 200,
    fontSize : "16px",
    lineHeight : "18.98px",
    whiteSpace: "pre-wrap" as "pre-wrap"
  },
  attributeDesc : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "12px",
    lineHeight : "16.88px",
  },
  attributesDivParent : {
    height : "70px",
    display : "flex",
    flexDirection : "column" as "column",
    borderBottom : "1px solid #DFDFDF",
    borderTop : "1px solid #DFDFDF",
    justifyContent : "space-evenly"
  },
  clientActionDiv : {
    height : "30px",
    width : "100%",
    display : "flex",
    justifyContent : "space-between",
    alignItems : "center"
  },
  clientActionright : {
    display  :"flex",
    gap : "20px"
  },
  iconStyle : {
    height : "24px",
    width : "24px"
  },
  administratorBody : {
    display  :"flex",
    flexDirection : "column" as "column",
    alignItems : "center",
    padding : "48px"
  },
  opportunityBody : {
    display  :"flex",
    flexDirection : "column" as "column",
    alignItems : "center",
    padding : "48px"
  },
  cardParent : {
    display : "flex",
    gap : "24px",
    flexWrap  :"wrap" as "wrap",
    justifyContent : "center",
    marginTop : "24px",
  },
  adminstratorBlock : {
    maxHeight : "332px",
    maxWidth : "375px",
    paddingTop : "30px",
    paddingBottom  :"30px",
    display : "flex",
    flexDirection : "column" as "column",
    gap : "24px",
    alignItems : "center",
    borderRadius : "4px",
    border : "1px solid #DFDFDF",
    position  :"relative" as "relative"
  },
  administratorprofile : {
    height : "100px",
    width : "100px",
    borderRadius : "50%",
    objectFit : "cover" as "cover"
  },
  administratorName : {
    fontFamily : "Silika-Medium",
    fontSize : "20px",
    fontWeight : 500,
    lineHeight : "24.13px",
    textAlign : "center" as "center"
  },
  administratorHeadline : {
    fontFamily : "Silika-Light",
    fontSize : "14px",
    fontWeight : 300,
    lineHeight : "22px",
    marginBottom : "10px",
    marginTop  :"4px",
    textAlign : "center" as "center"
  },
  professionBox : {
    width : "315px",
    display : "flex",
    gap : "8px",
    flexWrap:"wrap" as "wrap",
    marginTop : "16px",
    justifyContent : "center"
  },
  professionCard : {
    height : "33px",
    width : "134px",
    border  :"1px solid #DFDFDF",
    borderRadius : "2px",
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },
  professionTitle : {
    fontFamily : "Silika-Medium",
    fontSize : "12px",
    fontWeight : 500,
    lineHeight : "14.4px",
  },
  showMoreButton : {
    border : "1px solid #DFDFDF",
    marginTop : "32px",
    textTransform  :"none" as "none",
    fontSize: '16px', 
  },
  plusIcon : {
    color : "white",
    height : "20px",
    width : "20px"
  },
  moreIcon : {
    position  :"absolute" as "absolute",
    top : "24px",
    right : "24px",
    cursor : "pointer"
  },
  editIcon : {
    position  :"absolute" as "absolute",
    top : "50px",
    right : "50px",
    cursor : "pointer"
  },
  popOverButtons : {
    width : "200px",
    height : "36px",
    display : "flex",
    alignItems : "center",
    paddingLeft : "16px",
    gap  :"8px",
    boxSizing : "border-box" as  "border-box",
    '&:hover': {
      backgroundColor: 'black', 
      cursor: 'pointer',
    },
  },
}

// Customizable Area End
