import React from "react";
import { styled, Typography, Theme } from "@material-ui/core";

interface Props{
    title: string
}

const StyledNoFavourites = styled(Typography)(({theme}:{theme: Theme}) => ({
    textAlign: "center",
    marginTop:"40px",
    height: "100%",
    fontSize: "32px"
}));

const NoFavourites = (props: Props) => {
    return <StyledNoFavourites>{props.title}</StyledNoFavourites>
};

export default NoFavourites;