Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';
exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";

exports.chatList = [{
  id: "1",
  muted: false,
  unreadCount: 2,
  lastMessage: "I ve just received an e-mail...",
  name: "Jane Doe",
  time: 5,
  approved : true
},
{
  id: "2",
  muted: false,
  unreadCount: 0,
  lastMessage: "Perfect, thanks",
  name: "Matt Taylor",
  time: 3,
  approved : true
},
{
  id: "3",
  muted: false,
  unreadCount: 3,
  lastMessage: "Do we have a meeting tomorr...",
  name: "Dale Carlson",
  time: 7,
  approved : true
},
{
  id: "4",
  muted: false,
  unreadCount: 1,
  lastMessage: "Got the file, thanks",
  name: "Mary Lee",
  time: 8,
  approved : true
},
{
  id: "5",
  muted: false,
  unreadCount: 0,
  lastMessage: "Great, thanks",
  name: "Willie Gilbert",
  time: 5,
  approved : false
},
{
  id: "6",
  muted: false,
  unreadCount: 0,
  lastMessage: "See you tomorrow!",
  name: "Michele Burton",
  time: 3,
  approved : false
},
{
  id: "7",
  muted: true,
  unreadCount: 0,
  lastMessage: "I'll send the report by EOD.",
  name: "Maria Sanchez",
  time: 15,
  approved: false
},
{
  id: "8",
  muted: false,
  unreadCount: 1,
  lastMessage: "Can you review the document?",
  name: "John Doe",
  time: 25,
  approved: true
},
{
  id: "9",
  muted: false,
  unreadCount: 5,
  lastMessage: "Let's catch up later today.",
  name: "Jane Smith",
  time: 12,
  approved: true
},
{
  id: "9",
  muted: true,
  unreadCount: 0,
  lastMessage: "Looking forward to our meeting.",
  name: "Samuel Lee",
  time: 5,
  approved: false
},
{
  id: "11",
  muted: false,
  unreadCount: 2,
  lastMessage: "Reminder: Project deadline is Friday.",
  name: "Emily Wong",
  time: 5,
  approved: true
},
{
  id: "12",
  muted: false,
  unreadCount: 6,
  lastMessage: "Hello are you there ?",
  name: "Arnab Roy",
  time: 1,
  approved: true
},
{
  id: "13",
  muted: false,
  unreadCount: 0,
  lastMessage: "Working ?",
  name: "Emely",
  time: 1,
  approved: true
},
{
  id: "14",
  muted: false,
  unreadCount: 0,
  lastMessage: "Working 333?",
  name: "Anya",
  time: 1,
  approved: true
}

];

exports.roomList = [{
  id: "5",
  muted: false,
  unreadCount: 0,
  lastMessage: "Great, thanks",
  name: "Willie Gilbert",
  time: 5,
},
{
  id: "6",
  muted: false,
  unreadCount: 0,
  lastMessage: "See you tomorrow!",
  name: "Michele Burton",
  time: 3,
}
]


  // Customizable Area End