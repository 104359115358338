import React from "react";
import Terms from "../src/components/Terms"
// import {accountLight,visibilityLight,termsLight,projectLight,notificationLight,accountDark,visibilityDark,termsDark,projectDark,notificationDark} from "../../assets"
// Customizable Area Start


// Customizable Area End

import TermsAndConditionWebController, {
  Props,
} from "./TermsAndConditionWebController";

export class TermsAndConditionWeb extends TermsAndConditionWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
      <Terms state={this.state}/>
      </>
      // Customizable Area End
    );
  }
}


