import React from 'react'
import {
    Theme, Button, styled
} from "@material-ui/core"
import parse from "html-react-parser";

interface Props {
    termsAndConditions: string;
    isAgreeButtonEnabled: boolean;
    onAgreeButtonClick: () => void;
}

class TermsAndConditions extends React.Component<Props>{

    constructor(props: Props){
        super(props);
    }

    render(): React.ReactNode {
        return (
            <StyledTermAndConditions>
                <div className="termsAndConditions" data-test-id="termsAndConditions">
                    {parse(this.props.termsAndConditions)}
                </div>
                {!this.props.isAgreeButtonEnabled ? <StyledButtonContainer> 
                    <Button data-test-id="termsAndConditionsAgree" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.props.onAgreeButtonClick()}>Agree</Button>
                </StyledButtonContainer>: <></>}
            </StyledTermAndConditions>
        )
    }
}

const StyledTermAndConditions = styled("div")(({theme}: {theme: Theme}) => ({
    width: "calc(100% - 350px)",
    marginTop: "50px",
    [theme.breakpoints.down('sm')] :{ 
        width: "calc(100vw - 80px)",
        marginTop: "24px",
        "& .termsAndConditions":{
            marginLeft: "56px",
        }
    },
    "& p":{
        fontFamily: "Silika-Light",
        fontSize: "16px",
        lineHeight: "24px"
    }
}));

const StyledButtonContainer = styled("div")(({theme}: {theme: Theme}) => ({
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down('sm')] :{ 
        width: "100vw"
    },
    "& button":{
        backgroundColor: "#0D0C22 !important",
        borderRadius: "2px !important",
        color:"white !important",
        right:"0px",
        textTransform: "none",
        width: "87px",
        height: "48px",
        fontSize: "16px",
        fontFamily: "Silika-Medium",
        marginTop: "21.5px",
        marginBottom: "32.5px",
        [theme.breakpoints.down('sm')] :{ 
            width: "calc(100% - 50px)",
            margin: "24px 25px auto 25px"
        }
    }
}));

export default TermsAndConditions
