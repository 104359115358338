export const measureInternetSpeed = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      const testUrl = window.location.href;
      const startTime = performance.now();
     
      fetch(testUrl)
        .then(() => {
          const endTime = performance.now();
          const speed = endTime - startTime; 
          resolve(speed);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

export const isSlowInternet = async() => {
  const speed = await measureInternetSpeed();
      const speedInSeconds =  speed / 1000;
      const fileSizeInBytes = 1024 * 1024;
      const estimatedSpeedMbps = fileSizeInBytes / speedInSeconds / 125000;
      const slowInternet = estimatedSpeedMbps && estimatedSpeedMbps <= 30;
      return slowInternet;
}