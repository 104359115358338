import React from "react";
// Customizable Area Start
import {
  ScrollView,
  Image,
  Text,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  ImageSourcePropType
} from "react-native";

import scale, { verticalScale } from "../../../components/src/Scale";
import PostCreationController, {Props, configJSON} from "./PostCreationController.web";
import { edit } from "../src/assets";
import Scale from "../../../components/src/Scale";
import Select, { OptionTypeBase, ValueType } from "react-select";
import { Grid } from "@material-ui/core";
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          {/* Customizable Area Start */}
         <Grid container sm={12} spacing={2}>
            <Grid item sm={3}></Grid>
         </Grid>
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "90%"
  },
  inputTextField: {
    height: verticalScale(80),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    margin: scale(3),
    padding: 10,
    marginTop: verticalScale(5)
  },

  inputTextField1: {
    height: verticalScale(50),
    borderColor: "grey",
    borderWidth: 1,
    color: "black",
    fontSize: scale(16.7),
    borderBottomWidth: 0.3,
    paddingLeft: 10,
    paddingRight: 10,
    margin: scale(3)
  },

  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 22,
    backgroundColor: "#366ef9",
    height: scale(50),
    marginTop: verticalScale(20)
  },
  imageStyle: {
    width: scale(100),
    height: scale(100),
    borderRadius: scale(50),
    borderWidth: 1,
    borderColor: "black",
    marginTop: verticalScale(5),
    marginBottom: verticalScale(25),
    opacity: 0.6
  },
  buttonText: {
    color: "white"
  },
  editIcon: {
    position: "absolute",
    height: scale(20),
    width: scale(20),
    resizeMode: "contain",
    top: verticalScale(90),
    left: Scale(90)
  }
});
// Customizable Area End
