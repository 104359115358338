import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik, FormikTouched, FormikErrors } from "formik";
import * as Yup from "yup";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TermsAndConditions from "../../email-account-login/src/TermsAndConditions.web";
import PrivacyPolicy from "../../email-account-login/src/PrivacyPolicy.web";
import LoginSignUp, { webStyle , StyledButton, StyledBigContainer, StyledTextField, BorderOr, StyledTitle, AccountStatement} from "../../email-account-login/src/LoginSignUp.web";
import "../../customisableuserprofiles/src/ProfileInfo.css"
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z]).+$/,
      "Password doesnt match the criteria above"
    )
    .required('Password is required'),
  
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required')
});

// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  }
});



export default class AccountRegistration extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getError = (touched: FormikTouched<{ email: string; password: string;confirmPassword: string;}>, errors: FormikErrors<{ email: string; password: string; confirmPassword: string;}>, name: string) => {
      if(touched[name as keyof typeof touched] && !!errors[name as keyof typeof errors]){
        return true;
      }
      return false;
  }

  getErrorHelpers = (touched: FormikTouched<{ email: string; password: string;confirmPassword: string;}>, errors: FormikErrors<{ email: string; password: string; confirmPassword: string;}>, name: string) => {
      if(touched[name as keyof typeof touched]){
        return errors[name as keyof typeof errors];
      }
      return "";
  }

  // Customizable Area End
  render() {
    
    return (
      // Customizable Area Start
      // Required for all blocks
      
      <ThemeProvider theme={theme}>
        <div style={{ overflow: 'hidden'}} data-test-id="AccountRegiContainer">
          <LoginSignUp navigation={this.props.navigation} id={""}>
          {this.state.termsPopup && <TermsAndConditions open={this.state.termsPopup} onClose={this.handleTermsNavigate} />}
          {this.state.privacyPopup && <PrivacyPolicy open={this.state.privacyPopup} onClose={this.handlePrivacyPolicyClick} />}
            <StyledBigContainer width={{xs : "300px", sm : "327px",  md: "360px"}}>
              <Formik
                initialValues={{
                  email: this.state.signUpFormData.email,
                  password: this.state.signUpFormData.password,
                  confirmPassword: this.state.signUpFormData.confirmPassword
                }}
                data-testid="formikForm"
                isInitialValid={false}
                onSubmit={(values) => {
                  this.handleSignInSubmit(values);
                }}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, isValid, handleSubmit, handleChange, handleBlur}) => {
                  return(
                  <form autoComplete="off" onSubmit={handleSubmit}>
                      <StyledTitle className="textCenter marginBottom48 removeMargin">
                        Join Forge
                      </StyledTitle>
                    <Box >
                      <StyledTextField
                        className="marginBottom16"
                        placeholder={this.isCreative?"Email":"Company Email"}
                        variant="outlined"
                        value={values.email}
                        data-test-id={"txtEmail"}
                        name={"email"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          classes:{
                            error: "formError"
                          }
                        }}
                        error={this.getError(touched, errors, "email") || !!this.state.emailError}
                        helperText={this.getErrorHelpers(touched, errors, "email") || this.state.emailError}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                          },
                          classes: {
                            error: "formHelperErrorText"
                          }
                        }}
                      />
                    </Box>
                    <Box style={webStyle.passwordInputPosition}>
                      <AccountStatement>
                        <Typography className="lightGray">
                          The password should have at least one lower case and one upper case characters
                        </Typography>
                      </AccountStatement>
                      <StyledTextField
                        fullWidth
                        placeholder="Password"
                        className="marginBottom16"
                        data-test-id={"txtPassword"}
                        name="password"
                        error={this.getError(touched, errors, "password") || !!this.state.passFieldError}
                        helperText={this.getErrorHelpers(touched, errors, "password")|| this.state.passFieldError}
                        variant="outlined"
                        type={this.state.showPassword ? "text" : "password"}
                        value={values.password}
                        autoComplete='new-password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                          },
                          classes: {
                            error: "formHelperErrorText"
                          }
                        }}
                        InputProps={{
                          classes:{
                            error: "formError"
                          },
                          endAdornment:
                            !this.state.showPassword
                              ? (<VisibilityOffIcon
                                style={webStyle.passwordVisibilityOffIcon}
                                onClick={this.handlePassShow}
                              />)
                              : (<VisibilityIcon
                                style={webStyle.passwordVisibilityOnIcon}
                                onClick={this.handlePassShow}
                              />)
                        }}
                      />
                    </Box>
                    <Box style={webStyle.passwordInputPosition}>
                      <StyledTextField
                        fullWidth
                        className="marginBottom26"
                        placeholder="Re-enter Password"
                        data-test-id={"confirmPassword"}
                        onBlur={handleBlur}
                        error={this.getError(touched, errors, "confirmPassword")|| !!this.state.confirmPassError}
                        helperText={this.getErrorHelpers(touched, errors, "confirmPassword") || this.state.confirmPassError}
                        variant="outlined"
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onChange={handleChange}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: 0,
                          },
                          classes: {
                            error: "formHelperErrorText"
                          }
                        }}
                        InputProps={{
                          classes:{
                            error: "formError"
                          },
                          endAdornment:
                            !this.state.showConfirmPassword
                              ? (<VisibilityOffIcon
                                style={webStyle.passwordVisibilityOffIcon}
                                onClick={this.handleConfirmPassShow}
                                data-test-id="showConfirmPassword"
                              />)
                              : (<VisibilityIcon
                                style={webStyle.passwordVisibilityOnIcon}
                                onClick={this.handleConfirmPassShow}
                                data-test-id="hideConfirmPassword"
                              />)
                        }}
                      />
                    </Box>
                      <AccountStatement>
                        <Typography className="lightBlack">
                          By joining Forge you agree to the <span
                            data-test-id={"termsConditions"}
                            className="privacyLink"
                            onClick={() => {
                              this.handleTermsNavigate()
                            }} style={{cursor : "pointer"}}> 
                            Terms & Conditions</span>  and  <span
                            onClick={() => {
                              this.handlePrivacyPolicyClick()
                            }}
                            data-test-id={"privacyPolicy"}
                            className="privacyLink" style={{cursor : "pointer"}}>Privacy Policy</span>
                        </Typography>
                      </AccountStatement>
                    <div>
                      <StyledButton
                        type="submit"
                        data-test-id='submitBtn'
                        disabled={!isValid}
                        className={isValid ? "" : "disabledButton"}
                      >
                        {this.isCreative?"Sign Up":"Next"}
                      </StyledButton>
                    </div>
                    <div style={webStyle.borderContainer}>
                      <div>
                        <BorderOr/>
                      </div>
                      <Typography style={webStyle.or}>or</Typography>
                      <div>
                        <BorderOr />
                      </div>
                    </div>
                    <div style={webStyle.isAccountDiv}>
                      <AccountStatement>
                        <Typography>
                          Already have an account?
                        </Typography>
                      </AccountStatement>
                      <span
                        onClick={() => { this.handleLoginClick() }}
                        data-test-id={"userLogin"}
                        style={{ ...webStyle.signUplink, fontFamily: 'Silika-Medium' }}>Log In</span>
                    </div>

                  </form>
                )}}
              </Formik>
            </StyledBigContainer>
          </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

