Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getSubscriptionsPlan = "bx_block_subscriptions/subscription_plans";
exports.btnExampleTitle = "CLICK ME";
exports.getMediaGalleryApiEndPoint = "/bx_block_posts/posts/media_posts";
exports.getMediaGalleryLoginApiEntPoint="/bx_block_posts/posts/gallery_posts";
exports.getLandingPageDataApiEndPoint = "/media/new_updates"; 
exports.getSubscriptionsPlan="bx_block_subscriptions/subscription_plans";
exports.getSubscriptionFeatureCategories="bx_block_subscriptions/subscription_plan_feature_categories";
exports.getSubscriptionFeatures="bx_block_subscriptions/subscription_plan_features";
exports.getFAQApiEndPoint="bx_block_help_centre/question_answer";
exports.getSupportFeatureApiEndPoint="bx_block_help_centre/supports";
exports.getFindDesignTalentApiEndPoint="bx_block_help_centre/design_talent";
exports.getFindTechnicalTalentApiEndPoint="bx_block_help_centre/technical_talent";
// Customizable Area End