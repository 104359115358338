import React from "react";
import SubscriptionCards from "../../../../../components/src/CommonSubscription.web";
import CommonDialog from "../../../../../components/src/CommonDialog.web";
import { SubscriptionProps1 } from "../../SettingsProfileController";

export default class AccountSubscriptions extends React.Component<SubscriptionProps1>{
    
    constructor(props: SubscriptionProps1){
        super(props);
    }

    render(){
        return (
            <>
            <SubscriptionCards {...this.props} />
            <CommonDialog
                open={false}
                title="Downgrade Plan"
                description="Are you sure you wish to downgrade your plan?"
                successText="Confirm"
                cancelText="Cancel"
                hasCloseButton={false}
            />
            <CommonDialog
                open={false}
                title="Downgrade Plan"
                description="Thank you, The requested changes will be implemented for your next billing period."
                okText="Ok"
                hasCloseButton={false}
                isOkdisabled={false}
            />
            <CommonDialog
                open={false}
                title="Upgrade Plan"
                description="Please confirm you wish to upgrade to Studio plan"
                successText="Confirm"
                cancelText="Cancel"
                hasCloseButton={false}
            />
             <CommonDialog
                open={false}
                title="Upgrade Plan"
                description="Thank you, The requested changes will be implemented for your next billing period."
                okText="Ok"
                hasCloseButton={false}
                isOkdisabled={false}
            />
            </>
        )
    }
}