// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");

import {
  getStorageData,
  setStorageData
} from '../../../framework/src/Utilities';
import { ICreateJob } from "./components/DialogJobPostPopUp";
import _ from "lodash";
import { apiCall } from "../../../../packages/components/src/ApiCall";
import { OptionType, Profile } from "../../../../packages/blocks/events/src/EventViewController";
export interface Props {
  navigation: any;
  id: string;
}

export interface JobListDataType {
    attributes: {
      address: string, 
      addresses: [], 
      company_headquarter: string, 
      company_name: string, 
      company_page_id: number, 
      company_photo: string,
        company_photo_url: string, 
        country: string, 
        created_at: string, 
        employment_type: null, 
        employment_type_id: number,
        industry_id: number,
        industry_name: string, 
        job_description: string, 
        job_function: string, 
        job_title: string, 
        job_video: string, 
        job_video_url: string, 
        location: string, 
        other_skills: Array<string>, 
        preffered_location: Array<string>, 
        profile_id: number, 
        question_answer_id: Array<string>, 
        question_answers: Array<object>, 
        remote_job: boolean, 
        salary: string, 
        seniority_level: string, 
        skill_id: Array<number>, 
        skill_name: Array<string>, 
        sub_emplotyment_type: null, 
        total_inteview_rounds: number,
        image_url: string,
        followers_count: number
    }, 
      id: string, 
      type: string
}

export interface JobListIOSDataType {
  item: {
    attributes: {
      address: string, 
      addresses: [], 
      company_headquarter: string, 
      company_name: string, 
      company_page_id: number, 
      company_photo: string,
        company_photo_url: string, 
        country: string, 
        created_at: string, 
        employment_type: null, 
        employment_type_id: number,
        industry_id: number,
        industry_name: string, 
        job_description: string, 
        job_function: string, 
        job_title: string, 
        job_video: string, 
        job_video_url: string, 
        location: string, 
        other_skills: Array<string>, 
        preffered_location: Array<string>, 
        profile_id: number, 
        question_answer_id: Array<string>, 
        question_answers: Array<object>, 
        remote_job: boolean, 
        salary: string, 
        seniority_level: string, 
        skill_id: Array<number>, 
        skill_name: Array<string>, 
        sub_emplotyment_type: null, 
        total_inteview_rounds: number,
        image_url: string,
        followers_count: number
    }, 
      id: string, 
      type: string
  }
}

interface IAccountProfile{
  data: {
    id: string,
    type: string,
    attributes: {
      hourly_rate_from: string,
      hourly_rate_to: string,
    }
  }
}

interface Account{
    data: {
      id: string,
      type: string,
      attributes: {
        account_profile: IAccountProfile
      }
    }
}

interface IAccountProfile{
  data: {
    id: string,
    type: string,
    attributes: {
      hourly_rate_from: string,
      hourly_rate_to: string,
    }
  }
}

interface Account{
    data: {
      id: string,
      type: string,
      attributes: {
        account_profile: IAccountProfile
      }
    }
}

interface IAccountProfile{
  data: {
    id: string,
    type: string,
    attributes: {
      hourly_rate_from: string,
      hourly_rate_to: string,
    }
  }
}

interface JobListDataWeb {
  id: string,
  type: string,
  attributes: {
    job_title: string;
    role_title: string[],
    job_type:string[],
    job_description: string,
    location: string,
    level: string[],
    skill_id: string[],
    country:string,
    county:string,
    category:string,
    created_at:string,
    banner_image:string,
    budget:string,
    account:Account,
    profession:string[],
    start_date: string,
    end_date: string,
  }
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  first: boolean;
  searchText: string;
  jobArray: JobListDataType[];
  recent: Array<string>;
  lastSearch: string;
  loading: boolean
  followIndex: number,
  token: string,
  activeTab:number;
  isPostJobOpen: boolean;
  applyPopup:boolean;
  showMessage:boolean;
  jobListingData:JobListDataWeb[];
  isNewOpportunity:boolean;
  advanceSearchText: string,
  professions: string[],
  selectedProfessions: string[],
  workTypes: string[],
  selectedWorkTypes: string[],
  skills: string[],
  selectedSkills: string[],
  selectedCountries: string[],
  seniorityLevels: string[],
  selectedSeniorityLevels: string[],
  favPeople: [],
  minRate: number|undefined,
  maxRate: number|undefined,
  applySuccessPopUp:boolean,
  typeOpportunity:string,
  shareData:OptionType[];
  chatMessage: string;
  isShareModalOpen: boolean;
  shareLink: string;
  isChatSend: boolean;
  isChatModalOpen: boolean;
  profileData: Profile[];
  wantToShareJob:number;
  click:boolean;
}

interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingController extends BlockComponent<Props, S, SS> {
  searchJobApiCallId: string = ""
  searchCompanyApiCallId: string = "";
  searchPeopleApiCallId: string = "";
  followCompanyApiCallId: string = "";
  jobListingApiCallId:string = "";
  //willFocusSubscription: object;
  addConnectionApiCallId: string = "";
  lastVisitedJob: string = "";
  lastVisitedCompany: string = "";
  lastVisitedPeople: string = "";
  handleCreateJobApiCallId:string="";
  getProfessionsAPICallID:string="";
  getSeniorityLevelAPICallID:string="";
  getAllSkillsAPICallID:string="";
  getWorkTypesAPICallID:string="";
  chatMsgSendApiCallID:string="";
  getAllDataProfileApiCallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: '',
      loading: false,
      txtSavedValue: 'A',
      enableField: false,
      first: true,
      searchText: '',
      jobArray: [],
      recent: [],
      lastSearch: '',
      followIndex: -1,
      token:'',
      activeTab: 1,
      isPostJobOpen: false,
      showMessage:false,
      jobListingData:[],
      applyPopup:false,
      isNewOpportunity:false,
      advanceSearchText: "",
      professions: [],
      selectedProfessions: [],
      workTypes: [],
      selectedWorkTypes: [],
      skills: [],
      selectedSkills: [],
      selectedCountries: [],
      seniorityLevels: [],
      selectedSeniorityLevels: [],
      favPeople: [],
      minRate: undefined,
      maxRate: undefined,
      applySuccessPopUp:false,
      typeOpportunity:"",
      shareData:[],
      chatMessage: "",
      isShareModalOpen: false,
      shareLink: "",
      isChatSend:false,
      isChatModalOpen: false,
      profileData:[],
      wantToShareJob:0,
      click:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  getJobSearchSuccessCallBack = (responseJson: JobListDataType[], apiRequestCallId:string) => {
    if (apiRequestCallId === this.searchJobApiCallId) {
      try {
          this.lastVisitedJob = this.state.searchText?.trim();
          this.setState({ jobArray: responseJson || [], loading: false });
      }
      catch (error) {
        alert(error)
      }
    }
  }

  getJobSearchFailedCallBack = (message:string, apiRequestCallId:string) => {
     if (message.includes('Record not found')) {
          if (apiRequestCallId === this.searchJobApiCallId) {
            this.lastVisitedJob = this.state.searchText?.trim()
            this.setState({ jobArray: [], loading: false });
          }
        } else if (apiRequestCallId === this.addConnectionApiCallId && message.includes("Connection created successfully")) {
          alert('Request sent successfully')
        }
  }

  getJobSearchErrorCallBack = (errorReponse:object, apiRequestCallId:string) => {
    if (
      apiRequestCallId === this.searchJobApiCallId ||
      apiRequestCallId === this.searchCompanyApiCallId ||
      apiRequestCallId === this.searchPeopleApiCallId
    ) {
      this.parseApiErrorResponse(errorReponse);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {

      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token }, () => {
      });
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        'Change Value',
        'From: ' + this.state.txtSavedValue + ' To: ' + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getJobSearchSuccessCallBack(responseJson.data, apiRequestCallId)
      }
      if (responseJson?.meta?.message) {
        this.getJobSearchFailedCallBack(responseJson.meta.message, apiRequestCallId);
      }
      if (responseJson && responseJson.errors && responseJson.errors?.length != 0 && responseJson.errors[0].token) {
        alert('Session Expired')
        this.props.navigation.navigate('Auth')
      }
      if (errorReponse) {
        this.getJobSearchErrorCallBack(errorReponse, apiRequestCallId)
      }
    }
   this.getResponse(getName(MessageEnum.RestAPIResponceMessage),message);
   
  }

  getResponse = (responseType:string,message:Message) => {
    if (responseType === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (apiRequestCallId) {
        case this.handleCreateJobApiCallId:
          this.handleCreateJobResponse(responseJson);
          break;

          case this.searchJobApiCallId:
          case this.jobListingApiCallId:
            this.handleListJobResponse(responseJson);
            break;

            case this.getProfessionsAPICallID:{
              this.setState({ professions: responseJson.professions });
              break;
            } 
  
            case this.getWorkTypesAPICallID:{
              this.setState({ workTypes: responseJson.prefrred_type_of_works });
              break;
            } 
            case this.getAllSkillsAPICallID:{
              this.setState({ skills: responseJson.skills });
              break;
            } 
            case this.getSeniorityLevelAPICallID:{
              this.setState({ seniorityLevels: responseJson.seniority_level });
              break;
            }

            case this.getAllDataProfileApiCallId:
              this.handleAllProfileResponse(responseJson.data);
             break;

             case this.chatMsgSendApiCallID:{
              this.setState({
                isChatModalOpen: false, 
                isShareModalOpen: false, 
                isChatSend: true, 
                chatMessage: "", 
                shareData: []});
              break;
            }

          default:
            break;
        }
      }
  }
  
  
  getToken = () => {
    const getAuthToken: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(getAuthToken);
  };

  async componentDidMount() {
    this.getToken();
    this.handleJobListing();
    this.fetchSkills();
    this.fetchWorkTypes();
    this.fetchProfessionsList();
    this.fetchSeniorityLevels();
    this.handleGetAllUserProfile();
  }
  
  async getRecents() {
    const recentData = await getStorageData('recent');
    this.setState({ recent: recentData });
  }
  handler = (inputValue: string) => {
    if (inputValue.trim()) {
      this.setSearchText(inputValue)
    }
    this.setState({ lastSearch: inputValue.trim(), searchText: inputValue, loading: true });
  };

  setSearchText = (inputValue: string) => {
    this.searchJob(inputValue);
  };

  addDataToRecent() {
    let recent = this.state.recent;
    this.setState({ recent }, () => {
      setStorageData('recent', JSON.stringify(this.state.recent));
    });
  }


 
  
  searchJob = async (inputValue: string) => {
    this.setState({ loading: true });
    let token = this.state.token;
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchJobApiEndPoint + '?search=' + inputValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUrl = (imageUrl: string) => {
    if (imageUrl.includes("https://") || imageUrl.includes("http://")) {
      return imageUrl;
    } else {
      return config.baseURL + imageUrl;
    }
  };
  handleTabChange = (activeTab: number) => {
    this.setState({ activeTab: activeTab});
  };
  handleNewOpportunity = () => {
    this.setState({isNewOpportunity: !this.state.isNewOpportunity})
  }

  handlePopupApply = () =>{
    this.setState({ applyPopup:!this.state.applyPopup });
  };

  getTokenFromStorage = async() =>{
    return await getStorageData('token');
  }


  handleJobListing = async () => {
    let endpoint = `${configJSON.jobListingApiEndPoint}?search_key=${this.state.advanceSearchText}`
    if(this.state.selectedCountries.length > 0) {
      this.state.selectedCountries.forEach((country: string) => {
        endpoint = `${endpoint}&country[]=${country}`
      });
    }
  
    if(this.state.selectedProfessions.length > 0) {
      this.state.selectedProfessions.forEach((profession: string) => {
        endpoint = `${endpoint}&profession[]=${profession}`
      });
    }
  
    if(this.state.selectedSeniorityLevels.length > 0) {
      this.state.selectedSeniorityLevels.forEach((seniority_level: string) => {
        endpoint = `${endpoint}&seniority_level[]=${seniority_level}`
      });
    }
  
    if(this.state.selectedSkills.length > 0) {
      this.state.selectedSkills.forEach((skill: string) => {
        endpoint = `${endpoint}&skill[]=${skill}`
      });
    }
  
    if(this.state.selectedWorkTypes.length > 0) {
      this.state.selectedWorkTypes.forEach((work_type: string) => {
        endpoint = `${endpoint}&work_type[]=${work_type}`
      });
    }
  
    if((this.state.minRate !== undefined) && (this.state.maxRate !== undefined)){
      endpoint = `${endpoint}&hourly_rate_from=${this.state.minRate}&hourly_rate_to=${this.state.maxRate}`
    }
     
    this.jobListingApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint:endpoint,
      token: await getStorageData('token'),
    });
  };
  
  handleJobPostPopUp = () => {
    this.setState({ isPostJobOpen: !this.state.isPostJobOpen })
  }

  handleCreateJobResponse = (responseJson: {data:Object}) => {
    if (responseJson.data) {
      this.setState({ isPostJobOpen: false, isNewOpportunity: false })
      this.handleJobListing();
    }
  }

  handleListJobResponse = (responseJson: {data:JobListDataWeb[]}) => {
    if (responseJson.data) {
      this.setState({ jobListingData: responseJson.data })
    }
  }

  handleAllProfileResponse(responseJson:Profile[]) {
    if (responseJson) {
      this.setState({profileData:responseJson});
    }
  }

  handleJobCreate = async (values: ICreateJob) => {
    let formdata = new FormData();
    const skill_ids = values.jobType.map(jobType => jobType.key).join(",")
    const skill_idse = values.level.map(level => level.key).join(",")
    formdata.append("job_type[]", skill_ids);
    formdata.append("level[]", skill_idse);
    formdata.append("job_title", values.jobTitle);
    formdata.append("link", values.link);
    formdata.append("country", values.country.name);
    formdata.append("salary", values.salary);
    formdata.append("profession[]", values.profession);
    formdata.append("start_date", values.startDate);
    formdata.append("job_description", values.description);
    formdata.append("town", values.town.name);
    formdata.append("county", values.county.name);
    formdata.append("location", "");
    formdata.append("role_title[]", "");
    if (values.bannerImage) {
      formdata.append("banner_image", values.bannerImage);
    };
    const header = {
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleCreateJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobListingApiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  fetchProfessionsList = async () => {
    this.getProfessionsAPICallID = await apiCall({
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getProfessionsListApiEndPoint,
      contentType: configJSON.validationApiContentType,
      token: await getStorageData('token'),
    });
  };

  fetchSeniorityLevels = async () => {
    this.getSeniorityLevelAPICallID = await apiCall({
      endPoint: configJSON.getSeniorityListApiEndPoint,
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      token: await getStorageData('token'),
    });
  };

  fetchSkills = async () => {
    this.getAllSkillsAPICallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      token: await getStorageData('token'),
      endPoint: configJSON.getAllSkilsApiEndPoint,
      method: configJSON.httpGetMethod,
    });
  };

  fetchWorkTypes = async () => {
    this.getWorkTypesAPICallID = await apiCall({
      method: configJSON.httpGetMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.getWorkTypesListApiEndPoint,
      token: await getStorageData('token'),
    });
  };

  handleChangeSearchText = (event:string) => {
    this.handleSearchKeyDebounce(event);
  }

  handleSearchKeyDebounce = _.debounce((searchKey: string) => {
    this.setState({ advanceSearchText: searchKey }, () => {
      this.handleJobListing(); 
    })
  }, 1000);

  handleMinRateChange = (value: number | undefined) => {
    this.setState({ minRate: !Number.isNaN(value) ? value : undefined }, () => this.checkMinMaxRate());
  }

  handleMaxRateChange = (value: number | undefined) => {
    this.setState({ maxRate: !Number.isNaN(value) ? value : undefined }, () => this.checkMinMaxRate());
  }

  checkMinMaxRate = () => {
    const { minRate, maxRate } = this.state;
    if ((minRate !== undefined) && (maxRate !== undefined) && (minRate < maxRate)) {
      this.handleJobListing();
    }
  }

  handleClickClearAll = () => {
    this.setState({
      selectedCountries: [],
      selectedSkills: [],
      selectedWorkTypes: [],
      selectedProfessions: [],
      selectedSeniorityLevels: [],
      minRate: undefined,
      maxRate: undefined
    });
  }

  handleClickClearFilter = (values: string[], type: string) => {
  
    this.setState(prevState => ({
      ...prevState,
      [type]: values
    }),()=>{
      this.handleJobListing();
    })
  }


  handleChangeFilter = (values: string[], fieldName: string) => {
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: values
    }),()=>{
      this.handleJobListing();
    })
  }

  handleClearRateRange = () => {
    this.setState({ minRate: undefined, maxRate: undefined })
  }

  handleDayDifference = (startDateStr: string) => {
    const startDate = new Date(startDateStr);
    const differenceMs = new Date().getTime() - startDate.getTime();
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
    return differenceDays - 1;
  }

  handleDateFormat = (dateStr:string) =>{
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',   
        month: 'short',   
        year: '2-digit'
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  openPopUp =(job_id:number)=>{
    this.setState({
      isShareModalOpen:true,
      wantToShareJob:job_id,
      shareLink:window.location.href.split("/",3).join("/")+"/JobProfile/"+`${job_id}`
    })
   }

  closeOpenConfirmationSuccessJob = () =>{
    this.setState({applySuccessPopUp:true,applyPopup:false})
  }

  handleApplySuccessPopUp =() => {
   this.setState({applySuccessPopUp:!this.state.applySuccessPopUp})
  }

  handleChooseOptionForOpportunity = (type:string) => {
    this.setState({typeOpportunity:type})
  }

  handleOpenPopUpRelatedPopup = () =>{
    if(this.state.typeOpportunity==="Permanent"){
      this.handleJobPostPopUp()
    }
  }

  handleCloseButton = () => {
    this.setState({isChatModalOpen: false, 
      isShareModalOpen: false, 
      shareData: [], 
      shareLink:"", 
      isChatSend: false, 
      chatMessage: ""
    });
  }

  handleClickChatButton = () => {
    this.setState(prevState => ({
      ...prevState, 
      isChatModalOpen: !prevState.isChatModalOpen, 
      isShareModalOpen: !prevState.isShareModalOpen
    }));
  }

  handleChatSend = async () => {
    const ids = this.state.shareData.map(user => user.account_id);
    let body =  {
      user_id: ids,
      job_id: this.state.wantToShareJob,
      message: this.state.chatMessage,
     }
    this.chatMsgSendApiCallID = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: "bx_block_joblisting/share_job",
      token: await getStorageData('token'),
      body:  body,
      isJsonStringify:true
    });
  };


  handleSendChatButton = () => {
    this.handleChatSend();
  }

  handleShareData = (event: React.ChangeEvent<{}>, value: OptionType[]) => {
    this.setState({ shareData: value });
  }

  handleChangeChatMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({chatMessage: event.target.value});
  }

  handleBackButton = () => {
    this.setState(prevState => ({
      ...prevState,
       isShareModalOpen: !prevState.isShareModalOpen, 
       isChatModalOpen: !prevState.isChatModalOpen
      }));
  }

  handleGetAllUserProfile = async () => {
  this.getAllDataProfileApiCallId = await apiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.httpGetMethod,
    endPoint: configJSON.getProfileEndPoint,
    token: await getStorageData('token'),
  });
 }

}
// Customizable Area End
