import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'; 
import { Box, styled } from '@material-ui/core';
const saveImage = require("./image__icon share.svg");
const shareImage = require("./image__icon save.svg");
const locationIcon = require("./location.svg");

interface Props {
    title: string;
    category: string;
    updatedTime: string;
    level: string[]|string;
    location: string;
    type: string[]|string;
    profession: string[]|string;
    dates: string;
    rate: string;
    budget: string;
    description: string;
    imageUrl: string;
    stage?: string;  
    btnText?: string;
    handleApplyPopUp?:()=>void
    handleSharePopUp?:(job_id:number)=>void
    job_id?:number
}

interface S { }

const StyledContainer = styled(Box)({
    "@media(max-width:960px)": {
      padding:"10px 10px !important"
    },
    "& .opportunityDetails":{
      "@media(max-width:960px)": {
            gap:"10px !important"
        },
    },
    "& .administrativeImage":{
        "@media(max-width:960px)": {
             width:"100px !important",
             height:"100px !important"
           },
           "@media(max-width:670px)": {
            width:"48px !important",
            height:"48px !important",
            borderRadius:"50%"
          },
    },
    "& .opportunityTitle":{
        "@media(max-width:960px)": {
            fontSize:"16px !important"
          },
    },
    "& .detailsDiv":{
        "@media(max-width:600px)": {
            width:"100% !important",
            flexWrap:"wrap !important" as "wrap",
            height:"auto !important",
          },
    },
    "& .column1":{
        "@media(max-width:600px)": {
            width:"100% !important",
            padding:"5px",
            flexWrap:"wrap !important" as "wrap"
          },
    },
    "& .opprtunityDescription":{
        "@media(max-width:960px)": {
           fontSize:"12px !important",
           marginTop:"16px !important"
          },
    },
    "& .headerDiv":{
        "@media(max-width:960px)": {
         marginBottom:"16px !important"
          },
          "@media(max-width:600px)": {
          flexWrap:"wrap !important" as "wrap",
        
        },
    },
    "& .subTexts":{
        "@media(max-width:960px)": {
            gap:"10px !important"
        },
    },
    "& .headerLeft":{
        "@media(max-width:600px)": {
          width:"100% !important",
          marginBottom:"10px !important"
        },
    },
    "& .applyButton":{
        "@media(max-width:600px)": {
         height:"36px !important"
        },
    }
})

export default class JobCard extends Component<Props, S> {
    render() {
        const {
            title,
            category,
            updatedTime,
            level,
            location,
            type,
            profession,
            dates,
            rate,
            budget,
            description,
            imageUrl,
            stage = 'open', 
            btnText = 'Apply',
            job_id,
            handleApplyPopUp,
            handleSharePopUp
        } = this.props;

    return (
        <StyledContainer style={webStyles.mainBlock}>
        <div style={webStyles.opportunityBlock}>
            <div style={webStyles.opportunityDetails} className='opportunityDetails'>
                <img src={imageUrl} style={webStyles.administrativeImage} className='administrativeImage' alt="Job" />
                <div style={webStyles.administrativeDetailBlock}>
                    <div style={webStyles.headerDiv} className='headerDiv'>
                        <div style={webStyles.headerLeft} className='headerLeft'>
                            <Typography style={webStyles.opportunityTitle} className='opportunityTitle'>
                                {title} 
                            </Typography>
                            <div style={webStyles.subTexts} className='subTexts'>
                                <Typography style={webStyles.catagory}>{category}</Typography>
                                <Typography style={webStyles.updatedTime}>{updatedTime}</Typography>
                            </div>
                        </div>
                        <div style={webStyles.headerRight} className="headerRight">
                            <img src={saveImage} onClick={()=>handleSharePopUp?handleSharePopUp(job_id||0):()=>{}} style={webStyles.iconStyle} alt="Save" />
                            <img src={shareImage} style={webStyles.iconStyle} alt="Share" />
                            <Button style={webStyles.applyButton} data-test-id="apply-btn-job"className="applyButton" 
                            onClick={() => {
                                if (this.props.handleApplyPopUp) {
                                  this.props.handleApplyPopUp();
                                }
                              }}
                            >{btnText}</Button>
                        
                        </div>
                    </div>
                    <div style={webStyles.detailsDiv} className='detailsDiv'>
                        <div style={webStyles.column1} className='column1'>
                            <Typography style={webStyles.attributeTitle}>Level:</Typography>
                            <Typography style={webStyles.attributeDesc}>{level}</Typography>
                        </div>
                        <div style={webStyles.column2} className='column1'>
                            <Typography style={webStyles.attributeTitle}>Location:</Typography>
                            <img src={locationIcon} style={webStyles.iconStyle} alt="Location" />
                            <Typography style={webStyles.attributeDesc}>{location}</Typography>
                        </div>
                    </div>
                    <div style={webStyles.detailsDiv} className='detailsDiv'>
                        <div style={webStyles.column1} className='column1'>
                            <Typography style={webStyles.attributeTitle}>Type:</Typography>
                            <Typography style={webStyles.attributeDesc}>{type}</Typography>
                        </div>
                        <div style={webStyles.column2} className='column1'>
                            <Typography style={webStyles.attributeTitle}>Profession:</Typography>
                            <Typography style={webStyles.attributeDesc}>{profession}</Typography>
                        </div>
                    </div>
                    <div style={webStyles.detailsDiv} className='detailsDiv'>
                        <div style={webStyles.column1} className='column1'>
                            <Typography style={webStyles.attributeTitle}>Dates:</Typography>
                            <Typography style={webStyles.attributeDesc}>{dates}</Typography>
                        </div>
                        <div style={webStyles.column2} className='column1'>
                            <div style={{ display: 'flex', gap: '15px', width: '50%' }}>
                                <Typography style={webStyles.attributeTitle}>Rate:</Typography>
                                <Typography style={webStyles.attributeDesc}>{rate}</Typography>
                            </div>
                            <div style={{ display: 'flex', gap: '15px', width: '50%' }}>
                                <Typography style={webStyles.attributeTitle}>Budget:</Typography>
                                <Typography style={webStyles.attributeDesc}>{budget}</Typography>
                            </div>
                        </div>
                    </div>

                </div>
               
            </div>
            
        </div>
        <Typography style={webStyles.opprtunityDescription} className='opprtunityDescription'>
          {description}
        </Typography>
  </StyledContainer>
    );
}
}

const webStyles = {
    mainBlock:{
     border: "1px solid #DFDFDF",
     padding: "38px 42px",   
    },
    opportunityBlock: {
        display: "flex",
        flex: 1,   
    },
    opportunityDetails: {
        display: "flex",
        flex: 1,
        gap: "32px"
    },
    administrativeImage: {
        height: "206px",
        width: "205px",
        objectFit: "cover" as "cover",
        border: "1px solid #DFDFDF"
    },
    administrativeDetailBlock: {
        display: "flex",
        flex: 1,
        flexDirection: "column" as "column",
    },
    headerDiv: {
        display: "flex",
        flex: 1,
        height: "80px",
        justifyContent: "space-between",
        marginBottom: "34px"
    },
    headerLeft: {

},
headerRight: {
    display: "flex",
    gap: "16px",
    alignItems: "center"
},
opportunityTitle: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "26px",
    LineHeight: "31.37px",
    letterSpacing: "-2.2%"
},
subTexts: {
    display: "flex",
    gap: "115px",
    alignItems: "center"
},
catagory: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "16px",
    LineHeight: "19.36px",
    letterSpacing: "-0.15%",
    color: "#9B9B9B"
},
updatedTime: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    LineHeight: "19.36px",
    letterSpacing: "-0.15%",
    color: "#9B9B9B"
},
applyButton: {
    width: "110px",
    height: "48px",
    backgroundColor: "#0D0C22",
    color: "white",
    textTransform: "none" as "none",
    fontFamily:"Silika-Medium",
    borderRadius: "2px"
},
detailsDiv: {
    height: "36px",
    display: "flex",
    borderBottom: "1px solid #DFDFDF",
    borderTop: "1px solid #DFDFDF",
    alignItems: "center",
},
column1: {
    width: "40%",
    display: "flex",
    gap: "15px"
},
column2: {
    width: "60%",
    display: "flex",
    gap: "15px",
    alignItems: "center"
},
opprtunityDescription: {
    fontFamily: "Silika-Light",
    fontWeight: 200,
    fontSize: "16px",
    lineHeight: "18.98px",
    marginTop: "34px",
    width:"100%"
},
iconStyle: {
    height: "24px",
    width: "24px"
},
attributeTitle: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16.88px",
    color: "#9B9B9D"
},
attributeDesc: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16.88px",
},
}