// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import 'react-toastify/dist/ReactToastify.css';
import HomeScreen from "../../components/src/HomeScreen";
import HeaderNavbar from "../../components/src/Navbar";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Portal from '@material-ui/core/Portal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Task from "../../blocks/tasks/src/Task";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import AddFriends from "../../blocks/addfriends/src/AddFriends";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import CreateComment from "../../blocks/comments/src/CreateComment";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Search from "../../blocks/search/src/Search";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Premiumplancomparison2 from "../../blocks/premiumplancomparison2/src/Premiumplancomparison2";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Chat from "../../blocks/chat/src/Chat";
import NewChat from "../../blocks/chat/src/NewChat.web";
import ViewChat from "../../blocks/chat/src/ViewChat";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import AccountLogin from "../../blocks/email-account-login/src/AccountLogin.web";
import SettingsProfile from "../../blocks/settings2/src/SettingsProfile.web";
import JobListing from "../../blocks/joblisting/src/JobListing.web"

import {TermsAndConditionWeb} from "../../blocks/termsconditions/src/TermsAndConditionWeb"
import UserForgotPassword from "../../blocks/email-account-login/src/UserForgotPassword.web";
import ForgotPasswordEmail from "../../blocks/email-account-login/src/ForgotPasswordEmail.web";
import PasswordProcessComplete from "../../blocks/email-account-login/src/PasswordProcessComplete.web";
import TermsAndConditions from "../../blocks/email-account-login/src/TermsAndConditions.web";
import PrivacyPolicy from "../../blocks/email-account-login/src/PrivacyPolicy.web";
import ForgotPwdVerification from "../../blocks/email-account-login/src/ForgotPwdVerification.web";
import SignUpComplete from "../../blocks/email-account-registration/src/SignUpComplete.web"
import SignUpThanks from "../../blocks/email-account-registration/src/SignUpThanks.web"
import AccountRegistration from "../../blocks/email-account-registration/src/AccountRegistration.web"
import  AccountVerification from "../../blocks/email-account-registration/src/AccountVerification.web"
import  VerificationSuccess from "../../blocks/email-account-registration/src/VerificationSuccess.web"
import  UserPlan from "../../blocks/email-account-registration/src/UserPlan.web"
import  PaymentMethod from "../../blocks/email-account-registration/src/PaymentMethod.web"
import  AddPaymentDetail from "../../blocks/email-account-registration/src/AddPaymentDetail.web"
import  ClientSignUpConfirm from "../../blocks/email-account-registration/src/ClientSignUpConfirm.web"
import AddUserProfile from "../../blocks/email-account-registration/src/AddUserProfile.web"
import Availability from "../../blocks/email-account-registration/src/Availability.web"
import AccountAlreadyExists from "../../blocks/email-account-registration/src/AccountAlreadyExists.web"
import FindTalentCatalogue from "../../blocks/catalogue/src/FindTalentCatalogue.web"
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";
import ProfileInfo from "../../blocks/customisableuserprofiles/src/ProfileInfo.web"
import PostCreationWeb from "../../blocks/postcreation/src/PostCreationWeb"
import ListPost from "../../blocks/postcreation/src/ListPost"
import AllEventsWeb from "../../blocks/events/src/AllEvents.web"
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web"
import EventView from "../../blocks/events/src/EventView.web";
import LandingPage3Web from "../../blocks/landingpage/src/LandingPage3.web"
import LandingPage2Web from "../../blocks/landingpage/src/LandingPage2.web"
import Favourites from "../../blocks/favourites/src/Favourites.web";
import LandingPage5Web from "../../blocks/landingpage/src/LandingPage5.web"
import Dashboard from "../../blocks/dashboard/src/Dashboard.web"
import LandingPage6Web from "../../blocks/landingpage/src/LandingPage6.web"
import JobProfile from "../../blocks/postcreation/src/JobProfile.web"
import JoinForge from "../../blocks/email-account-login/src/JoinForge.web"
import Membership from "../../blocks/email-account-login/src/Memberhip.web"
import MembershipConfirmation from "../../blocks/email-account-login/src/MembershipConfirmation.web"
import PaymentSetup from "../../blocks/email-account-login/src/PaymentSetup.web"
import EmployeeSignUp from "../../blocks/email-account-registration/src/EmployeeSignUp.web"
import LandingPage7Web from "../../blocks/landingpage/src/LandingPage7.web"
import SupportPage from "../../blocks/landingpage/src/SupportPage.web"
import NotificationWeb from "../../blocks/notifications/src/Notifications.web"
import CreativeDashboard from "../../blocks/dashboard/src/CreativeDashboardListing.web";
const routeMap = {
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
AllEventsWeb:{
component:AllEventsWeb,
path:"/AllEventsWeb"
},
NotificationWeb:{
  component:NotificationWeb,
  path:"/NotificationWeb"
},
EventView:{
  component:EventView,
  path:"/EventView"
},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Notifications:{
 component:Notifications,
path:"/Notifications"},


DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},

Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
AddFriends:{
 component:AddFriends,
path:"/AddFriends"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Share:{
 component:Share,
path:"/Share"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
CustomisableUserProfiles:{
  component:CustomisableUserProfiles,
  path:'/CustomisableUserProfiles',
},
AddUserProfile:{
  component:AddUserProfile,
  path:'/AddUserProfile',
},
Availability:{
  component:Availability,
  path:'/Availability',
},
AccountVerification:{
  component:AccountVerification,
  path:'/AccountVerification',
},
AccountAlreadyExists:{
  component:AccountAlreadyExists,
  path:'/AccountAlreadyExists',
},
UserPlan:{
  component:UserPlan,
  path:'/UserPlan',
},
PaymentMethod:{
  component:PaymentMethod,
  path:'/PaymentMethod'
},
AddPaymentDetail:{
  component:AddPaymentDetail,
  path:'/AddPaymentDetail'
},
ClientSignUpConfirm:{
  component:ClientSignUpConfirm,
  path:'/ClientSignUpConfirm'
},
VerificationSuccess:{
  component:VerificationSuccess,
  path:'/VerificationSuccess',
},
ProfileInfo:{
  component:ProfileInfo,
  path:'/ProfileInfo/:postId?'
},
Profiles:{
  component:ProfileInfo,
  path:'/Profiles/:id/:postId?'
},
FindTalentCatalogue:{
  component:FindTalentCatalogue,
  path:'/FindTalentCatalogue',
},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Search:{
 component:Search,
path:"/Search"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Premiumplancomparison2:{
 component:Premiumplancomparison2,
path:"/Premiumplancomparison2"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Chat:{
 component:Chat,
path:"/Chat"},
NewChat:{
  component:NewChat,
  path:"/NewChat"
},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
ListPost:{
  component:ListPost,
  path:"/PostDetails/:id"
  },
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
TermsAndConditionWeb:{
  component:TermsAndConditionWeb,
  path:"/TermsAndConditionWeb"
},
PostCreationWeb:{
  component:PostCreationWeb,
  path:"/PostCreationWeb"
},

AccountLogin:{
 component:AccountLogin,
path:"/AccountLogin"},
UserForgotPassword:{
  component:UserForgotPassword,
 path:"/UserForgotPassword"},
 ForgotPasswordEmail:{
  component:ForgotPasswordEmail,
 path:"/ForgotPasswordEmail"},
 PasswordProcessComplete:{
  component:PasswordProcessComplete,
 path:"/PasswordProcessComplete"},
 ForgotPwdVerification:{
  component:ForgotPwdVerification,
 path:"/ForgotPwdVerification"},
 TermsAndConditions:{
  component:TermsAndConditions,
 path:"/TermsAndConditions"},
 PrivacyPolicy:{
  component:PrivacyPolicy,
 path:"/PrivacyPolicy"},
AccountRegistration:{
  component:AccountRegistration,
 path:"/AccountRegistration"},
 SignUpComplete:{
  component:SignUpComplete,
 path:"/SignUpComplete"},
 SignUpThanks:{
  component:SignUpThanks,
 path:"/SignUpThanks"},
  Home: {
component:LandingPageWeb,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  AllEventsWeb: {
    component:AllEventsWeb,
        path: '/AllEventsWeb',
        exact: true
      },
  SettingsProfile:{
    component:SettingsProfile,
    path:"/SettingsProfile"
  },
  JobListing:{
    component:JobListing,
    path:"/JobListing"
  },
  LandingPage3Web:{
    component:LandingPage3Web,
   path:"/LandingPage3Web"},
  LandingPageWeb:{
    component:LandingPageWeb,
   path:"/LandingPageWeb"},
   LandingPageWeb6:{
    component:LandingPage6Web,
   path:"/Gallery"},
   JobProfile : {
    component:JobProfile,
    path:"/JobProfile/:id"
   },
   

  LandingPage2Web: {
    component: LandingPage2Web,
    path: '/LandingPage2Web',
    exact: true
  },
  LandingPage5Web: {
    component: LandingPage5Web,
    path: '/FindDesignTalent',
    exact: true
  },
  Dashboard : {
    component : Dashboard,
    path : '/Dashboard',
    exact : true
  },
  JoinForge : {
    component : JoinForge,
    path : '/JoinForge',
    exact : true
  },
  Membership : {
    component : Membership,
    path : '/Membership',
    exact : true
  },
  MembershipConfirmation : {
    component : MembershipConfirmation,
    path : '/MembershipConfirmation',
    exact : true
  },
  PaymentSetup : {
    component : PaymentSetup,
    path : '/PaymentSetup',
    exact : true
  },
  EmployeeSignUp : {
    component : EmployeeSignUp,
    path : '/EmployeeSignUp',
    exact : true
  },
  LandingPage7Web : {
    component : LandingPage7Web,
    path : '/FindTechnicalTalent',
    exact : true
  },
  SupportPage : {
    component : SupportPage,
    path : '/Support',
    exact : true
  },
  CreativeDashboard : {
    component : CreativeDashboard,
    path : '/CreativeDashboard',
    exact : true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View>
        {/* <TopNav /> */} 
       <Portal>
        <ToastContainer
                autoClose={3000}
                position="top-right"
                className="toast-container"
                toastClassName="dark-toast"
              />       
              </Portal> 
        {WebRoutesGenerator({ routeMap })}    
        {/* <ModalContainer /> */}
      </View>
    );
  }
}

export default App;