import React from "react";
// Customizable Area Start
import {
    Grid,
    Dialog,
    DialogContent,
    Typography,
    TextField,
    Chip, 
    Box,
    TextareaAutosize,
    styled,
    withStyles
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { closeImage } from "../assets"
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import { S } from "../ProfileInfoController";
import { CustomButton } from "./EditProfileModal";
import { checkCondition } from "../../../../components/src/HelperUtils";
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const StyledTextareaAutosize = styled(TextareaAutosize)(() => ({
    "& *":{
        fontFamily: "Silika-Light",
        fontSize: "16px",
        lineHeight: "25px"
    },
    "&::placeholder":{
        fontFamily: "Silika-Medium",
        color: "#9B9B9D"
    }
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        experienceInnerDiv1: {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "1195px",
            background: "#000",
            borderRadius: "4px, 4px, 0px, 0px",
            fontSize: "14px !important",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            lineHeight: "19.3px",
            color: "white",
        },
        dialougeHeading: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "19px",

        },
        inputTitle: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            marginTop: "2%",
            color: "#010101"
        },
        buttonFilled: {
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
          },
    })
);
const editAboutValidation = Yup.object().shape({
    aboutMe: Yup.string().required('Job title is required'),
    skills: Yup.string().required('Company name is required'),

});

type Props = {
    state: S
    handleEditAbout: () => void
    editAboutMe: (value:any) => void
}

function EditAbout(props: Props) {
    const classes = useStyles();
    const { state, handleEditAbout, editAboutMe } = props;

    return (
        <Dialog
            maxWidth="md"
            open={state.editAbout}
            onClose={handleEditAbout}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
        >
            <ModalHeaderWrapper>
                <ModalTitle className={classes.dialougeHeading}>{"Edit About"}</ModalTitle>
                <img onClick={handleEditAbout} style={{ width: "20px", cursor: "pointer" }} src={closeImage}></img>
            </ModalHeaderWrapper>
            <FormWrapper>
                        <Formik
                            initialValues={{
                                aboutMe: state.getProfile?.attributes?.description || '',
                                skills: state.getProfile?.attributes?.skill || [],
                            }}
                            onSubmit={(values) => {
                                 editAboutMe(values)
                            }}
                            data-test-id="Formik"
                            validationSchema={editAboutValidation}
                        >
                            {({
                                handleChange,
                                values,
                                handleSubmit,
                                errors,
                                touched,
                                setFieldValue,
                                isValid,
                                dirty,
                            }) =>  (
                                <Form onSubmit={handleSubmit}>
                                <FieldsWrapper>
                                <Grid container>
                                    <GridItem item lg={12} md={12} sm={12}>
                                    <FormLabelFont>About Me<span>*</span></FormLabelFont>
                                        <StyledTextareaAutosize
                                            name='aboutMe'
                                            minRows={7}
                                            maxRows={7}
                                            placeholder="Description"
                                            onChange={handleChange}
                                            style={webStyle.textareaSize as React.CSSProperties}
                                            value={values.aboutMe}
                                        />

                                    </GridItem>
                                    <GridItem item lg={12} md={12} sm={12} style={webStyle.GridStyled}>
                                        <FormLabelFont>Skills<span>*</span></FormLabelFont>
                                        <StyledAutocomplete
                                            popupIcon={<KeyboardArrowDownIcon />}
                                            limitTags={4}
                                            options={state.skill}
                                            multiple
                                            id="tags"
                                            getOptionLabel={(option) => option as string}
                                            onChange={(_event, newValue) => {
                                                setFieldValue("skills", newValue);
                                            }}
                                            value={values.skills}
                                            data-testId="skills"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={checkCondition(Boolean(values.skills.length), "", "Eg Figma") as string}
                                                    variant="outlined"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        key={index}
                                                        label={
                                                            <Box component={'span'}>
                                                                 <ChipText noWrap>{option as string}</ChipText>
                                                            </Box>
                                                        }
                                                        style={webStyle.ChipStyled}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }

                                        />
                                    </GridItem>
                                </Grid>
                                </FieldsWrapper>
                            <ButtonWrapper>
                                <CustomButton
                                    variant="contained"
                                    type="submit"
                                    className={`${classes.buttonFilled} ${isValid && dirty ? 'blackBackground' : ''}`}
                                    disabled={!isValid || !dirty}
                                >
                                    Save
                                </CustomButton>
                            </ButtonWrapper>
                                </Form>
                                )
                            }
                        </Formik>
            </FormWrapper>
        </Dialog>
    )
}

export default EditAbout

const webStyle={
    typographyStyled:{
        marginBottom:"-4px",
        color:'#000'
    },
    GridStyled:{
        marginTop:"20px"
    },
    ChipStyled:{
        borderRadius:'3px'
    },
    textareaSize: {
        width: '100%',
        padding: '10px',
        resize: 'none',
        outline: 'none',
        marginTop: '4px',
        border: '1px solid #c4c4c4',
        borderRadius: '4px',
        fontFamily: "Silika-Light",
        fontSize: "16px",
        color: "#010101",
        height: "269px"
    }
}
const ModalHeaderWrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 40px",
    backgroundColor: "#0D0C22",
    "@media (max-width:600px)": {
        padding: "20px",
    }
})
const ModalTitle = styled(Typography)({
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    color: "#fff"
})
const FormWrapper = styled(DialogContent)({
    padding: "0 !important",
    "& form": {
        marginBottom: "8px !important"
    }
})
const FieldsWrapper = styled(Box)({
    padding: "35px 40px",
    "@media (max-width:600px)": {
        padding: "20px",
    }
})
const ButtonWrapper = styled(Box)({
    display: "flex",
    justifyContent: "end",
    columnGap: "16px",
    padding: "16px 40px",
    "@media (max-width:600px)": {
        padding: "16px 20px",
    }
})
const GridItem = styled(Grid)({
    width: "100%"
})
const FormLabelFont = styled(Typography)({
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "19px",
    color: "#000000",
    letterSpacing: "-0.15px",
    "& span": {
        color: "red"
    }
})
const ChipText = styled(Typography)({
    fontSize: "14px",
    fontFamily: "Silika-Light",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#010101",
  })
const StyledAutocomplete = withStyles({
    inputRoot: {
        paddingTop: "4px !important",
        paddingBottom: "4px !important",
        minHeight: "48px !important",
        marginTop: "4px !important",
        '& .MuiInputBase-input': {
            minWidth: '0px !important',
            height: '30px !important',
            fontFamily: "Silika-Light",
            fontSize: "16px",
            "&::placeholder": {
                fontFamily: "Silika-Medium !important",
                fontSize: "16px",
                color: "#9B9B9D !important",
            },
        },
    },
    option: {
        fontFamily: "Silika-Medium",
        fontSize: "14px",
        color: "#0D0C22",
        padding: "7px 16px !important"
    },
    popupIndicator: {
        marginRight: "-4px"
    }
})(Autocomplete);