import React, { ReactNode } from "react";
import { Theme, styled, Dialog, DialogActions, DialogTitle, DialogContent, Button, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    open: boolean,
    title: string;
    description: string | ReactNode;
    okText?: string;
    onHandleOk?: () => void;
    onCancelClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onSuccessClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    cancelText?: string;
    successText?: string;
    onClose?: () => void;
    isOkdisabled?: boolean;
    hasCloseButton: boolean;
  }

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& *': {
      fontSize: "16px"
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
      borderRadius: 4,
      width: '600px',
      boxShadow: 'none',
      paddingBottom: "9.5px",
    },
  }));
  const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#0D0C22',
    color: '#fff',
    padding: '18px 37px 18px 40px',
    fontWeight: 'bold',
    "& h2":{
      width: "100%",
      display: "inline-flex",
      alignItems: "center"
    },
    "& button":{
      marginRight: "0px",
      marginLeft: "auto",
      padding: "0px",
      borderRadius: "2px"
    },
    "& *": {
      fontFamily: "Silika-Medium",
    },
    "& path": {
      fill: "#fff"
    }
  
  }));
  
  const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: '48px 39.5px 24px 39.5px',
    "& *": {
      fontWeight: 300,
      fontFamily: "Silika-Light",
      textAlign: "center"
    }
  }));
  
  const StyledDialogActions1 = styled(DialogActions)(({ theme }: {theme: Theme}) => ({
    padding: '16px 40px 24px 40px',
    justifyContent: "flex-end",
    display: 'flex',
    [theme.breakpoints.down('sm')] :{ 
      justifyContent: "center"
    },
    '& button': {
      fontFamily: "Silika-Medium",
      textTransform: "none",
      boxShadow: "none",
      height: "48px",
      borderRadius: "2px"
    },
    "& .cancelBtn": {
      border: "1px solid #010101",
      color: "#010101"
    },
    "& .successBtn": {
      background: "#0D0C22",
      marginLeft: "16px"
    }
  }));

  const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    padding: '16px 40px 24px 40px',
    justifyContent: "center",
    display: 'flex',
    '& button': {
      fontFamily: "Silika-Medium",
      textTransform: "none",
      borderRadius: "0",
      boxShadow: "none",
      height: "48px",
      padding: "14.5px 49.5px"
    },
    "& .okBtn": {
      background: "#0D0C22",
      marginLeft: "16px",
      color: "#fff"
    }, 
    "& .Mui-disabled": {
      background: "#9B9B9D",
      color: "#FFFFFF"
    }
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: '100px',
  }));

interface S{
  isOkdisabled: boolean
}

class CommonDialog extends React.Component<Props, S>{
  timer :ReturnType<typeof setTimeout> | null = null;  
  constructor(props: Props){
        super(props);
        this.state = {
          isOkdisabled: false
        }
    }

    componentDidMount(): void {
      const { isOkdisabled } = this.props;
      this.setState({ isOkdisabled: isOkdisabled || false });
      if(isOkdisabled){
      this.timer = setTimeout(() => {
        this.setState({ isOkdisabled: false });
      }, 60000);
      }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: any): void {
      if(prevState.isOkdisabled != this.state.isOkdisabled){
        if(this.state.isOkdisabled){
          this.timer = setTimeout(() => {
            this.setState({ isOkdisabled: false });
          }, 20000);
          }
      }
    }

    componentWillUnmount(): void {
     if(this.timer !== null){
      clearInterval(this.timer);
     }
    }

    render(){
        return(
            <StyledDialog open={this.props.open} onClose={this.props.onClose} disableEscapeKeyDown={false}>
                <StyledDialogTitle>
                  <span>{this.props.title}</span>
                 {this.props.hasCloseButton && <IconButton onClick={this.props.onClose}><CloseIcon /></IconButton>}
                </StyledDialogTitle>
                <StyledDialogContent>
                    <div>{this.props.description}</div>
                </StyledDialogContent>
                {this.props.okText ? <StyledDialogActions>
                    <StyledButton disabled={this.state.isOkdisabled} onClick={() => {
                      if(this.props.onHandleOk){
                        this.props.onHandleOk();
                      }
                      this.setState({isOkdisabled: true})
                    }} color="primary" className="okBtn">
                        {this.props.okText}
                    </StyledButton>
                </StyledDialogActions> 
                : <StyledDialogActions1>
                  <StyledButton onClick={this.props.onCancelClick} color="primary" className="cancelBtn">
                      {this.props.cancelText}
                  </StyledButton >
                  <StyledButton type="submit" onClick={this.props.onSuccessClick} color="primary" variant="contained" className="successBtn">
                      {this.props.successText}
                  </StyledButton>
                </StyledDialogActions1>}
            </StyledDialog>
        )
    }
}

export default CommonDialog;
