import React from "react";
// Customizable Area Start
import {
  Button,
  Typography, Grid, Dialog, DialogContent, RadioGroup, FormControlLabel, Radio, Select, MenuItem, TextField, Chip, Box
} from "@material-ui/core";
import { makeStyles, Theme, createStyles,styled,withStyles } from "@material-ui/core/styles";
import { Formik, Form,  Field } from "formik";
import { closeImage } from "../assets"
import "react-datepicker/dist/react-datepicker.css";
import { S } from "../ProfileInfoController";
import CustomTextField from "../../../../components/src/CustomTextField";
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as Yup from 'yup';
import { checkCondition } from "../../../../components/src/HelperUtils";


type Props = {
  state: S
  editExperienceUpdate:(value:any) => void
  editSingleExperience:(value:any) => void
  getCallApi:(value:any) => void
  getCountry:() => {value:any,label:any}[]
  handleExperianceClose:()=> void
  openDeleteExperience: (expId: string) => void
}

interface Option {
  name: string;
  isoCode: string;
  flag: string;
  phonecode: string;
  currency: string;
  latitude: string;
  longitude: string;
  timezones: TimeZone[];
}
interface TimeZone {
  zoneName: string;
  gmtOffset: number;
  gmtOffsetName: string;
  abbreviation: string;
  tzName: string;
}

const validationSchemaExperince = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
  Profession: Yup.string().required('Profession is required'),
  Seniority: Yup.string().required('Seniority is required'),
  startMonth: Yup.string().required('Start Month is required'),
  startYear: Yup.string().required('Start Year is required'),
  endMonth: Yup.string().when(['currentlyWorking', 'startYear', 'endYear'], {
    is: (currentlyWorking, startYear, endYear) => currentlyWorking === 'No',
    then: Yup.string().required('End Month is required when not currently working')
      .test('is-greater-or-equal', 'End Month must be greater than or equal to Start Month when End Year is the same as Start Year', function (values) {
        const { startMonth, startYear, endYear } = this.parent;
        const monthYearMap: Record<string, number> = {
          January: 1, February: 2, March: 3, April: 4, May: 5, June: 6,
          July: 7, August: 8, September: 9, October: 10, November: 11, December: 12
        };
        const startMonNumber = monthYearMap[startMonth];
        const endMonNumber = monthYearMap[values];

        if (endYear === startYear) {
          return endMonNumber > startMonNumber
        }
        return true;
      }),
    otherwise: Yup.string().nullable()
  }),
  currentlyWorking: Yup.string().required('This field is required'),
  endYear: Yup.string().when('currentlyWorking', {
    is: 'No',
    then: Yup.string().required('End Year is required when not currently working')
    .test('is-greater', 'End Year must be greater than or equal to Start Year', function (value) {
      const { startYear } = this.parent;
      return value >= startYear;
    }),
    otherwise: Yup.string().nullable()

  }),
  Country: Yup.string().required('Country is required'),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    experienceInnerDiv1: {
      display: "flex",
      justifyContent: "space-between",
      maxWidth: "1195px",
      background: "#000",
      borderRadius: "4px, 4px, 0px, 0px",
      fontSize: "14px !important",
      fontWeight: 500,
      fontFamily: "Silika-Light",
      lineHeight: "19.3px",
      color: "white",
    },
    buttonOutline: {
      borderRadius: "2px",
      borderColor: "black",
    },
    dialougeHeading: {
      fontFamily: "Silika-Medium",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",

    },
    inputTitle: {
      fontFamily: "Silika-Medium",
      fontSize: "16px",
      fontWeight: 500,
      marginTop: "2%",
      color: "#010101"
    },
    buttonFilled: {
      borderRadius: "2px",
      backgroundColor: "black",
      color: "white",
    },
    opacityClass:{
      opacity:0.3,
    },
    marginTopStyle:{
      opacity:1,
    },
  })
);

const CustomSelect = styled(Select)({
  marginTop: "4px",
  width: "100%",
  "& .MuiSelect-root": {
    display: "flex",
    alignItems: "center"
  },
  "& .MuiInputBase-input": {
    fontFamily: "Silika-Light",
    width: "100% !important",
    height: "48px !important",
    fontSize: "16px",
  },
})

const CustomButton = styled(Button)({

  "&.blackBackground": {
      backgroundColor: "black",
      color: "white"
  },
  "&:hover": {
      backgroundColor: "black !important",
  },
  "& .MuiButton-contained.Mui-disabled":{
      backgroundColor: "lightgray",
      color:'white',
      boxShadow:'none'
  }
})


function EditExperience(props: Props) {
  const { state,getCallApi ,getCountry,handleExperianceClose, openDeleteExperience} = props;

  const classes = useStyles();
  const months = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" }
  ];
  const year = (new Date()).getFullYear();
  const nextYears: number = year + 10

const StartYearsAll = Array.from(new Array(51), (_value, index) => year - index);
const EndYearRange = Array.from(new Array(61), (_value, index) => nextYears - index);
const EndYearList = EndYearRange.map(years => {
  return { value: years.toString(), label: years.toString() };
});

const yearsAsObjects = StartYearsAll.map(years => {
    return { value: years.toString(), label: years.toString() };
});

const conditionRender=(values:string,placeholder:string)=>{
  return values ? undefined : () => <div style={webStyle.placeholderStyle}>{placeholder}</div>
}
  const fieldValueData = (newInputValue: string) => {
    return newInputValue === null ? "" : newInputValue
  }

  const countryfieldValueData = (newValue: string) => {
    return newValue === null ? "" : newValue
  }
  return (

    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={state.editExperiance}
      aria-describedby="alert-dialog-slide-description"
    >
      <ModalHeaderWrapper>
        <ModalTitle>{`${state.experienceId ? "Edit": "Add"} Experience`}</ModalTitle>
        <img onClick={handleExperianceClose} style={{ width: "20px", cursor: "pointer" }} src={closeImage}></img>
      </ModalHeaderWrapper>
      <ModalContentWrapper>
          <Formik
            initialValues={state.editExperience}
            onSubmit={(values) => {
              getCallApi(values)
              handleExperianceClose()
            }}
            validationSchema={validationSchemaExperince}
            validateOnMount={true}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              isValid,
            }) => {
              let companyList: string[] = state.companyList.map(company => company?.attributes?.name|| "")
              return (
                <Form style={{marginBottom:8}}>
                  <FieldsWrapper>
                  <Grid container style={webStyle.styleMargin}>
                    <GridItem item lg={12} md={12} sm={12}>
                      <FormLabelFont>Company Name<span>*</span></FormLabelFont>
                      <StyledAutocomplete
                        popupIcon={<KeyboardArrowDownIcon />}
                        disableClearable
                        style={webStyle.autoComplateStyle}
                        value={values.companyName}
                        id="combo-box-demo"
                        data-testId="companyName"
                        options={companyList} 
                        getOptionLabel={(option) => (option as string) }
                        renderInput={(params) => <TextField {...params} data-test-id="companyName" variant="outlined"  placeholder="Eg. Jaguar Land Rover" />}
                        freeSolo
                        onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
                          const newInputValue = (event.target as HTMLInputElement).value;
                          setFieldValue('companyName', fieldValueData(newInputValue))
                        }}
                        onChange={(_event, newValue) => {
                          setFieldValue('companyName', fieldValueData(newValue as string))
                        }}
                      />
                    </GridItem>
                  </Grid>

                  <TwoGridWrapper container spacing={2} >
                    <GridItem item lg={6} md={6} sm={12}>
                      <FormLabelFont>Profession<span>*</span></FormLabelFont>
                      <StyledAutocomplete
                        multiple
                        popupIcon={<KeyboardArrowDownIcon />}
                        style={webStyle.autoComplateStyle}
                        options={state.getProfessionData}
                        data-testId="Profession"
                        id="tags-standard"
                        getOptionLabel={(option) => option as string}
                        onChange={(_event, newValue) => {
                          setFieldValue("Profession", newValue);
                        }}
                        value={values.Profession}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={checkCondition(Boolean(values.Profession.length), "", "Eg. Product Designer") as string}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              disabled: values.Profession && values.Profession.length >= 3
                            }}
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined"
                              key={index}
                              label={
                                <Box component={'span'}>
                                  <Box>{option as string}</Box>
                                </Box>
                              }
                              style={webStyle.ChipStyle}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                      />
                    </GridItem>

                    <GridItem item lg={6} md={6} sm={12}>
                      <FormLabelFont>Seniority<span>*</span></FormLabelFont>
                      <CustomSelect
                        data-testId='Seniority'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.Seniority}
                        displayEmpty
                        renderValue={conditionRender(values.Seniority, "Seniority Level")}
                        disableUnderline
                        name='Seniority'
                        variant="outlined"
                        onChange={handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {state.SeniorityData.map((menuItem) => <MenuItemFont key={menuItem} value={menuItem}>{menuItem}</MenuItemFont>)}
                      </CustomSelect>
                    </GridItem>
                  </TwoGridWrapper>

                  <Grid container style={webStyle.styleMargin}>
                    <GridItem item lg={12} md={12} sm={12}>
                      <FormLabelFont>Country<span>*</span></FormLabelFont>
                      <StyledAutocomplete
                        popupIcon={<KeyboardArrowDownIcon />}
                        disableClearable
                        style={webStyle.autoComplateStyle}
                        id="combo-box-demo"
                        data-testId="CountryData"
                        value={values.Country || ""}
                        options={getCountry()?.map((options) => options.value)}
                        getOptionLabel={(option: unknown) => (option as Option)?.name}
                        renderInput={(params) => <TextField {...params} variant="outlined"  placeholder="Country" />}
                        onChange={(_event, newValue) => {
                          setFieldValue('Country', countryfieldValueData(newValue as string))
                        }}
                      />
                    </GridItem>
                  </Grid>

                  <Grid container style={webStyle.styleMargin}>
                    <Grid style={webStyle.radioGridStyle}>
                      <FormLabelFont>Are you currently working in this company?<span>*</span></FormLabelFont>
                    </Grid>
                    <Grid>
                      <RadioGroup
                      data-testId="radiogroup"
                        onChange={(event) => setFieldValue("currentlyWorking", event.target.value)}
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={values.currentlyWorking}
                        name="radio-buttons-group"
                        style={webStyle.radioGroup as React.CSSProperties}
                      >
                        <FormControlLabel value="Yes" control={<Radio size="small" style={webStyle.yesRadio} />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio size="small" style={webStyle.noRadio} />} label="No" />
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <TwoGridWrapper container spacing={2}>
                  <GridItem item lg={6} md={6} sm={6}>
                    <FormLabelFont>Start Date<span>*</span></FormLabelFont>
                    <CustomSelect
                        data-testId='startMonth'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.startMonth}
                        displayEmpty
                        renderValue={conditionRender(values.startMonth, "Month")}
                        disableUnderline
                        name='startMonth'
                        variant="outlined"
                        onChange={handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {months.map((month) => <MenuItemFont key={month.value} value={month.value}>{month.label}</MenuItemFont>)}
                      </CustomSelect>
                    </GridItem>
                    <GridWithoutLabel item lg={6} md={6} sm={6}>
                      <CustomSelect
                        data-testId='startYear'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.startYear}
                        displayEmpty
                        renderValue={conditionRender(values.startYear, "Year")}
                        disableUnderline
                        name='startYear'
                        variant="outlined"
                        onChange={handleChange}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {yearsAsObjects.map((years) => <MenuItemFont key={years.value} value={years.value}>{years.label}</MenuItemFont>)}
                      </CustomSelect>
                    </GridWithoutLabel>
                  </TwoGridWrapper>

                  <TwoGridWrapper container spacing={2} className={values.currentlyWorking === "Yes" ? classes.opacityClass : classes.marginTopStyle}>
                  <GridItem item lg={6} md={6} sm={6}>
                    <FormLabelFont>End Date</FormLabelFont>
                      <CustomSelect
                        data-testId='endMonth'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.endMonth}
                        displayEmpty
                        renderValue={conditionRender(values.endMonth, "Month")}
                        disableUnderline
                        name='endMonth'
                        variant="outlined"
                        onChange={handleChange}
                        disabled={values.currentlyWorking==="Yes"}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {months.map((month) => <MenuItemFont key={month.value} value={month.value}>{month.label}</MenuItemFont>)}
                      </CustomSelect>
                    </GridItem>
                    <GridWithoutLabel item lg={6} md={6} sm={6}>
                       <CustomSelect
                        data-testId='endYear'
                        IconComponent={KeyboardArrowDownIcon}
                        value={values.endYear}
                        displayEmpty
                        renderValue={conditionRender(values.endYear, "Year")}
                        disableUnderline
                        name='endYear'
                        variant="outlined"
                        onChange={handleChange}
                        disabled={values.currentlyWorking==="Yes"}
                        MenuProps={{
                          getContentAnchorEl: null,
                          style: webStyle.startYearMaxHeight
                        }}
                      >
                        {EndYearList.map((years) => <MenuItemFont key={years.value} value={years.value}>{years.label}</MenuItemFont>)}
                      </CustomSelect>
                      {errors.endYear && touched.endYear && (
              <div style={{ color: 'red' }}>{errors.endYear}</div>
            )}
                    </GridWithoutLabel>
                  </TwoGridWrapper>

                    <Grid container style={webStyle.styleMargin}>
                      <GridItem item lg={12} md={12} sm={12}>
                        <FormLabelFont>Description</FormLabelFont>
                        <TextFieldCustomText
                          name="description"
                          variant="outlined"
                          value={values.description}
                          onChange={handleChange}
                          placeholder="Eg. Jaguar Land Rover"
                          multiline
                          minRows={5}
                        />
                      </GridItem>
                    </Grid>

                  <Grid container>
                  <GridItem item lg={12} md={12} sm={12}>
                    <FormLabelFont>Skills</FormLabelFont>
                      <StyledAutocomplete
                        style={webStyle.autoComplateStyle}
                        limitTags={4}
                        popupIcon={<KeyboardArrowDownIcon />}
                        id="tags-standard"
                        getOptionLabel={(options) => options as string}
                        multiple
                        onChange={(_event, newValue1) => {
                          setFieldValue('skills', newValue1);
                        }}
                        value={values.skills}
                        options={state.skill}
                        renderTags={(value, getTagProps) =>
                          value.map((option, indexx) => (
                            <Chip
                              variant="outlined"
                              key={indexx}
                              label={
                                <Box component={'span'}>
                                  <ChipText noWrap>{option as string}</ChipText>
                                </Box>
                              }
                              style={webStyle.ChipStyle}
                              {...getTagProps({ index: indexx })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            placeholder={checkCondition(Boolean(values.skills.length), "", "Eg. Figma") as string}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                        data-testId="skills"
                      />
                    </GridItem>
                  </Grid>
                  </FieldsWrapper>
                  <ButtonWrapper>
                    {state.experienceId ?
                      <Button
                        data-testid="delete-experience-btn"
                        variant="outlined"
                        className={classes.buttonOutline}
                        onClick={() => openDeleteExperience(String(state.experienceId))}>
                        Delete
                      </Button>
                      :
                      <Button variant="outlined" className={classes.buttonOutline} onClick={handleExperianceClose}>Cancel</Button>
                    }
                    <CustomButton variant="contained" type="submit" className={`${classes.buttonFilled} ${isValid ? 'blackBackground' : ''}`} disabled={!isValid} data-testId="submit">Save</CustomButton>
                  </ButtonWrapper>
                </Form>
              );
            }}
          </Formik>
      </ModalContentWrapper>

    </Dialog>
  )
}

export default EditExperience

const webStyle={
  marginStyle:{
      marginTop:"10px"
  },
  typographyStyle:{
      marginBottom:"-4px",
      color:'#000'
  },
  placeholderStyle: {
    color: "#b7b6b6",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
  } as React.CSSProperties,
  radioGridStyle: {
    alignSelf: "center",
  } as React.CSSProperties,
  radioGroup: {
    display: "flex",
    flexDirection: 'row'
  },
  typographStyle:{
    color:'black'
  },
  startYearMaxHeight:{
    maxHeight:'400px'
  },
  yesRadio: {
    color: "#010101",
    marginLeft: "10px"
  },
  noRadio:{
    color: "#010101" 
  },
  dateMarginStyle:{
    marginTop:'20px'
  },
  autoComplateStyle: {
    marginTop: '4px',
  } as React.CSSProperties,
  ChipStyle: {
    borderRadius: '3px'
  },
  starColor:{
    color:'red'
  },
  styleMargin:{
    marginBottom:"20px"
  }as React.CSSProperties,
}

const ModalHeaderWrapper = styled(Box)({
  backgroundColor: "#0D0C22",
  display: "flex",
  padding: "20px 40px",
  justifyContent: "space-between",
  "@media (max-width:600px)": {
    padding: "20px",
  }
})
const ModalTitle = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Silika-Medium",
  lineHeight: "19px",
  fontWeight: 500,
  color: "#fff"
})
const ModalContentWrapper = styled(DialogContent)({
  padding: "0 !important"
})
const ButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "end",
  columnGap: "16px",
  padding: "16px 40px",
  "@media (max-width:600px)": {
    padding: "16px 20px",
  }
})
const FieldsWrapper = styled(Box)({
  padding: "35px 40px",
  "@media (max-width:600px)": {
    padding: "20px",
  }
})
const FormLabelFont = styled(Typography)({
  fontFamily: "Silika-Medium",
  fontSize: "16px",
  color: "#010101",
  fontWeight: 500,
  lineHeight: "19px",
  letterSpacing:"-0.15px",
  "& span":{
    color:"red"
  }
})
const StyledAutocomplete = withStyles({
  inputRoot: {
    minHeight: "48px !important",
    paddingTop:"4px !important",
    paddingBottom:"4px !important",
    '& .MuiInputBase-input': {
      fontSize: "16px",
      height: '30px !important',
      minWidth: '0px !important',
      fontFamily: "Silika-Light",
      "&::placeholder": {
        fontFamily: "Silika-Medium !important",
        color: "#9B9B9D !important",
        fontSize: "16px",
      },
    },
  },
  option: {
    fontFamily: "Silika-Medium",
    fontSize: "14px",
    padding: "7px 16px !important",
    color: "#0D0C22",
  },
  popupIndicator: {
    marginRight: "-4px"
  }
})(Autocomplete);
const GridItem = styled(Grid)({
  width: "100%"
})
const TwoGridWrapper = styled(Grid)({
  marginBottom: "12px"
})
const GridWithoutLabel = styled(Grid)({
  width: "100%",
  marginTop: "20px",
  "@media (max-width:600px)": {
    marginTop: "0px",
  }
})
const MenuItemFont = styled(MenuItem)({
  fontFamily: "Silika-Medium",
  fontSize: "14px",
  color: "#0D0C22",
  padding: "7px 16px !important"
})
const TextFieldCustomText = styled(TextField)({
  width: "100%",
  marginTop: '4px',
  "& .MuiInputBase-input": {
    width: "100% !important",
    maxHeight: "100px !important",
    fontFamily: "Silika-Light",
    fontSize: "16px",
    color: "#010101",
    overflow: "auto !important",
    "&::placeholder": {
      fontFamily: "Silika-Medium !important",
      fontSize: "16px",
      color: "#9B9B9D !important",
    },
  },
})
const ChipText = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Silika-Light",
  fontWeight: 400,
  lineHeight: "22px",
  color: "#010101",
})