import * as React from 'react';
import { Theme, IconButton, styled, Typography, TextField } from '@material-ui/core'
import Button from '@mui/material/Button';
import { AccountActivityProps } from '../../SettingsProfileController';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CommonFormDialog, { StyledActions } from "../../../../../components/src/CommonFormDialog.web";
import CommonDialog from '../../../../../components/src/CommonDialog.web';
import { Formik, ErrorMessage } from 'formik';
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import * as Yup from "yup";

const validationSchemaEditCard = Yup.object({
    cardHolderName: Yup.string()
      .required('Card Holder Name is required'),
    cardNumber: Yup.string()
      .matches(/^\d{16}$/, 'Card Number must be 16 digits')
      .required('Card Number is required'),
    securityCode: Yup.string()
      .matches(/^\d{3}$/, 'Security Code must be 3 digits')
      .required('Security Code is required'),
    expirationDate: Yup.string()
      .matches(/^(0[1-9]|1[0-2])\/?(\d{2})$/, 'Expiration Date must be MM/YY format')
      .required('Expiration Date is required'),
});

const validationSchema = Yup.object().shape({
    new_email: Yup.string()
        .email('Invalid email format')
        .required('New Email is required'),
    confirm_new_email: Yup.string()
        .oneOf([Yup.ref('new_email'), null], 'Emails must match')
        .required('Confirm New Email is required'),
    password: Yup.string().required('Password is required')
});

export default class AccountActivity extends React.Component<AccountActivityProps> {
    constructor(props: AccountActivityProps){
        super(props);
    }
    
   renderMobileView = () => {
    const {onClickOpenSubscription, onClickDeleteAccount, handlePasswordShowModal, onClickUpdateEmail} = this.props;
        return (<>
            <StyledListItemContainer data-test-id="mobileAccountActivity">
                <div className="label" onClick={onClickOpenSubscription}>Subscription</div>
                <div className="description">
                    <span>Free plan</span>
                    <span>Get started with no fees.</span>
                    <span>£ 0/month</span>
                </div>
            </StyledListItemContainer>
            <StyledListItemContainer>
                <div className="label">Card details</div>
                <div className="description">
                    <span>Visa ending on ***4428</span>
                    <span>Your next billing date is 26th January, 2024</span>
                </div>
            </StyledListItemContainer>
            <StyledListItemContainer onClick={handlePasswordShowModal}>
                Reset Password
            </StyledListItemContainer>
            <StyledListItemContainer onClick={onClickUpdateEmail}>
                Change email
            </StyledListItemContainer>
            <StyledListItemContainer onClick={onClickDeleteAccount}>
                <div className="deleteIcon">
                    <DeleteIcon /> <span>Delete account</span>
                </div>
            </StyledListItemContainer>
        </>)
    }

   renderWebView = () => {
    const {profile, onClickOpenSubscription, onClickDeleteAccount, handlePasswordShowModal, onClickUpdateEmail} = this.props;
        return (
            <StyledAccountActivity data-test-id="accountActivity">
                <StyledListItemContainer >
                    <div className="label">Subscription</div>
                    <div className="description">
                        <span className="title">Free plan</span>
                        <span>Get started with no fees.</span>
                        <span>£ 0/month</span>
                    </div>
                    <div className="actions"><IconButton onClick={onClickOpenSubscription}><KeyboardArrowRightIcon /></IconButton></div>
                </StyledListItemContainer>
                <StyledListItemContainer >
                    <div className="label">Payments</div>
                    <div className="description">
                        <span className="title">Card details</span>
                        <span>Visa ending on ***4428</span>
                        <span>Your next billing date is 26th January, 2024</span>
                    </div>
                    <div className="actions"><Button>Edit</Button></div>
                </StyledListItemContainer>
                <StyledListItemContainer>
                    <div className="label">Account Actions</div>
                    <div className="description">
                        <span className="title margin24"> Password</span>
                        <span className="title margin24">
                            <Typography>Email</Typography><br />
                            <span className="email">{profile?.attributes?.email}</span>
                        </span>
                        <span>
                            <div className="deleteIcon" onClick={onClickDeleteAccount} data-test-id="deleteAccountBtn">
                            <DeleteIcon /> <span>Delete account</span>
                            </div>
                        </span>
                    </div>
                    <div className="actions">
                        <Button data-test-id="reset" className="margin24" onClick={handlePasswordShowModal}>Reset</Button>
                        <Button onClick={onClickUpdateEmail}>Edit</Button>
                    </div>
                </StyledListItemContainer>
            </StyledAccountActivity>
        )
    }

    render() {
        const {onCloseDialog, onForgotPasswordClick, showEmailPassword, onClickEditEmailPasswordShow, isEmailUpdated, isAccountDeleted, onClickNavigateToLogin, windowWidth, onClickDeleteAccount, isConfirmDeleteModalOpen, onDeleteAccount, onClickUpdateEmail, isEditEmailModalOpen, profile, onUpdateEmail} = this.props;
        const isMobile = windowWidth <= 599;
        return(
            <>
            {isMobile ? this.renderMobileView(): this.renderWebView()}
            <CommonDialog
                data-test-id="delete-account"
                onClose={onClickDeleteAccount} 
                open={isConfirmDeleteModalOpen}
                title="Delete Account"
                description={<span>Are you sure you want to delete your account?</span>}
                cancelText="Cancel"
                successText="Yes"
                onSuccessClick={onDeleteAccount}
                onCancelClick={onClickDeleteAccount}
                hasCloseButton={false}
            />
            <CommonDialog
                onClose={onClickNavigateToLogin}
                onHandleOk={onClickNavigateToLogin}
                hasCloseButton={false}
                open={isEmailUpdated}
                title="Change email"
                description={<span>Thank you. Your email will be updated once you click the verification link in your email.</span>}
                okText="Ok"
                isOkdisabled={false}
            />
            <CommonFormDialog 
                data-test-id="change-email-dialog" 
                onClose={onCloseDialog} 
                open={isEditEmailModalOpen} 
                title="Change email" 
            >
                <StyledChangeEmailContainer>
                    <Typography className="title">Your current email is: {profile?.attributes?.email}</Typography>
                <div className="form">
                <Formik
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    initialValues={{
                        new_email:"",
                        confirm_new_email: "",
                        password:""
                    }}
                    onSubmit={(values) => onUpdateEmail(values)}
                    data-test-id="editEmailFormik"
                    >
                    {({values, handleChange, handleBlur, handleSubmit, isValid, touched}) => {
                        const isFormValid = Object.keys(touched).length !== 0 && isValid;
                        const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
                            if(event.key === "Enter"){
                                handleSubmit();
                            }
                        }

                        return <>
                            <div className="textField">
                                <Typography>New Email</Typography>
                                <TextFieldCustomText
                                    name="new_email"
                                    value={values.new_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    data-test-id="new_email"
                                    onKeyDown={handleKeyDown}
                                    placeholder="New Email"
                                />
                                <ErrorMessage name="new_email" component="div" className="errorStyle"/>
                            </div>
                            <div className="textField">
                                <Typography>Confirm New Email</Typography>
                                <TextFieldCustomText
                                    name="confirm_new_email"
                                    value={values.confirm_new_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    data-test-id="confirm_new_email"
                                    onKeyDown={handleKeyDown}
                                    placeholder="Confirm New Email"
                                />
                                <ErrorMessage name="confirm_new_email" component="div" className="errorStyle"/>
                            </div>
                            <div className="textField">
                                <Typography>Enter your password</Typography>
                                <TextFieldCustomText
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    variant="outlined"
                                    type={showEmailPassword? "text": "password"}
                                    data-test-id="password"
                                    onKeyDown={handleKeyDown}
                                    placeholder="Password"
                                    InputProps={{
                                        startAdornment: <IconButton className="showPasswordBtn" data-test-id="showPasswordBtn" onClick={onClickEditEmailPasswordShow}>
                                            {showEmailPassword? <VisibilityIcon/>: <VisibilityOffIcon/>}
                                        </IconButton>
                                    }}
                                />
                                <ErrorMessage name="password" component="div" className="errorStyle"/>
                            </div>
                            <Typography className="forgotPasswordBtn" type="button" data-test-id="forgotPasswordBtn" component="button" onClick={()=> onForgotPasswordClick(true)}>Forgot password?</Typography>
                            <StyledActions
                                successText="Update"
                                cancelText="Cancel"
                                isValid={isFormValid}
                                onCancelClick={onClickUpdateEmail}
                                onSuccessClick={handleSubmit}
                            />
                        </>
                    }}
                </Formik>
                </div>
                </StyledChangeEmailContainer>
            </CommonFormDialog>
            <CommonDialog
                hasCloseButton={false}
                data-test-id="account-deleted-dialog"
                onClose={onClickNavigateToLogin}
                onHandleOk={onClickNavigateToLogin}
                open={isAccountDeleted}
                title="Account Deletion"
                okText="Ok"
                isOkdisabled={false}
                description={
                    <>
                    Sorry to see you go.
                    <br/>
                    <br/>
                    No payments will be taken after the end of your current billing period, and after the current billing period your plan will maintained at the free version for 30 days. 
                    <br/>
                    <br/>
                    Please note if you log back in during this period, your deletion request will automatically be cancelled. 
                    </>
                }
            />
        </>
        )
    }
};

const TextFieldCustomText = styled(TextField)({
    width: "100%",
    marginTop: '7px',
    "& .MuiInputBase-input": {
        width: "100% !important",
        fontSize: '14px',
        fontFamily: "Silika-Medium"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border: '1px solid gray',
            outline: 'none',
        },
    },
    "& .showPasswordBtn":{
        position: "absolute",
        right: 0
    }
})

const StyledChangeEmailContainer = styled("div")({
    "& .title": {
        margin: "14px 0",
        wordWrap: "break-word",
        textAlign: "start"
    },
    "& p": {
        fontFamily: "Silika-Medium"
    },
    "& .textField": {
        position: "relative",
        "& p": {
            fontFamily: "Silika-Light",
            fontSize: "16px",
            marginBottom: "4px",
            textAlign: "start"
        },
        marginTop: "20px",
        "&:last-child": {
            marginBottom: "20px"
        }
    },
    "& .errorStyle": {
        color: 'red',
        fontFamily: "Silika-Medium",
        fontSize: "14px",
        position: "absolute"
    },
    "& .form": {
        marginTop: "14px"
    },
    "& .forgotPasswordBtn": {
        pointerEvents: "all",
        cursor: "pointer",
        textAlign: "end",
        border: "0",
        background: "none",
        display: "flex",
        marginRight: "0px",
        marginLeft: "auto",
        fontFamily: "Silika-Medium",
        fontSize: "12px",
        color: "#000000",
        marginBottom: "24px",
        height: "22px",
        marginTop: "20px"
    }
});

const StyledAccountActivity = styled("div")(({ theme }: { theme: Theme }) => ({
    width: "728px",
    marginTop: "25px"
}));

const StyledListItemContainer = styled("div")(({ theme }: { theme: Theme }) => ({
    display: "inline-flex",
    borderBottom: "1px solid #E2E8F0",
    "&:last-child": {
        borderBottom: "none"
    },
    padding: "24px 0",
    width: "100%",
    "& .margin24": {
        marginBottom: "24px"
    },
    "& *": {
        fontFamily: "Silika-Medium"
    },
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        flexFlow: "column",
        padding: "12px 143px 12px 40px",
        width: "auto"
    },
    "& .label": {
        fontSize: "16px",
        width: "30%",
        marginBottom: "2px",
        lineHeight: "24px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    "& .description": {
        width: "60%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
        "& span": {
            display: "flex",
            flexFlow: "column",
            fontSize: "14px",
            fontFamily: "Silika-Light",
            color: "#9B9B9D",
            lineHeight: "20px"
        },
        [theme.breakpoints.up('sm')]: {
            "& .title": {
                color: "#010101",
                fontFamily: "Silika-Medium",
                "& button": {
                    padding: "0px",
                    textTransform: "none",
                    fontFamily: "Silika-Medium",
                    color: "#010101",
                    pointerEvents: "all !important",
                    cursor: "pointer !important",
                    background: "none",
                    border: "none",
                    width: "fit-content",
                    fontSize: "14px"
                }
            },
            "& .email": {
                color: "#9B9B9D !important",
                marginBottom: "2px"
            }
        }
    },
    "& .deleteIcon": {
        display: "inline-flex",
        alignItems: "center",
        pointerEvents: "all",
        cursor: "pointer",
        "& span": {
            color: "#FF5959 !important",
            fontFamily: "Silika-Medium !important"
        },
        "& svg": {
            color: "#FF5959",
            marginRight: "8px"
        }
    },
    "& .actions": {
        display: "flex",
        flexFlow: "column",
        width: "10%",
        alignItems: "center",
        "& button": {
            padding: "0px",
            textTransform: "none",
            fontFamily: "Silika-Medium",
            color: "#010101"
        }
    }
  }));

const StyledErrorMessage = styled(ErrorMessage)({
    color: 'red',
    fontFamily: "Silika-Medium",
    fontSize: "14px",
    position: "absolute"
});

const StyledTextField = styled("div")({
    position: "relative",
    width: "48%",
    "& p": {
        fontFamily: "Silika-Light",
        fontSize: "16px",
        marginBottom: "4px",
        textAlign: "start"
    },
    marginTop: "20px",
    "&:nth-child(1), &:nth-child(3)":{
        paddingRight: "20px"
    },
    "&:nth-child(3), &:nth-child(4)":{
        marginBottom:"24px"
    }
})

const StyledEditCardForm = styled("div")(({theme}: {theme: Theme}) => ({
    "& .title": {
        margin: "14px 0",
        wordWrap: "break-word",
        textAlign: "start"
    },
    "& p": {
        fontFamily: "Silika-Medium",
        width: "100%"
    },
    "& .form": {
        marginTop: "14px",
        display: "flex",
        flexFlow: "row wrap"
    },
    "& .forgotPasswordBtn": {
        pointerEvents: "all",
        cursor: "pointer",
        textAlign: "end",
        border: "0",
        background: "none",
        display: "flex",
        marginRight: "0px",
        marginLeft: "auto",
        fontFamily: "Silika-Medium",
        fontSize: "12px",
        color: "#000000"
    }
}));
